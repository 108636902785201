import {
  USER_APP_SETTINGS,
  USER_APP_SETTINGS_ABO_HINT
} from "../store/constants";

export const setUserAppSettings = data => dispatch => {
  dispatch({
    type: USER_APP_SETTINGS,
    payload: data
  });
};
export const setUserAppSettingsAboHint = data => dispatch => {
  dispatch({
    type: USER_APP_SETTINGS_ABO_HINT,
    payload: data
  });
};
