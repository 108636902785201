import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { MenuBook } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AboSalesDealClientsTable from "./AboSalesDealClientsTable";
import { setAboSalesDealClient } from "../../actions/abosales";

const StyledBadge = withStyles(theme => ({
  badge: {
    right: 28,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

const useStyles = makeStyles(theme => ({
  styledBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: props =>
        props.disabled ? "#ddd" : theme.palette.primary.main
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  dialog: {
    "& .MuiDialogContent-root:first-child": {
      padding: theme.spacing(0)
    }
  },
  dialogcontent: {
    padding: theme.spacing(0)
  },
  dialogtitle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  rightIconBadge: {
    marginLeft: theme.spacing(6)
  },
  button: {
    margin: theme.spacing(2, 4),
    marginLeft: 0
  }
}));

function AboSalesDealClientSearch(props) {
  const classes = useStyles(props);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function select(id) {
    props.dispatch(setAboSalesDealClient(id));
    props.dispatch(setAboSalesDealClient(id));
    handleClose();
  }

  return (
    <Fragment>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        fullWidth
        className={classes.button}
        onClick={handleClickOpen}
        disabled={props.disabled}
      >
        Einen Kunden {props.aboclients.count > 50 ? "suchen" : "auswählen"}
        <StyledBadge
          badgeContent={props.aboclients.count}
          color={props.disabled ? "default" : "primary"}
          className={classes.styledBadge}
        >
          <MenuBook className={classes.rightIconBadge} />
        </StyledBadge>
      </Button>

      <Dialog
        open={open}
        className={props.aboclients.count > 50 ? null : classes.dialog}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {props.aboclients.count > 50 && (
          <DialogTitle id="form-dialog-title" className={classes.dialogtitle}>
            Kunde in meinem Adressbuch suchen
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogcontent}>
          <AboSalesDealClientsTable
            data={props.aboclients.items}
            selectClient={select}
            title="Kunde durch Klick wählen"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    aboclients: state.aboclients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboSalesDealClientSearch);
