import React from "react";

const SvgPdf = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M19.514 33.324c-.348 0-.682-.113-.967-.326-1.041-.781-1.181-1.65-1.115-2.242.182-1.628 2.195-3.332 5.985-5.068 1.504-3.296 2.935-7.357 3.788-10.75-.998-2.172-1.968-4.99-1.261-6.643.248-.579.557-1.023 1.134-1.215a4.91 4.91 0 011.016-.172c.504 0 .947.649 1.261 1.049.295.376.964 1.173-.373 6.802 1.348 2.784 3.258 5.62 5.088 7.562 1.311-.237 2.439-.358 3.358-.358 1.566 0 2.515.365 2.902 1.117.32.622.189 1.349-.39 2.16-.557.779-1.325 1.191-2.22 1.191-1.216 0-2.632-.768-4.211-2.285-2.837.593-6.15 1.651-8.828 2.822-.836 1.774-1.637 3.203-2.383 4.251-1.025 1.435-1.909 2.105-2.784 2.105zm2.662-5.126c-2.137 1.201-3.008 2.188-3.071 2.744-.01.092-.037.334.431.692.149-.047 1.019-.444 2.64-3.436zm13.637-4.442c.815.627 1.014.944 1.547.944.234 0 .901-.01 1.21-.441.149-.209.207-.343.23-.415-.123-.065-.286-.197-1.175-.197-.505.001-1.14.023-1.812.109zm-7.47-6.582a71.291 71.291 0 01-2.674 7.564 49.966 49.966 0 016.496-2.02c-1.35-1.568-2.699-3.526-3.822-5.544zm-.607-8.462c-.098.033-1.33 1.757.096 3.216.949-2.115-.053-3.23-.096-3.216zM48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#cc4b4c"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"PDF"}
    </text>
  </svg>
);

export default SvgPdf;
