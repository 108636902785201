import React from "react";
import PropTypes from "prop-types";

const SvgAudio = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M37 0H8c-.8 0-1.5.7-1.5 1.9V55c0 .3.7 1 1.5 1h40c.8 0 1.5-.7 1.5-1V13c0-.7-.1-.9-.3-1.1L37.6.3c-.2-.2-.4-.3-.6-.3z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.2V12h11.8z" />
    <path
      d="M48 56H8c-.8 0-1.5-.7-1.5-1.5V39h43v15.5c0 .8-.7 1.5-1.5 1.5z"
      fill="#7d6599"
    />
    <path
      d="M35.7 15c-.6-.8-1.3-1.5-2.3-2.4-3.9-3.1-4.8-6.3-4.9-6.7V5c0-.6-.4-1-1-1s-1 .4-1 1v18.5h-3.7c-3.9 0-6.3 2-6.3 5.1 0 3.5 2.2 5.7 5.7 5.7s6.3-2.8 6.3-6.3V16.5l.3.2c.3.3.9.7 1.5 1.2 2.3 1.7 6.2 4.6 5.2 7.8v.3c0 .4.3.8.7 1h.3c.4 0 .8-.3 1-.7 1.6-5.4-.5-9.4-1.8-11.3zm-9.2 12.9c0 2.4-1.9 4.3-4.3 4.3s-3.7-1.4-3.7-3.7c0-2.6 2.3-3.1 4.3-3.1h3.7v2.5zm5-11.6c-.6-.4-1.1-.8-1.4-1.1l-1.6-1.3v-3.5c.8 1.2 2 2.5 3.6 3.8.9.7 1.5 1.3 1.9 1.9.7 1 1.4 2.3 1.8 4-1.3-1.6-3.1-2.9-4.3-3.8z"
      fill="#c8bdb8"
    />
    <path
      className="audio_svg__st4"
      d="M15.4 50h-2.7l-.5 2H10l3.1-10h1.8L18 52h-2.1l-.5-2zm-2.3-1.7H15l-1-3.6-.9 3.6zm12.2-6.2V49c0 1-.3 1.8-.9 2.4-.6.5-1.4.8-2.4.8-1.1 0-1.9-.3-2.4-.8-.6-.6-.8-1.4-.8-2.4v-6.8h2V49c0 .6.1 1 .3 1.2.2.2.5.4 1 .4s.8-.1 1-.4.3-.6.3-1.2v-6.9h1.9zm1.5 9.9V42h2.6c1.2 0 2.1.4 2.8 1.1.7.7 1 1.8 1.1 3v1.6c0 1.3-.3 2.3-1 3.1-.7.7-1.6 1.1-2.9 1.1h-2.6zm2.1-8.3v6.6h.6c.7 0 1.1-.2 1.4-.5.3-.4.4-1 .4-1.8v-1.7c0-.9-.1-1.6-.4-1.9-.3-.4-.7-.6-1.3-.6h-.7zm8 8.3h-2V42h2v10zm8.7-4.1c0 1.3-.3 2.4-.9 3.1-.6.7-1.5 1.1-2.6 1.1s-2-.4-2.6-1.1-1-1.8-1-3.1v-1.7c0-1.4.3-2.4 1-3.2.6-.8 1.5-1.2 2.6-1.2s2 .4 2.6 1.1c.6.8 1 1.8 1 3.2v1.8zm-2.1-1.6c0-.9-.1-1.6-.4-2s-.7-.7-1.2-.7-.9.2-1.2.6c-.3.4-.4 1.1-.4 1.9v1.8c0 .9.1 1.5.4 1.9.3.4.7.6 1.2.6s.9-.2 1.2-.6.4-1 .4-1.9v-1.6z"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {props.label}
    </text>
  </svg>
);

SvgAudio.propTypes = {
  label: PropTypes.string
};
SvgAudio.defaultProps = {
  label: "AUDIO"
};

export default SvgAudio;
