import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTimeout from "react-timeout";
import { reloadSitemap, reloadSitemapSilent } from "../actions";
import { contentWillChange } from "../actions/content";
import { logoutPrepare } from "../actions/login";
import { setUsersiteReload, resetUsersiteDetail } from "../actions/usersite";
import SitemapReloader from "../components/SitemapReloader";
var AppConfig = require("../config.json");

class SitemapReload extends Component {
  constructor(props) {
    super(props);
    this.goLogout = this.goLogout.bind(this);
    this.reloadSitemap = this.reloadSitemap.bind(this);
  }
  goLogout() {
    this.props.dispatch(logoutPrepare());
    this.props.dispatch(contentWillChange("logout"));
  }
  reloadSitemap(evt) {
    this.props.dispatch(resetUsersiteDetail());
    this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
    this.props.dispatch(reloadSitemap(this.props.employeeId));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isAuthenticated &&
      !this.props.isLoggingOut &&
      !this.props.isAuthenticating
    ) {
      if (this.props.sitemapPages.length < 1 && this.props.hasContent) {
        this.props.dispatch(resetUsersiteDetail());
        this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
        this.props.dispatch(reloadSitemapSilent(this.props.employeeId));
        // this.props.setTimeout(this.goLogout, 300);
        return;
      }
      if (
        prevProps.sitemapChanged > 0 &&
        prevProps.sitemapChanged < this.props.sitemapChanged &&
        !this.props.reload &&
        !prevProps.reload
      ) {
        this.props.dispatch(setUsersiteReload(true));
        return;
      }
    }
  }

  render() {
    return (
      <Fragment>
        <SitemapReloader
          handleClick={this.reloadSitemap}
          showReload={this.props.reload}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    sitemapChanged: state.auth.sitemapChanged,
    sitemapPages: state.sitemap.pages,
    employeeId: state.auth.employeeId,
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    reload: state.usersite.reload,
    hasContent: state.usersite.hasContent,
    isLoggingOut: state.auth.isLoggingOut
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactTimeout(SitemapReload));
