import React, { Fragment } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import {
  contentWillChange,
  contentWillChangeWithReset
} from "../../actions/content";
import { ltrim } from "../../utils";

import Slide from "@material-ui/core/Slide";
import Hidden from "@material-ui/core/Hidden";

import { menuOpen, menuClose } from "../../actions/menu";
import CloseIcon from "@material-ui/icons/Close";
import MenuDrawerItems from "./MenuDrawerItems";
const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: theme.palette.primary.light
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: 200
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: 200
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(14) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  closeMenuButton: {
    marginTop: 5,
    marginLeft: "auto",
    marginRight: 5
  },
  drawerPaper: {
    width: drawerWidth
  }
}));

function HideOnScroll(props) {
  const { children, auth, initialLogin } = props;
  var trigger = !auth;

  return (
    <Slide appear={false} direction="right" in={!trigger && !initialLogin}>
      {children}
    </Slide>
  );
}

function MenuDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();

  function handleDrawerToggle() {
    if (props.menu.open) {
      props.dispatch(menuClose());
    } else {
      props.dispatch(menuOpen());
    }
  }

  function handleClick(link) {
    props.dispatch(contentWillChangeWithReset(link));
  }
  function handleContentClick(link) {
    props.dispatch(contentWillChange(link));
  }
  return (
    <Fragment>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.menu.open}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.closeMenuButton}
          >
            <CloseIcon />
          </IconButton>
          <MenuDrawerItems
            handleClick={handleClick}
            handleContentClick={handleContentClick}
            mobile={true}
            sitemap={props.sitemap}
            isSalesperson={props.isSalesperson}
            isCustomermaster={props.isCustomermaster}
            menuOpen={props.menu.open}
            pathname={ltrim(props.pathname, "/")}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <HideOnScroll
          auth={props.auth && !props.logout}
          initialLogin={props.initialLogin}
        >
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: props.menu.open,
              [classes.drawerClose]: !props.menu.open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: props.menu.open,
                [classes.drawerClose]: !props.menu.open
              })
            }}
            open={props.menu.open}
          >
            <div className={classes.toolbar}>
              {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
            </div>
            <MenuDrawerItems
              handleClick={handleClick}
              handleContentClick={handleContentClick}
              mobile={false}
              sitemap={props.sitemap}
              isSalesperson={props.isSalesperson}
              isCustomermaster={props.isCustomermaster}
              menuOpen={props.menu.open}
              pathname={ltrim(props.pathname, "/")}
            />
          </Drawer>
        </HideOnScroll>
      </Hidden>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth.isAuthenticated,
    initialLogin: state.auth.initialLogin,
    isSalesperson: state.auth.isSalesperson,
    isCustomermaster: state.auth.isCustomermaster,
    logout: state.auth.isLoggingOut,
    pathname: state.router.location.pathname,
    menu: state.menu,
    sitemap: state.sitemap
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuDrawer);
