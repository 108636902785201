import React from "react";

const SvgJson = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#9777a8"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"JSON"}
    </text>
    <g fill="#9777a8">
      <path d="M19.5 19v-4c0-.551.448-1 1-1a1 1 0 100-2c-1.654 0-3 1.346-3 3v4c0 1.103-.897 2-2 2a1 1 0 100 2c1.103 0 2 .897 2 2v4c0 1.654 1.346 3 3 3a1 1 0 100-2c-.552 0-1-.449-1-1v-4c0-1.2-.542-2.266-1.382-3a3.975 3.975 0 001.382-3z" />
      <circle cx={27.5} cy={18.5} r={1.5} />
      <path d="M39.5 21c-1.103 0-2-.897-2-2v-4c0-1.654-1.346-3-3-3a1 1 0 100 2c.552 0 1 .449 1 1v4c0 1.2.542 2.266 1.382 3a3.975 3.975 0 00-1.382 3v4c0 .551-.448 1-1 1a1 1 0 100 2c1.654 0 3-1.346 3-3v-4c0-1.103.897-2 2-2a1 1 0 100-2zm-12 3a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z" />
    </g>
  </svg>
);

export default SvgJson;
