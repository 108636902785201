import React from "react";

const SvgExe = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#9777a8"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"EXE"}
    </text>
    <path
      d="M33.5 32a1.001 1.001 0 01-.955-1.299l5-16a1 1 0 011.909.597l-5 16A1 1 0 0133.5 32z"
      fill="#9777a8"
    />
    <circle cx={29} cy={19.5} r={1.5} fill="#9777a8" />
    <circle cx={29} cy={26.5} r={1.5} fill="#9777a8" />
    <path
      d="M23.5 30h-2c-3.86 0-7-3.14-7-7s3.14-7 7-7h2a1 1 0 010 2h-2c-2.757 0-5 2.243-5 5s2.243 5 5 5h2a1 1 0 010 2z"
      fill="#9777a8"
    />
  </svg>
);

export default SvgExe;
