import {
  ABO_SALES_DEAL_IN_PROGRESS,
  ABO_SALES_SET_DEAL_CHANGED,
  ABO_SALES_SET_DEAL_ARTICLES,
  ABO_SALES_SET_DEAL_CLIENT,
  ABO_SALES_SET_DEAL_MONTH,
  ABO_SALES_SET_DEAL_COMPLETE,
  ABO_SALES_SET_DEAL_EDITMODE,
  ABO_SALES_START_DEAL,
  ABO_SALES_SET_DEAL_TITLE,
  ABO_SALES_SET_CLIENT_ADDRESS,
  ABO_SALES_SET_DEAL_FROM_SAVED_ITEMS,
  ABO_SALES_DEAL_RESET
} from "../store/constants";

export const setAboSalesDealFromSavedItem = data => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_FROM_SAVED_ITEMS,
    payload: data
  });
};
export const setAboSalesDealProgress = (bool, id) => dispatch => {
  dispatch({
    type: ABO_SALES_DEAL_IN_PROGRESS,
    payload: {
      isset: bool,
      id: id
    }
  });
};
export const setAboSalesClientAddress = data => dispatch => {
  dispatch({
    type: ABO_SALES_SET_CLIENT_ADDRESS,
    payload: data
  });
};
export const setAboSalesDealEditMode = bool => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_EDITMODE,
    payload: bool
  });
};
export const setAboSalesDealTitle = data => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_TITLE,
    payload: data
  });
};
export const setAboSalesDealLastChanged = data => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_CHANGED,
    payload: data
  });
};
export const setAboSalesDealArticles = data => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_ARTICLES,
    payload: data
  });
};

export const setAboSalesDealClient = data => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_CLIENT,
    payload: data
  });
};
export const setAboSalesDealMonth = data => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_MONTH,
    payload: data
  });
};

export const setAboSalesDealComplete = () => dispatch => {
  dispatch({
    type: ABO_SALES_SET_DEAL_COMPLETE,
    payload: {}
  });
};
export const startNewAboSalesDeal = () => dispatch => {
  dispatch({
    type: ABO_SALES_START_DEAL,
    payload: {}
  });
};
export const resetAboSalesDeal = () => dispatch => {
  dispatch({
    type: ABO_SALES_DEAL_RESET,
    payload: {}
  });
};
