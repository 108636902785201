import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { doNewAuth } from "../actions/auth";
import { resetUsersiteCurrent } from "../actions/usersite";
import { resetCustomerContacts } from "../actions/contacts";
import { resetShipping } from "../actions/shipping";
import { resetCustomer } from "../actions/customer";
import { passwordReset } from "../actions/password";
import { resetUsersiteWithoutFav } from "../actions/usersite";
import { resetSitemap } from "../actions/sitemap";
import { resetAboSalesDeal } from "../actions/abosales";
import { resetAboArticles } from "../actions/aboarticles";
import { resetAboClients } from "../actions/aboclients";
import { resetDeals } from "../actions/abodeals";
import { logout } from "../actions/login";

class AppController extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.pathname !== prevProps.pathname) {
      this.props.dispatch(doNewAuth());
    }
    if (this.props.resetSitemapSelects !== prevProps.resetSitemapSelects) {
      if (!this.props.resetSitemapSelects) {
        this.props.dispatch(resetUsersiteCurrent());
      }
    }
    if (
      this.props.controlledLogout &&
      this.props.controlledLogout !== prevProps.controlledLogout
    ) {
      this.props.dispatch(resetShipping());
      this.props.dispatch(passwordReset());
      this.props.dispatch(resetCustomerContacts());
      this.props.dispatch(resetCustomer());
      this.props.dispatch(passwordReset());
      this.props.dispatch(resetUsersiteWithoutFav());
      this.props.dispatch(resetAboSalesDeal());
      this.props.dispatch(resetDeals());
      this.props.dispatch(resetAboClients());
      this.props.dispatch(resetAboArticles());
      this.props.dispatch(resetSitemap());
      this.props.dispatch(logout());
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
    controlledLogout: state.auth.controlledLogout,
    resetSitemapSelects: state.content.resetSitemapSelects
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppController);
