import { createReducer } from "../../utils";
import { NOT_FOUND } from "../constants";

const initialState = {
  status: false
};

export default createReducer(initialState, {
  [NOT_FOUND]: (state, payload) => {
    return Object.assign({}, state, {
      status: payload
    });
  }
});
