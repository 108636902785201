import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  getAboArticles,
  getAboDealClient,
  getAboClientsCount,
  getAboClients
} from "../../actions";
import AboSalesDealNoActions from "./AboSalesDealNoActions";
import AboSalesDealGrid from "./AboSalesDealGrid";

class AboSalesDealContainer extends Component {
  constructor(props) {
    super(props);
    this.loadSingleClient = this.loadSingleClient.bind(this);
    this.checkClients = this.checkClients.bind(this);
    this.loadClientAddresses = this.loadClientAddresses.bind(this);
    this.loadArticles = this.loadArticles.bind(this);
  }
  componentDidMount() {
    if (!this.props.aboarticles.loaded) {
      this.loadArticles();
    }
    if (this.props.clientId !== 0) {
      this.loadSingleClient();
    }
    if (this.props.userId !== 0) {
      this.checkClients();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.clientCount > 0 &&
      this.props.clientCount <= 50 &&
      this.props.clientCount !== prevProps.clientCount
    ) {
      this.loadClientAddresses("getaddresses", {});
    }
    if (
      this.props.clientId !== 0 &&
      this.props.clientId !== prevProps.clientId
    ) {
      this.loadSingleClient();
    }
  }

  loadArticles() {
    this.props.dispatch(getAboArticles());
  }

  loadSingleClient() {
    this.props.dispatch(getAboDealClient(this.props.clientId));
  }
  loadClientAddresses(command, form) {
    this.props.dispatch(getAboClients(command, form));
  }
  checkClients() {
    this.props.dispatch(getAboClientsCount());
  }

  render() {
    return (
      <Fragment>
        {this.props.aboarticles.loaded ? (
          this.props.aboarticles.items.length ? (
            <AboSalesDealGrid
              clickSave={this.props.clickSave}
              printAbo={this.props.printAbo}
              sendAboPerMail={this.props.sendAboPerMail}
            />
          ) : (
            <AboSalesDealNoActions />
          )
        ) : (
          <div>Artikel werden geladen</div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    aboarticles: state.aboarticles,
    clientId: state.abosales.deal.clientId,
    clientCount: state.aboclients.count,
    userId: state.auth.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboSalesDealContainer);
