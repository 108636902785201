import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FolderIcon from "@material-ui/icons/Folder";
import CardMediaFileType from "./CardMediaFileType";
import Link from "@material-ui/core/Link";
import { getContentItemParentSpecs } from "../../utils";
import { contentWillChange } from "../../actions/content";
import { startDownloadRequest } from "../../actions/downloads";
import { saveContentFavorites, requestDownload } from "../../actions";
import {
  setUsersiteFavorites,
  setUsersiteDetail
} from "../../actions/usersite";
import MenuToolTip from "../menu/MenuToolTip";

import {
  GradeOutlined as Tutorials,
  CloudDownloadOutlined as Downloads,
  CloudDownload as DownloadIcon,
  Search as SearchIcon
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15
    },
    "& .MuiCardHeader-content": {
      maxWidth: "calc(100% - 60px)",
      overflow: "hidden"
    }
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  mediageneric: {
    position: "relative",
    background: "#f7f7f7",
    "& svg": {
      position: "absolute",
      left: "15%",
      top: "15%"
    }
  },
  excerpt: {
    height: 80,
    maxHeight: 80,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: 40,
      maxHeight: 40
    },
    [theme.breakpoints.up("sm")]: {
      height: 60,
      maxHeight: 60
    },
    [theme.breakpoints.up("md")]: {
      height: 40,
      maxHeight: 40
    }
  },
  cardtitlecat: {
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "86%",
    fontSize: "1rem"
  },
  cardtitle: {
    fontWeight: 700,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "86%"
  },
  cardheadline: {
    fontWeight: 700,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  breadcrumb: {
    "& ol": {
      flexWrap: "nowrap"
    }
  },
  paper: {
    position: "relative",
    marginTop: theme.spacing(1)
  },
  breadcrumblink: {
    fontWeight: 500,
    cursor: "pointer"
  },
  cardactions: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const getIcon = values => {
  if (values.subCatSmarturl === "tutorials") {
    return <Tutorials />;
  } else if (values.subCatSmarturl === "downloads") {
    return <Downloads />;
  }
  return <FolderIcon />;
};

function ContentElementGrid(props) {
  const classes = useStyles(props);

  const [values, setValues] = React.useState(getContentItemParentSpecs(props));

  const icon = getIcon(values);

  const breadcrumbClick = href => {
    props.dispatch(contentWillChange(href));
  };

  const handleClickDownload = id => {
    props.dispatch(startDownloadRequest());
    props.dispatch(requestDownload("element", id));
  };
  const handleClickDetails = id => {
    props.dispatch(setUsersiteDetail(props.id));
  };
  const handleClickFavorite = id => {
    if (props.usersite.favorites.indexOf(id) !== -1) {
      props.dispatch(
        setUsersiteFavorites(
          props.usersite.favorites.filter(item => item !== id)
        )
      );
      props.dispatch(
        saveContentFavorites(
          { favorites: props.usersite.favorites.filter(item => item !== id) },
          props.employeeId
        )
      );
    } else {
      props.dispatch(setUsersiteFavorites([...props.usersite.favorites, id]));

      props.dispatch(
        saveContentFavorites(
          { favorites: [...props.usersite.favorites, id] },
          props.employeeId
        )
      );
    }
  };

  // const cardmedia = <CardMediaFileType {...props} />;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Box className={classes.paper}>
        <Card className={classes.card} elevation={0}>
          {!props.isContentPage && (
            <CardHeader
              avatar={<Avatar aria-label={props.headline}>{icon}</Avatar>}
              title={
                <Breadcrumbs
                  separator="»"
                  aria-label="breadcrumb"
                  className={classes.breadcrumb}
                >
                  <Link
                    className={classes.breadcrumblink}
                    onClick={() => breadcrumbClick(values.mainCatSmarturl)}
                  >
                    {values.mainCatLabel}
                  </Link>
                  <Link
                    className={classes.breadcrumblink}
                    onClick={() =>
                      breadcrumbClick(
                        values.mainCatSmarturl.concat(
                          "/",
                          values.subCatSmarturl
                        )
                      )
                    }
                  >
                    {values.subCatLabel}
                  </Link>
                </Breadcrumbs>
              }
              subheader={values.catGroupLabel}
              disableTypography={true}
            />
          )}

          <CardActionArea onClick={handleClickDetails}>
            {props.preview.length > 0 ? (
              <CardMedia
                className={classes.media}
                image={props.preview}
                title={props.headline}
              />
            ) : (
              <Box className={clsx(classes.media, classes.mediageneric)}>
                <CardMediaFileType {...props} />
              </Box>
            )}
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                className={classes.cardheadline}
              >
                {props.headline}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.excerpt}
              >
                {props.copytext
                  .replace(/^(.{90}[^\s]*).*/, "$1")
                  .concat(props.copytext.length > 90 ? "…" : "")}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing className={classes.cardactions}>
            <Box>
              <IconButton
                aria-label="Zu den Favoriten"
                onClick={() => handleClickFavorite(props.id)}
              >
                <MenuToolTip
                  title={
                    props.usersite.favorites.indexOf(props.id) !== -1
                      ? "Aus Favoriten entfernen"
                      : "Zu den Favoriten"
                  }
                  placement="top"
                  icon={
                    <FavoriteIcon
                      color={
                        props.usersite.favorites.indexOf(props.id) !== -1
                          ? `secondary`
                          : `disabled`
                      }
                    />
                  }
                />
              </IconButton>
            </Box>
            <Box>
              {values.subCatSmarturl === "downloads" && (
                <IconButton
                  aria-label="Download"
                  onClick={() => handleClickDownload(props.id)}
                >
                  <MenuToolTip
                    title="Datei jetzt downloaden"
                    placement="top"
                    icon={<DownloadIcon color="disabled" />}
                  />
                </IconButton>
              )}
              <IconButton
                aria-label="Details"
                onClick={() => handleClickDetails(props.id)}
              >
                <MenuToolTip
                  title="Details ansehen"
                  placement="top"
                  icon={<SearchIcon color="disabled" />}
                />
              </IconButton>
            </Box>
          </CardActions>
        </Card>
      </Box>
    </Grid>
  );
}

ContentElementGrid.propTypes = {
  isContentPage: PropTypes.bool
};
ContentElementGrid.defaultProps = {
  isContentPage: true
};

const mapStateToProps = state => {
  return {
    sitemap: state.sitemap,
    usersite: state.usersite,
    customerId: state.auth.customerId,
    employeeId: state.auth.employeeId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentElementGrid);
