import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Hidden, TextField, Typography, Box, Button } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import PropTypes from "prop-types";
import SelectInputSimple from "../SelectInputSimple";
import { validate } from "../../utils";
import withConfirm from "material-ui-confirm";
import isEqual from "lodash/isEqual";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(3)
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  divider: {
    marginBottom: theme.spacing(5)
  },
  radioLabel: {
    color: theme.palette.grey[600]
  },
  checkbox: {
    marginRight: theme.spacing(12)
  },
  paper: {
    height: "100%",
    padding: theme.spacing(1, 2)
  },
  proceed: {
    height: "100%",
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  proceedbtn: {
    marginTop: "auto",
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  },
  deletebtn: {
    color: theme.palette.error.main,
    marginBottom: "20px"
  }
}));

const checkForm = (values, props) => {
  var regexFields = [],
    propFields = {},
    hasError = false;
  Object.keys(AppConfig.forms.aboclient)
    .filter(n => AppConfig.forms.aboclient[n].hasOwnProperty("regxp"))
    .map(n => {
      return regexFields.push(n);
    });

  Object.keys(AppConfig.forms.aboclient).map(n => {
    return (propFields[n] = props[n]);
  });

  for (let index = 0; index < regexFields.length; index++) {
    var name = regexFields[index];
    if (AppConfig.forms.aboclient[name].hasOwnProperty("regxp")) {
      if (!validate(values[name], AppConfig.forms.aboclient[name].regxp)) {
        hasError = true;
      }
    }
  }
  var equalForms = props.isnew ? false : isEqual(propFields, values);

  const notFilled = Object.keys(AppConfig.forms.aboclient).filter(
    n =>
      AppConfig.forms.aboclient[n].required &&
      ((Array.isArray(AppConfig.forms.aboclient[n].default) &&
        values[n].length < 1) ||
        values[n] === "")
  );

  if (!hasError && notFilled.length < 1 && !equalForms) {
    return true;
  } else {
    return false;
  }
};

const AboSalesDealClientItem = props => {
  const classes = useStyles();
  var fields = {};
  Object.keys(AppConfig.forms.aboclient).map(n => {
    return Object.assign(fields, {
      [n]: props[n]
    });
  });
  const [values, setValues] = React.useState(fields);

  const saveContact = () => {
    props.saveAboClient(values);
  };

  const setChangeSelect = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (AppConfig.forms.aboclient[name].hasOwnProperty("regxp")) {
      if (!validate(value, AppConfig.forms.aboclient[name].regxp)) {
        setErrors({ ...errors, [name]: true });
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
  };

  var errorFields = {};
  Object.keys(AppConfig.forms.aboclient)
    .filter(n => AppConfig.forms.aboclient[n].hasOwnProperty("regxp"))
    .map(n => {
      return Object.assign(errorFields, {
        [n]: false
      });
    });
  const [errors, setErrors] = React.useState(errorFields);

  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    setCanSubmit(checkForm(values, props));
  }, [props, values]);

  return (
    <Fragment>
      <form noValidate autoComplete="off">
        {!props.isnew && (
          <Box>
            <Typography variant="subtitle1" className={classes.heading}>
              Hinweis: Das Bearbeiten dieses Kunden führt zu einem Update aller
              Angebote mit dieser Kundenreferenz.
            </Typography>
          </Box>
        )}
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="company"
                autoComplete="_off"
                name="company"
                label={AppConfig.forms.aboclient.company.label}
                required={AppConfig.forms.aboclient.company.required}
                fullWidth
                className={classes.textField}
                value={values.company}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="customernumber"
                autoComplete="_off"
                name="customernumber"
                label={AppConfig.forms.aboclient.customernumber.label}
                required={AppConfig.forms.aboclient.customernumber.required}
                fullWidth
                className={classes.textField}
                value={values.customernumber}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <SelectInputSimple
                id="salutation"
                name="salutation"
                disabled={false}
                fullWidth
                label={AppConfig.forms.aboclient.salutation.label}
                required={AppConfig.forms.aboclient.salutation.required}
                value={values.salutation}
                onChange={setChangeSelect}
                options={AppConfig.forms.aboclient.salutation.options}
              />
            </Box>
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} sm={6} />
          </Hidden>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="firstname"
                autoComplete="_off"
                name="firstname"
                label={AppConfig.forms.aboclient.firstname.label}
                required={AppConfig.forms.aboclient.firstname.required}
                fullWidth
                className={classes.textField}
                value={values.firstname}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="lastname"
                autoComplete="_off"
                name="lastname"
                label={AppConfig.forms.aboclient.lastname.label}
                required={AppConfig.forms.aboclient.lastname.required}
                fullWidth
                className={classes.textField}
                value={values.lastname}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="street"
                autoComplete="_off"
                name="street"
                label={AppConfig.forms.aboclient.street.label}
                required={AppConfig.forms.aboclient.street.required}
                fullWidth
                className={classes.textField}
                value={values.street}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} sm={6} />
          </Hidden>

          <Grid item xs={12} sm={4} md={3}>
            <Box className={classes.paper}>
              <TextField
                id="zipcode"
                autoComplete="_off"
                name="zipcode"
                error={errors.zipcode}
                label={AppConfig.forms.aboclient.zipcode.label}
                required={AppConfig.forms.aboclient.zipcode.required}
                fullWidth
                className={classes.textField}
                value={values.zipcode}
                helperText={
                  errors.zipcode
                    ? "PLZ unvollständig"
                    : values.zipcode.length > 0
                    ? "PLZ gültig"
                    : "5-stellige PLZ angeben"
                }
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Box className={classes.paper}>
              <TextField
                id="city"
                autoComplete="_off"
                name="city"
                label={AppConfig.forms.aboclient.city.label}
                required={AppConfig.forms.aboclient.city.required}
                fullWidth
                className={classes.textField}
                value={values.city}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="email"
                autoComplete="_off"
                name="email"
                type="email"
                error={errors.email}
                label={AppConfig.forms.aboclient.email.label}
                required={AppConfig.forms.aboclient.email.required}
                fullWidth
                className={classes.textField}
                value={values.email}
                helperText={
                  errors.email
                    ? "Email-Adresse unvollständig"
                    : values.email.length > 0
                    ? "Email-Adressformat gültig"
                    : "Email-Adresse angeben"
                }
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="phone"
                autoComplete="_off"
                name="phone"
                type="tel"
                label={AppConfig.forms.aboclient.phone.label}
                required={AppConfig.forms.aboclient.phone.required}
                fullWidth
                className={classes.textField}
                value={values.phone}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="mobile"
                autoComplete="_off"
                name="mobile"
                type="tel"
                label={AppConfig.forms.aboclient.mobile.label}
                required={AppConfig.forms.aboclient.mobile.required}
                fullWidth
                className={classes.textField}
                value={values.mobile}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Hidden only="xs">
            <Grid item xs={12} sm={6} md={8} />
          </Hidden>

          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.proceed}>
              <Button
                disabled={!canSubmit}
                variant="contained"
                color="primary"
                fullWidth
                className={classes.proceedbtn}
                onClick={saveContact}
              >
                Speichern
                <Save className={classes.rightIcon} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

AboSalesDealClientItem.propTypes = {
  isnew: PropTypes.bool
};
AboSalesDealClientItem.defaultProps = {
  isnew: false
};

export default withConfirm(AboSalesDealClientItem);
