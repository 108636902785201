import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  }
}));

export default function AboSalesDealNoActions() {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.error}>
      Artikelstamm konnte nicht geladen werden - Vorgänge sind leider nicht
      möglich.
    </Typography>
  );
}
