import React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FavoritesList from "./FavoritesList";
import GridListAction from "./GridListAction";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark
  },
  root: {
    flexGrow: 1
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3)
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "70px",
    marginBottom: theme.spacing(10)
    // justifyContent: "center"
  }
}));

export default function Favorites(props) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xl" component="main">
        <Box className={classes.title}>
          <Box p={2}>
            <GridListAction label={AppConfig.pages.favorites.headings.main} />
          </Box>
        </Box>

        <FavoritesList />
      </Container>
    </Box>
  );
}
