import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  heading: {
    marginBottom: theme.spacing(3)
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "80px"
    // justifyContent: "center"
  }
}));

export default function LoginForm(props) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xs" component="main">
        <Paper p={3} bgcolor="white">
          <Box p={3}>
            <form className={classes.form} onSubmit={props.handleSubmit}>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.heading}
                >
                  YOLii Kunden-Portal Login
                </Typography>
                <TextField
                  id="username"
                  label="Benutzername"
                  placeholder="Benutzername"
                  onChange={props.handleChange("username")}
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  autoFocus
                />
                <TextField
                  id="password"
                  type="password"
                  label="Passwort"
                  placeholder="Passwort"
                  onChange={props.handleChange("password")}
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                />
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  {props.buttonlabel}
                </Button>
              </div>
            </form>
            <Divider />
            <Button
              className={classes.submit}
              type="button"
              fullWidth
              onClick={props.gotoReset}
            >
              Passwort vergessen?
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
