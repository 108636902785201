import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Typography, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContentElementGrid from "./ContentElementGrid";
import ContentElementList from "./ContentElementList";
import GridListAction from "./GridListAction";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark
  },
  root: {
    flexGrow: 1
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative"
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "70px"
    // justifyContent: "center"
  }
}));

function Pinned(props) {
  const classes = useStyles();

  var pinned = [];
  props.sitemap.pages
    .filter(cat => cat.id === props.usersite.main)
    .map(cat =>
      cat.subcategories.map(sub =>
        sub.categorygroups
          .filter(gr => gr.contentelements.length > 0)
          .map(gr =>
            gr.contentelements
              .filter(item => item.pinned)
              .map(elem => pinned.push(elem))
          )
      )
    );

  return (
    <Grid container className={classes.root} spacing={3}>
      {pinned.length > 0 && (
        <Fragment>
          <Grid item xs={12}>
            <Box className={classes.title}>
              <Box p={2}>
                <GridListAction
                  label={AppConfig.pages.maincategories.headings.pinned.concat(
                    " ",
                    props.label
                  )}
                />
              </Box>
            </Box>
          </Grid>
          {pinned.map(elem =>
            props.usersite.layout === "grid" ? (
              <ContentElementGrid
                isContentPage={false}
                {...elem}
                key={elem.id}
              />
            ) : (
              <ContentElementList
                isContentPage={false}
                {...elem}
                key={elem.id}
              />
            )
          )}
        </Fragment>
      )}
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pinned);
