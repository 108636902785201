import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Hidden, TextField, Typography, Box, Button } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import Fingerprint from "@material-ui/icons/Fingerprint";
import { validate } from "../../utils";
import { saveLogindata } from "../../actions";
import { openPasswordOverlay } from "../../actions/password";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(1)
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: "100%",
    padding: theme.spacing(1, 2)
  },
  proceed: {
    height: "100%",
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  proceedbtn: {
    marginTop: "auto",
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  },
  rightIconPw: {
    marginLeft: theme.spacing(3),
    color: "grey"
  },
  pwbtn: {
    color: "grey"
  }
}));

const checkForm = (values, props) => {
  var regexFields = [],
    propFields = {},
    hasError = false;
  Object.keys(AppConfig.forms.logindata)
    .filter(n => AppConfig.forms.logindata[n].hasOwnProperty("regxp"))
    .map(n => {
      return regexFields.push(n);
    });

  Object.keys(AppConfig.forms.logindata).map(n => {
    return (propFields[n] = props.auth[n]);
  });

  for (let index = 0; index < regexFields.length; index++) {
    var name = regexFields[index];
    if (AppConfig.forms.logindata[name].hasOwnProperty("regxp")) {
      if (!validate(values[name], AppConfig.forms.logindata[name].regxp)) {
        hasError = true;
      }
    }
  }
  var equalForms = isEqual(propFields, values);

  const notFilled = Object.keys(AppConfig.forms.logindata).filter(
    n =>
      AppConfig.forms.logindata[n].required &&
      ((Array.isArray(AppConfig.forms.logindata[n].default) &&
        values[n].length < 1) ||
        values[n] === "")
  );

  if (!hasError && notFilled.length < 1 && !equalForms) {
    return true;
  } else {
    return false;
  }
};

const EmployeeLoginData = props => {
  const classes = useStyles();
  var fields = {};
  Object.keys(AppConfig.forms.logindata).map(n => {
    return Object.assign(fields, {
      [n]: props.auth[n]
    });
  });
  const [values, setValues] = React.useState(fields);

  const saveLogin = () => {
    props.dispatch(saveLogindata(values, props.auth.employeeId));
  };

  const changePassword = () => {
    props.dispatch(openPasswordOverlay());
  };

  var errorFields = {};
  Object.keys(AppConfig.forms.logindata)
    .filter(n => AppConfig.forms.logindata[n].hasOwnProperty("regxp"))
    .map(n => {
      return Object.assign(errorFields, {
        [n]: false
      });
    });
  const [errors, setErrors] = React.useState(errorFields);

  const handleChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (AppConfig.forms.logindata[name].hasOwnProperty("regxp")) {
      if (
        !validate(value, AppConfig.forms.logindata[name].regxp) &&
        value !== ""
      ) {
        setErrors({ ...errors, [name]: true });
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
  };

  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    setCanSubmit(checkForm(values, props));
  }, [props, values]);

  return (
    <Fragment>
      <form noValidate autoComplete="off">
        <Box>
          <Typography component="h1" variant="h6" className={classes.heading}>
            {AppConfig.pages.employee.headings.logindata}
          </Typography>
        </Box>
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="username"
                autoComplete="_off"
                name="username"
                label={AppConfig.forms.logindata.username.label}
                required={AppConfig.forms.logindata.username.required}
                fullWidth
                className={classes.textField}
                value={values.username}
                onChange={handleChange}
                error={errors.username}
                helperText={
                  errors.username
                    ? "Benutzername noch nicht gültig"
                    : values.username.length > 0
                    ? "Benutzername gültig"
                    : "Mindestens 6 Zeichen (Buchstaben und Zahlen)"
                }
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="email"
                autoComplete="_off"
                name="email"
                type="email"
                error={errors.email}
                label={AppConfig.forms.logindata.email.label}
                required={AppConfig.forms.logindata.email.required}
                fullWidth
                className={classes.textField}
                value={values.email}
                helperText={
                  errors.email
                    ? "Email-Adresse unvollständig"
                    : values.email.length > 0
                    ? "Email-Adressformat gültig"
                    : "Email-Adresse angeben"
                }
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Hidden only="xs">
            <Grid item xs={12} sm={5} md={4}>
              <Box className={classes.proceed}>
                <Button
                  variant="outlined"
                  color="default"
                  fullWidth
                  className={classes.pwbtn}
                  onClick={changePassword}
                >
                  Passwort ändern
                  <Fingerprint className={classes.rightIconPw} />
                </Button>
              </Box>
            </Grid>
          </Hidden>

          <Hidden only="xs">
            <Grid item xs={12} sm={1} md={4} />
          </Hidden>

          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.proceed}>
              <Button
                disabled={!canSubmit}
                variant="contained"
                color="primary"
                fullWidth
                className={classes.proceedbtn}
                onClick={saveLogin}
              >
                Speichern
                <Save className={classes.rightIcon} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeLoginData);
