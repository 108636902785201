import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Notfound from "../components/Notfound";
import { contentWillChange } from "../actions/content";
import { setNotFound } from "../actions";

var AppConfig = require("../config.json");

class NotFoundView extends Component {
  constructor(props) {
    super(props);
    this.goto = this.goto.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(setNotFound(true));
  }
  goto() {
    this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Seite nicht gefunden</title>
        </Helmet>
        <Notfound goto={this.goto} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.order
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFoundView);
