import {
  ABO_CLIENTS_SET,
  ABO_CLIENTS_CHECK_DATA,
  ABO_CLIENTS_ADD_ONE_TO_COUNT,
  ABO_CLIENTS_RESET
} from "../store/constants";

export const setAboClients = data => dispatch => {
  dispatch({
    type: ABO_CLIENTS_SET,
    payload: data
  });
};
export const setAboClientsHasData = data => dispatch => {
  dispatch({
    type: ABO_CLIENTS_CHECK_DATA,
    payload: data
  });
};
export const increaseAboClientsCountByOne = () => dispatch => {
  dispatch({
    type: ABO_CLIENTS_ADD_ONE_TO_COUNT,
    payload: {}
  });
};
export const resetAboClients = () => dispatch => {
  dispatch({
    type: ABO_CLIENTS_RESET,
    payload: {}
  });
};
