import React, { Fragment } from "react";
import { Typography, Grid, Container, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { contentWillChange } from "../../actions/content";
import SubcategoryActions from "./SubcategoryActions";
import SubcategoryGroup from "../contents/SubcategoryGroup";

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark
  },
  root: {
    flexGrow: 1
  },
  button: {
    marginBottom: theme.spacing(3),
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  },
  refresh: {
    position: "absolute",
    right: 2,
    top: 2,
    zIndex: 1
  },
  error: {
    color: theme.palette.error.main,
    fontStyle: "italic"
  },
  buttoncontainer: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3)
  },
  papersearch: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6)
  },
  tablecontainer: {
    overflowX: "auto",
    width: "100%"
  },
  filterReset: {
    position: "absolute",
    top: 8,
    left: 60
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "70px",
    paddingBottom: theme.spacing(10)
    // justifyContent: "center"
  },
  gridItemText: {
    order: 0,
    [theme.breakpoints.down("sm")]: {
      order: 1,
      marginTop: theme.spacing(-3)
    }
  },
  gridItemActions: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 0
    }
  }
}));
const getParagraphWithLinebreaks = str => (
  <Typography
    paragraph
    dangerouslySetInnerHTML={{ __html: str.replace("\n", "<br>") }}
  />
);
export default function Subcategory(props) {
  const classes = useStyles();

  function handleMenuClick(url) {
    props.dispatch(contentWillChange(url));
  }

  const mainPage = props.sitemap.pages.filter(item => {
    return item.id === props.usersite.main;
  })[0];
  const subPage = mainPage.subcategories.filter(
    subitem => subitem.id === props.usersite.sub
  )[0];

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xl" component="main">
        <Box className={classes.title}>
          <Box p={2}>
            <Typography component="h1" variant="h6" className={classes.heading}>
              {mainPage.headline.concat(" · ", subPage.headline)}
            </Typography>
          </Box>
        </Box>

        <Grid container className={classes.root} spacing={5}>
          <Grid
            item
            xs={12}
            md={8}
            bgcolor="white"
            className={classes.gridItemText}
          >
            <Box
              className={clsx(classes.paper, classes.paperlist)}
              bgcolor="white"
            >
              {getParagraphWithLinebreaks(subPage.copytext)}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridItemActions}>
            <Box className={classes.paper} bgcolor="white">
              <SubcategoryActions
                {...props}
                mainPage={mainPage}
                subPage={subPage}
                disabled={subPage.categorygroups.length < 1}
              />
            </Box>
          </Grid>
        </Grid>

        <SubcategoryGroup />
      </Container>
    </Box>
  );
}
