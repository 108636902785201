import React from "react";

const SvgYolii = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M37 0H8c-.8 0-1.5.7-1.5 1.9V55c0 .3.7 1 1.5 1h40c.8 0 1.5-.7 1.5-1V13c0-.7-.1-.9-.3-1.1L37.6.3c-.2-.2-.4-.3-.6-.3z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.2V12h11.8z" />
    <path
      d="M48 56H8c-.8 0-1.5-.7-1.5-1.5V39h43v15.5c0 .8-.7 1.5-1.5 1.5z"
      fill="#94acd9"
    />
    <path
      fill="#b2c0d6"
      d="M27 25.2c0-4.7-9.5-9.5-9.5-9.5v10.8l9.6 5.2c0 .1-.1-1.8-.1-6.5zm-8.5-11l9.1-5.8L37 14s-6.4 4.6-9.5 4.5c-3.1-.1-9-4.3-9-4.3zM29 25.3c0 4.7-.1 6.6-.1 6.6l9.6-5.2V15.9c0-.1-9.5 4.7-9.5 9.4z"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"YOLii"}
    </text>
  </svg>
);

export default SvgYolii;
