import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Grid, Typography, Box } from "@material-ui/core";
import AboSalesDealClientAdd from "./AboSalesDealClientAdd";
import AboSalesDealClientEdit from "./AboSalesDealClientEdit";
import AboSalesDealClientSearch from "./AboSalesDealClientSearch";
import { saveCurrentAboClient } from "../../actions";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },

  buttonFirst: {
    marginTop: 0,
    marginLeft: 0
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const getClientAddressArr = data => {
  var arr = [],
    str = "";
  if (data.company !== "") {
    arr.push(data.company);
  }
  if (data.customernumber !== "") {
    arr.push("Kd-Nr: " + data.customernumber);
  }
  if (data.salutation !== "") {
    str += data.salutation + " ";
  }
  if (data.firstname !== "") {
    str += data.firstname + " ";
  }
  if (data.lastname !== "") {
    str += data.lastname.trim();
  }
  if (str !== "") {
    arr.push(str);
    str = "";
  }
  if (data.street !== "") {
    arr.push(data.street);
  }
  if (data.zipcode !== "") {
    str += data.zipcode + " ";
  }
  if (data.city !== "") {
    str += data.city.trim();
  }
  if (str !== "") {
    arr.push(str);
  }
  if (data.email !== "") {
    arr.push(data.email);
  }
  if (data.phone !== "") {
    arr.push(data.phone);
  }
  if (data.mobile !== "") {
    arr.push(data.mobile);
  }
  return arr;
};

function AboSalesDealClient(props) {
  const classes = useStyles();

  const clickSave = form => {
    props.dispatch(saveCurrentAboClient(form, 0, true));
  };

  const clickEdit = form => {
    props.dispatch(
      saveCurrentAboClient(form, props.abosales.deal.clientId, false)
    );
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={6} md={5} lg={7} bgcolor="white">
        <Box className={clsx(classes.paper, classes.paperlist)} bgcolor="white">
          {props.abosales.deal.clientId === 0 ||
            (props.abosales.deal.clientId === null && (
              <Box className={classes.hint}>Kunde noch nicht festlegt</Box>
            ))}
          {props.abosales.deal.clientId > 0 && (
            <Typography variant="body1">
              {getClientAddressArr(props.abosales.clientAddress).map(
                (n, index) => {
                  return (
                    <Fragment key={index}>
                      {n}
                      <br />
                    </Fragment>
                  );
                }
              )}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={7} lg={5} bgcolor="white">
        <Box className={clsx(classes.paper, classes.paperlist)} bgcolor="white">
          <AboSalesDealClientAdd
            saveAboClient={clickSave}
            disabled={
              props.abosales.progress.id !== 0 &&
              props.abosales.deal.clientId > 0
            }
          />
          <AboSalesDealClientSearch
            disabled={
              (props.abosales.progress.id !== 0 &&
                props.abosales.deal.clientId > 0) ||
              (props.abosales.deal.clientId > 0
                ? props.clientCount < 2
                : props.clientCount === 0)
            }
          />
          <AboSalesDealClientEdit
            saveAboClient={clickEdit}
            clientAddress={props.abosales.clientAddress}
            disabled={
              props.abosales.deal.clientId === 0 ||
              props.abosales.deal.clientId === null
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    abosales: state.abosales,
    clientCount: state.aboclients.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboSalesDealClient);
