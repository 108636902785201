import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.secondary.dark
  },
  root: {
    flexGrow: 1
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  paper: {
    height: "100%",
    padding: theme.spacing(2, 3)
  },
  wrapper: props => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100000,
    backgroundColor: fade(theme.palette.secondary.dark, 0.7),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.shorter
    }),
    opacity: !props.fade ? 1 : 0
  })
}));

export default function SectionIncompleteOverlay(props) {
  const classes = useStyles(props);

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="sm" component="main">
        <Paper bgcolor="white" className={classes.title}>
          <Box p={2}>
            <Typography variant="h6" className={classes.heading}>
              Hinweis
            </Typography>
            <Typography variant="body1">
              Ein vorheriger Schritt wurde nicht korrekt vervollständigt.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              className={classes.button}
              onClick={props.hanldeClick}
            >
              Zurück zu: {props.buttonLabel}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
