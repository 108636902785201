import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LoginView,
  LogoutView,
  CustomerView,
  ShippingView,
  CustomerContactsView,
  EmployeeView,
  DashboardView,
  CategoryMainView,
  CategorySubView,
  FavoritesView,
  PasswordResetView,
  AboDashboardView,
  AboDealsListView,
  NotFoundView
} from "../views";

import RouteAuthenticated from "./RouteAuthenticated";
var AppConfig = require("../config.json");

const loginByUuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
const regexMainCat = /^(technik|schulung|marketing|content|vertrieb)$/;
const regexSubCat = /^(technik|schulung|marketing|content|vertrieb)\/(tutorials|downloads)$/;

export default (
  <Switch>
    <Redirect exact from="/" to={AppConfig.pages.dashboard.url} />
    <Route exact path={`/${AppConfig.pages.login.url}`} component={LoginView} />
    <Route
      exact
      path={`/${AppConfig.pages.forgot.url}`}
      component={PasswordResetView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.dashboard.url}`}
      component={DashboardView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.customer.url}`}
      component={CustomerView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.shipping.url}`}
      component={ShippingView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.contactpersons.url}`}
      component={CustomerContactsView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.employee.url}`}
      component={EmployeeView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.favorites.url}`}
      component={FavoritesView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.abodashboard.url}`}
      component={AboDashboardView}
    />
    <RouteAuthenticated
      exact
      path={`/${AppConfig.pages.abodealslist.url}`}
      component={AboDealsListView}
    />

    <RouteAuthenticated exact path="/logout" component={LogoutView} />
    <Route
      path="/:maincat"
      exact
      strict
      render={({ match }) => {
        if (regexMainCat.test(match.params.maincat)) {
          return <RouteAuthenticated component={CategoryMainView} />;
        }
        return <NotFoundView />;
      }}
    />
    <Route
      path="/:maincat/:subcat"
      exact
      strict
      render={({ match }) => {
        if (
          regexSubCat.test(
            match.params.maincat.concat("/", match.params.subcat)
          )
        ) {
          return <RouteAuthenticated component={CategorySubView} />;
        }
        return <NotFoundView />;
      }}
    />
    <Route
      path="/:uuid"
      exact
      strict
      render={({ match }) => {
        if (loginByUuid.test(match.params.uuid)) {
          return <RouteAuthenticated component={LoginView} />;
        }
        return <NotFoundView />;
      }}
    />
    <Route path="/404" component={NotFoundView} />

    <Route component={NotFoundView} />
  </Switch>
);
