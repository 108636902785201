import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { setUsersiteFilter } from "../../actions/usersite";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  }
}));

export default function SubcategoryFilter(props) {
  const classes = useStyles();

  const handleClick = id => {
    if (props.filter.indexOf(id) !== -1) {
      props.dispatch(
        setUsersiteFilter({
          key: props.filterKey,
          data: props.filter.filter(item => item !== id)
        })
      );
    } else {
      props.dispatch(
        setUsersiteFilter({ key: props.filterKey, data: [...props.filter, id] })
      );
    }
  };

  return (
    <div className={classes.root}>
      {props.groups
        .filter(item => item.contentelements.length > 0)
        .map(item => (
          <Chip
            key={item.id}
            label={item.menulabel}
            color={
              props.filter.indexOf(item.id) !== -1 ? `secondary` : `default`
            }
            onClick={() => handleClick(item.id)}
          />
        ))}
    </div>
  );
}
