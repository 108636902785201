import { createReducer } from "../../utils";
import {
  ABO_CLIENTS_SET,
  ABO_CLIENTS_CHECK_DATA,
  ABO_CLIENTS_ADD_ONE_TO_COUNT,
  ABO_CLIENTS_RESET
} from "../constants";

const initialState = {
  items: [],
  loaded: false,
  count: 0
};

export default createReducer(initialState, {
  [ABO_CLIENTS_SET]: (state, payload) => {
    return Object.assign({}, state, {
      items: payload.items,
      loaded: true
    });
  },
  [ABO_CLIENTS_CHECK_DATA]: (state, payload) => {
    return Object.assign({}, state, {
      count: payload
    });
  },
  [ABO_CLIENTS_ADD_ONE_TO_COUNT]: (state, payload) => {
    var num = state.count + 1;
    return Object.assign({}, state, {
      count: num
    });
  },
  [ABO_CLIENTS_RESET]: (state, payload) => {
    return initialState;
  }
});
