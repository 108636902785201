import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Refresh from "@material-ui/icons/Refresh";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
  IconButton
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialogTitle-root": {
      paddingTop: theme.spacing(2),
      paddingBottom: 0
    }
  },
  heading: {
    color: theme.palette.primary.dark
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function SitemapReloader(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.showReload}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="form-dialog-title"
      className={classes.root}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6" className={classes.heading}>
          Änderung der Seiteninhalte
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.paper}>
          <Typography paragraph className={classes.bodytext}>
            Da sich kürzlich die Seiteninhalte geändert haben oder neue Inhalte
            hinzugefügt wurden, laden sie diese bitte per Knopfdruck neu.
          </Typography>
          <Divider />
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            fullWidth
            onClick={props.handleClick}
          >
            Inhalte neu laden
            <Refresh className={classes.rightIcon} />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
