import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import SelectInputSimple from "../SelectInputSimple";
import { validate } from "../../utils";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(1)
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  textField: {},
  checkbox: {
    marginRight: theme.spacing(12)
  },
  paper: {
    height: "100%",
    padding: theme.spacing(1, 2)
  }
}));

export default function CustomerPersonalSpecs(props) {
  const classes = useStyles();
  var fields = {};
  Object.keys(AppConfig.forms.customer).map(n => {
    return Object.assign(fields, {
      [n]: props.customer.form[n]
    });
  });
  const [values, setValues] = React.useState(fields);

  var errorFields = {};
  Object.keys(AppConfig.forms.customer)
    .filter(n => AppConfig.forms.customer[n].hasOwnProperty("regxp"))
    .map(n => {
      return Object.assign(errorFields, {
        [n]: false
      });
    });
  const [errors, setErrors] = React.useState(errorFields);
  // const [checkbox, setCheckbox] = React.useState(
  //   props.order.customer.shippingaddress
  // );

  // const handleChangeCheckbox = event => {
  //   setCheckbox(event.target.checked);
  //   props.setShippingAddress(event.target.checked);
  // };
  const handleChange = event => {
    const { name, value } = event.target;
    setChange(name, value);
  };
  const setChange = (name, value) => {
    setValues({ ...values, [name]: value });

    if (AppConfig.forms.customer[name].hasOwnProperty("regxp")) {
      if (
        !validate(value, AppConfig.forms.customer[name].regxp) &&
        value !== ""
      ) {
        setErrors({ ...errors, [name]: true });
        props.setFormValue(props.formtype, name, "");
        return;
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
    props.setFormValue(props.formtype, name, value);
  };

  return (
    <Fragment>
      <form noValidate autoComplete="off">
        <Box p={2}>
          <Typography component="h1" variant="h6" className={classes.heading}>
            {AppConfig.pages.customer.headings.addressdata}
          </Typography>
          <Typography variant="body1" className={classes.heading}>
            {`Kundennummer: ${props.customer.form.customernumber}`}
          </Typography>
        </Box>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="company"
                autoComplete="_off"
                name="company"
                label={AppConfig.forms.customer.company.label}
                required={AppConfig.forms.customer.company.required}
                fullWidth
                className={classes.textField}
                value={values.company}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} sm={6} />
          </Hidden>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="street"
                autoComplete="_off"
                name="street"
                label={AppConfig.forms.customer.street.label}
                required={AppConfig.forms.customer.street.required}
                fullWidth
                className={classes.textField}
                value={values.street}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} sm={6} />
          </Hidden>

          <Grid item xs={12} sm={4} md={3}>
            <Box className={classes.paper}>
              <TextField
                id="zipcode"
                autoComplete="_off"
                name="zipcode"
                error={errors.zipcode}
                label={AppConfig.forms.customer.zipcode.label}
                required={AppConfig.forms.customer.zipcode.required}
                fullWidth
                className={classes.textField}
                value={values.zipcode}
                helperText={
                  errors.zipcode
                    ? "PLZ unvollständig"
                    : values.zipcode.length > 0
                    ? "PLZ gültig"
                    : "5-stellige PLZ angeben"
                }
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Box className={classes.paper}>
              <TextField
                id="city"
                autoComplete="_off"
                name="city"
                label={AppConfig.forms.customer.city.label}
                required={AppConfig.forms.customer.city.required}
                fullWidth
                className={classes.textField}
                value={values.city}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
        </Grid>

        <Box p={2}>
          <Typography
            component="h1"
            variant="h6"
            className={clsx(classes.heading, classes.headingMargin)}
          >
            {AppConfig.pages.customer.headings.personalcontact}
          </Typography>
        </Box>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <SelectInputSimple
                id="salutation"
                name="salutation"
                disabled={false}
                fullWidth
                label={AppConfig.forms.customer.salutation.label}
                required={AppConfig.forms.customer.salutation.required}
                value={values.salutation}
                onChange={setChange}
                options={AppConfig.forms.customer.salutation.options}
              />
            </Box>
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} sm={6} />
          </Hidden>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="firstname"
                autoComplete="_off"
                name="firstname"
                label={AppConfig.forms.customer.firstname.label}
                required={AppConfig.forms.customer.firstname.required}
                fullWidth
                className={classes.textField}
                value={values.firstname}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="lastname"
                autoComplete="_off"
                name="lastname"
                label={AppConfig.forms.customer.lastname.label}
                required={AppConfig.forms.customer.lastname.required}
                fullWidth
                className={classes.textField}
                value={values.lastname}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="email"
                autoComplete="_off"
                name="email"
                type="email"
                error={errors.email}
                label={AppConfig.forms.customer.email.label}
                required={AppConfig.forms.customer.email.required}
                fullWidth
                className={classes.textField}
                value={values.email}
                helperText={
                  errors.email
                    ? "Email-Adresse unvollständig"
                    : values.email.length > 0
                    ? "Email-Adressformat gültig"
                    : "Email-Adresse angeben"
                }
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="phone"
                autoComplete="_off"
                name="phone"
                type="tel"
                label={AppConfig.forms.customer.phone.label}
                required={AppConfig.forms.customer.phone.required}
                fullWidth
                className={classes.textField}
                value={values.phone}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="mobile"
                autoComplete="_off"
                name="mobile"
                type="tel"
                label={AppConfig.forms.customer.mobile.label}
                required={AppConfig.forms.customer.mobile.required}
                fullWidth
                className={classes.textField}
                value={values.mobile}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}
