import { createReducer } from "../../utils";
import {
  USERSITE_SET_CURRENT,
  USERSITE_SET_FILTER,
  USERSITE_SET_SEARCH,
  USERSITE_SET_DETAIL,
  USERSITE_RESET_DETAIL,
  USERSITE_SET_FAVORITES,
  USERSITE_SET_LAYOUT,
  USERSITE_SET_CAT_SEARCH,
  USERSITE_RELOAD,
  USERSITE_HAS_CONTENT,
  USERSITE_RESET_ALL_EXCEPT_FAV,
  USERSITE_RESET_CURRENT,
  USERSITE_RESET
} from "../constants";

const initialState = {
  main: 0,
  sub: 0,
  group: 0,
  filter: {},
  searchCat: {},
  favorites: [],
  search: "",
  detail: 0,
  detailView: false,
  reload: false,
  hasContent: false,
  layout: "list"
};

export default createReducer(initialState, {
  [USERSITE_SET_CURRENT]: (state, payload) => {
    return {
      ...state,
      main: payload.main,
      sub: payload.sub,
      group: payload.group
    };
  },
  [USERSITE_RELOAD]: (state, payload) => {
    return {
      ...state,
      reload: payload
    };
  },
  [USERSITE_HAS_CONTENT]: (state, payload) => {
    return {
      ...state,
      hasContent: payload
    };
  },
  [USERSITE_SET_LAYOUT]: (state, payload) => {
    return {
      ...state,
      layout: payload
    };
  },
  [USERSITE_SET_DETAIL]: (state, payload) => {
    return {
      ...state,
      detail: payload,
      detailView: true
    };
  },
  [USERSITE_RESET_DETAIL]: (state, payload) => {
    return {
      ...state,
      detail: {},
      detailView: false
    };
  },
  [USERSITE_SET_FAVORITES]: (state, payload) => {
    return {
      ...state,
      favorites: payload
    };
  },
  [USERSITE_SET_SEARCH]: (state, payload) => {
    return {
      ...state,
      search: payload
    };
  },
  [USERSITE_SET_FILTER]: (state, payload) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        [payload.key]: payload.data
      }
    };
  },
  [USERSITE_SET_CAT_SEARCH]: (state, payload) => {
    return {
      ...state,
      searchCat: {
        ...state.searchCat,
        [payload.key]: payload.data
      }
    };
  },
  [USERSITE_RESET_CURRENT]: (state, payload) => {
    return {
      ...state,
      main: 0,
      sub: 0,
      group: 0,
      search: "",
      detail: {},
      detailView: false
    };
  },
  [USERSITE_RESET_ALL_EXCEPT_FAV]: (state, payload) => {
    return {
      ...state,
      main: 0,
      sub: 0,
      group: 0,
      search: "",
      searchCat: {},
      filter: {},
      detail: {},
      detailView: false
    };
  },
  [USERSITE_RESET]: (state, payload) => {
    return initialState;
  }
});
