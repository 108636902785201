import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import isEqual from "lodash/isEqual";

import {
  setEmployeeCanProceed,
  setEmployeeCanNotProceed
} from "../actions/employee";
import { contentWillChange } from "../actions/content";
import { saveEmployee } from "../actions/";

import Employee from "../components/customer/Employee";

var AppConfig = require("../config.json");

class EmployeeView extends Component {
  constructor(props) {
    super(props);
    this.setFormValue = this.setFormValue.bind(this);
    this.checkForms = this.checkForms.bind(this);
    this.saveEmployee = this.saveEmployee.bind(this);
    this.goHome = this.goHome.bind(this);

    var employeeform = {
      id: this.props.employee.form.id
    };
    Object.keys(AppConfig.forms.employee).map(n => {
      return Object.assign(employeeform, {
        [n]: this.props.employee.form[n]
      });
    });

    this.state = {
      mount: true,
      typed: false,
      employeeform: employeeform
    };
  }
  setFormValue(form, name, value) {
    this.setState(
      {
        employeeform: { ...this.state.employeeform, [name]: value },
        typed: true
      },
      this.checkForms
    );
  }
  saveEmployee() {
    const form = Object.assign({}, this.state.employeeform);
    delete form.id;
    this.props.dispatch(saveEmployee(form, this.state.employeeform.id));
  }
  goHome() {
    this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
  }

  checkForms() {
    const notFilledEmployee = Object.keys(AppConfig.forms.employee).filter(
      n =>
        AppConfig.forms.employee[n].required &&
        ((Array.isArray(AppConfig.forms.employee[n].default) &&
          this.state.employeeform[n].length < 1) ||
          this.state.employeeform[n] === "")
    );
    var equalForms = isEqual(this.state.employeeform, this.props.employee.form);
    if (this.state.typed && !equalForms && notFilledEmployee.length < 1) {
      this.props.dispatch(setEmployeeCanProceed());
    } else {
      if (this.props.employee.isset) {
        this.props.dispatch(setEmployeeCanNotProceed());
      }
    }
  }
  componentDidMount() {
    this.setState({ mount: false }, this.checkForms);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mount || prevState.mount) {
      return;
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{AppConfig.pages.employee.label}</title>
        </Helmet>
        <Employee
          {...this.props}
          setFormValue={this.setFormValue}
          saveActive={this.props.employee.isset}
          saveEmployee={this.saveEmployee}
          goHome={this.goHome}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    employee: state.employee
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeView);
