const MaterialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} von {count}",
    labelRowsSelect: "Datensätze",
    firstAriaLabel: "Erste Seite",
    firstTooltip: "Erste Seite",
    previousAriaLabel: "Vorige Seite",
    previousTooltip: "Vorige Seite",
    nextAriaLabel: "Nächste Seite",
    nextTooltip: "Nächste Seite",
    lastAriaLabel: "Letzte Seite",
    lastTooltip: "Letzte Seite"
  },
  toolbar: {
    nRowsSelected: "{0} Datensätze ausgewählt",
    searchPlaceholder: "Suche in Ergebnissen",
    searchTooltip: "Suche"
  },
  header: {
    actions: "Aktion"
  },

  body: {
    emptyDataSourceMessage: "Keine Datensätze gefunden",
    filterRow: {
      filterTooltip: "Filter"
    }
  }
};
export default MaterialTableLocalization;
