import { createReducer } from "../../utils";
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_PREPARE,
  LOGOUT_USER,
  LOGOUT_CONTROLLED,
  AUTH_CHECK,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_RE_CHECK,
  AUTH_NEEDS_RELOG,
  LOGIN_USER_DATA_UPDATE
} from "../constants";

const initialState = {
  token: null,
  username: null,
  userId: null,
  email: null,
  customerId: 0,
  employeeId: 0,
  sitemapChanged: 0,
  initialLogin: false,
  isSalesperson: false,
  isCustomermaster: false,
  commissionView: false,
  isAuthenticated: false,
  isAuthenticating: false,
  isLoggingOut: false,
  doAuth: true,
  needsRelog: false,
  controlledLogout: false
};

export default createReducer(initialState, {
  [LOGIN_USER_REQUEST]: (state, payload) => {
    return Object.assign({}, state, {
      isAuthenticating: true,
      statusText: null
    });
  },
  [LOGIN_USER_SUCCESS]: (state, payload) => {
    return {
      ...state,
      isAuthenticating: false,
      isAuthenticated: true,
      isLoggingOut: false,
      doAuth: false,
      needsRelog: false,
      initialLogin: payload.initialLogin,
      isSalesperson: payload.isSalesperson,
      isCustomermaster: payload.isCustomermaster,
      commissionView: payload.commissionView,
      token: payload.token,
      username: payload.username,
      email: payload.email,
      userId: payload.id,
      sitemapChanged: payload.sitemapChanged,
      customerId: payload.customerId,
      employeeId: payload.employeeId
    };
  },
  [LOGIN_USER_FAILURE]: (state, payload) => {
    return initialState;
  },
  [LOGIN_USER_DATA_UPDATE]: (state, payload) => {
    return Object.assign({}, state, {
      username: payload.username,
      email: payload.email
    });
  },
  [LOGOUT_PREPARE]: (state, payload) => {
    return Object.assign({}, state, {
      isLoggingOut: true,
      doAuth: false,
      needsRelog: false
    });
  },
  [LOGOUT_CONTROLLED]: (state, payload) => {
    return Object.assign({}, state, {
      controlledLogout: true
    });
  },
  [LOGOUT_USER]: (state, payload) => {
    return initialState;
  },
  [AUTH_RE_CHECK]: (state, payload) => {
    return Object.assign({}, state, {
      doAuth: true,
      needsRelog: false
    });
  },
  [AUTH_NEEDS_RELOG]: (state, payload) => {
    return Object.assign({}, state, {
      needsRelog: true,
      doAuth: false
    });
  },
  [AUTH_CHECK]: (state, payload) => {
    return Object.assign({}, state, {
      isAuthenticating: true,
      doAuth: false,
      needsRelog: false
    });
  },
  [AUTH_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      isAuthenticating: false,
      isAuthenticated: true,
      doAuth: false,
      needsRelog: false,
      initialLogin: payload.initialLogin,
      isSalesperson: payload.isSalesperson,
      isCustomermaster: payload.isCustomermaster,
      commissionView: payload.commissionView,
      token: payload.token,
      userId: payload.id,
      customerId: payload.customerId,
      employeeId: payload.employeeId,
      email: payload.email,
      sitemapChanged: payload.sitemapChanged,
      username: payload.username
    });
  },
  [AUTH_FAILURE]: (state, payload) => {
    return initialState;
  }
});
