import { createReducer } from "../../utils";
import {
  ABO_PRINT_LINK,
  ABO_PRINT_LINK_RESET,
  ABO_PRINT_RUNTIME,
  ABO_PRINT_SAVE_FIRST
} from "../constants";

const initialState = {
  pdflink: "",
  runtime: 0,
  saveFirst: false,
  open: false
};

export default createReducer(initialState, {
  [ABO_PRINT_LINK]: (state, payload) => {
    return {
      ...state,
      pdflink: payload,
      saveFirst: false,
      open: true
    };
  },
  [ABO_PRINT_RUNTIME]: (state, payload) => {
    return {
      ...state,
      runtime: payload
    };
  },
  [ABO_PRINT_SAVE_FIRST]: (state, payload) => {
    return {
      ...state,
      saveFirst: payload
    };
  },
  [ABO_PRINT_LINK_RESET]: (state, payload) => {
    return initialState;
  }
});
