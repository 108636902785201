import {
  DOWNLOAD_REQUEST,
  DOWNLOAD_START,
  DOWNLOAD_IDLE
} from "../store/constants";

export const startDownloadRequest = () => dispatch => {
  dispatch({
    type: DOWNLOAD_REQUEST,
    payload: {}
  });
};
export const resetDownload = () => dispatch => {
  dispatch({
    type: DOWNLOAD_IDLE,
    payload: {}
  });
};

export const setDownloadLink = data => dispatch => {
  dispatch({
    type: DOWNLOAD_START,
    payload: data
  });
};
