import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },

  address: {
    fontSize: "inherit"
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const getClientAddressArr = filteredArr => {
  if (filteredArr.length < 1) {
    return [];
  }

  var data = filteredArr[0],
    arr = [],
    str = "",
    name = "";
  if (data.company !== "") {
    str += data.company + " ";
  }
  if (data.salutation !== "") {
    name += data.salutation + " ";
  }
  if (data.firstname !== "") {
    name += data.firstname + " ";
  }
  if (data.lastname !== "") {
    name += data.lastname.trim();
  }
  if (str !== "") {
    if (str !== "" && name !== "") {
      str += "· ";
    }
    str += name;
    arr.push(str);
    str = "";
  }
  if (data.street !== "") {
    str += data.street + " ";
  }
  if (data.zipcode !== "") {
    if (str !== "") {
      str += "· ";
    }
    str += data.zipcode + " ";
  }
  if (data.city !== "") {
    str += data.city.trim();
  }
  if (str !== "") {
    arr.push(str);
  }

  return arr;
};

function DealsListTableDataClient(props) {
  const classes = useStyles();

  const filteredAddress = props.addresses.filter(n => n.id === props.addressId);

  return (
    <Box className={clsx(classes.paper, classes.paperlist)} bgcolor="white">
      {props.addressId === 0 && (
        <Box className={classes.hint}>Kunde nicht festlegt</Box>
      )}
      {props.addressId > 0 && (
        <Typography variant="body1" className={classes.address}>
          {getClientAddressArr(filteredAddress).map((n, index) => {
            return (
              <Fragment key={index}>
                {n}
                <br />
              </Fragment>
            );
          })}
        </Typography>
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    addresses: state.abodeals.addresses
  };
};

export default connect(
  mapStateToProps,
  null
)(DealsListTableDataClient);
