import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { makeStyles } from "@material-ui/core/styles";
import { resetDownload } from "../actions/downloads";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  pdf: {
    margin: theme.spacing(3, 0, 2)
  },
  hint: {
    textAlign: "center"
  }
}));

function DownloadListener(props) {
  const classes = useStyles(props);

  const handleClose = () => {
    props.dispatch(resetDownload());
  };

  return (
    <Dialog
      open={props.downloads.link !== "" && props.downloads.requested}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogContent>
        <div className={classes.paper}>
          <Typography className={classes.hint}>
            Starten Sie ihren Download mit einem Klick.
          </Typography>
          {props.downloads.link !== "" && props.downloads.requested && (
            <Button
              className={classes.pdf}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => window.open(props.downloads.link)}
            >
              {props.buttondownload}
              <DownloadIcon className={classes.rightIcon} />
            </Button>
          )}

          <Button className={classes.submit} fullWidth onClick={handleClose}>
            Schliessen
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
DownloadListener.propTypes = {
  print: PropTypes.object,
  buttondownload: PropTypes.string
};
DownloadListener.defaultProps = {
  buttondownload: "Download starten"
};

const mapStateToProps = state => {
  return {
    downloads: state.downloads
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadListener);
