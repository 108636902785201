import {
  PASSWORD_OVERLAY,
  PASSWORD_CHANGED,
  PASSWORD_RESET
} from "../store/constants";

export const openPasswordOverlay = () => dispatch => {
  dispatch({
    type: PASSWORD_OVERLAY,
    payload: true
  });
};
export const closePasswordOverlay = () => dispatch => {
  dispatch({
    type: PASSWORD_OVERLAY,
    payload: false
  });
};
export const passwordChanged = () => dispatch => {
  dispatch({
    type: PASSWORD_CHANGED,
    payload: true
  });
};
export const passwordReset = () => dispatch => {
  dispatch({
    type: PASSWORD_RESET,
    payload: {}
  });
};
