import {
  ABO_PRINT_LINK,
  ABO_PRINT_LINK_RESET,
  ABO_PRINT_RUNTIME,
  ABO_PRINT_SAVE_FIRST
} from "../store/constants";

export const setAboPrintLink = href => dispatch => {
  dispatch({
    type: ABO_PRINT_LINK,
    payload: href
  });
};
export const resetAboPrintLink = () => dispatch => {
  dispatch({
    type: ABO_PRINT_LINK_RESET,
    payload: ""
  });
};
export const setAboPrintSaveFirst = bool => dispatch => {
  dispatch({
    type: ABO_PRINT_SAVE_FIRST,
    payload: bool
  });
};
export const setAboPrintRuntime = runtime => dispatch => {
  dispatch({
    type: ABO_PRINT_RUNTIME,
    payload: runtime
  });
};
