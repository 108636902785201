import { CONFIGS_SET, CONFIGS_RESET } from "../store/constants";

export const setAdditionalConfigs = data => dispatch => {
  dispatch({
    type: CONFIGS_SET,
    payload: data
  });
};
export const resetAdditionalConfigs = () => dispatch => {
  dispatch({
    type: CONFIGS_RESET,
    payload: {}
  });
};
