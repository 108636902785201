import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  formControl: props => ({
    opacity: props.disabled ? 0.5 : 1,
    width: props.fullWidth ? "100%" : "auto"
  }),
  select: {
    width: "100%"
  }
}));

SelectInputSimple.propTypes = {
  optionsLabels: PropTypes.object
};
SelectInputSimple.defaultProps = {
  optionsLabels: {}
};
export default function SelectInputSimple(props) {
  const classes = useStyles(props);

  const handleChange = name => event => {
    props.onChange(name, event.target.value);
  };

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl
      required={props.required}
      disabled={props.disabled}
      variant="outlined"
      margin="dense"
      className={classes.formControl}
    >
      <InputLabel ref={inputLabel} htmlFor={props.type}>
        {props.label}
      </InputLabel>
      <Select
        native
        fullWidth={props.fullWidth}
        value={props.value}
        onChange={handleChange(props.name)}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name={props.name}
            id={props.name}
          />
        }
      >
        <option value="" />
        {props.options.map(val => (
          <option key={val} value={val}>
            {props.optionsLabels.hasOwnProperty(val)
              ? props.optionsLabels[val]
              : val}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
