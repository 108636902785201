import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Typography, Grid, Button, Box } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AboSalesDealProjectTitle from "./AboSalesDealProjectTitle";
import AboSalesDealClient from "./AboSalesDealClient";
import AboSalesDealArticleContainer from "./AboSalesDealArticleContainer";
import AboSalesDealRuntimesContainer from "./AboSalesDealRuntimesContainer";
import AboSalesDealCommissionContainer from "./AboSalesDealCommissionContainer";
import { formatTimestampToDateGerman } from "../../utils";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  root: {
    flexGrow: 1
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3)
  },
  centered: {
    textAlign: "center"
  },
  savebtn: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0)
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  }
}));

function AboSalesDealGrid(props) {
  const classes = useStyles();
  const clickSave = () => {
    props.clickSave(false);
  };

  return (
    <Fragment>
      <Box className={classes.title}>
        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={12} sm={7} md={9}>
            <Typography component="h1" variant="h6">
              {props.abosales.progress.id === 0
                ? "Neues Angebot"
                : `Angebot (Online-Nr: ${props.abosales.progress.id})`}
            </Typography>
            <Typography variant="subtitle2">
              Letzte Speicherung:{" "}
              {props.abosales.changed === 0
                ? "nie"
                : formatTimestampToDateGerman(props.abosales.changed)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              className={classes.savebtn}
              aria-label="Speichern"
              onClick={clickSave}
              disabled={!props.abosales.shouldBeSaved}
            >
              Projekt speichern <Save className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container className={classes.root} spacing={5}>
        <Grid item xs={12} md={4} bgcolor="white">
          <Box
            className={clsx(classes.paper, classes.paperlist)}
            bgcolor="white"
          >
            <Typography variant="button">Titel des Projektes</Typography>
            <AboSalesDealProjectTitle abosales={props.abosales} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8} bgcolor="white">
          <Box
            className={clsx(classes.paper, classes.paperlist)}
            bgcolor="white"
          >
            <Typography variant="button">Auswahl Kunde</Typography>
            <AboSalesDealClient />
          </Box>
        </Grid>

        <Grid item xs={12} bgcolor="white">
          {props.abosales.deal.title === "" &&
            props.abosales.deal.articles.length === 0 && (
              <Box className={classes.paper} bgcolor="white">
                <Typography variant="button">Artikel</Typography>

                <Box className={classes.hint}>
                  Für Artikelauswahl erst einen Titel festlegen
                </Box>
              </Box>
            )}
          {(props.abosales.deal.title !== "" ||
            props.abosales.deal.articles.length !== 0) && (
            <AboSalesDealArticleContainer />
          )}
        </Grid>
        <AboSalesDealRuntimesContainer
          printAbo={props.printAbo}
          sendAboPerMail={props.sendAboPerMail}
        />

        {props.commissionActive && <AboSalesDealCommissionContainer />}

        {props.abosales.deal.articles.length !== 0 && (
          <Grid item xs={12}>
            <Box className={clsx(classes.paper, classes.centered)}>
              <Typography variant="body2">
                * Preise verstehen sich zzgl. der gesetzl. MwSt.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    aboarticles: state.aboarticles,
    abosales: state.abosales,
    aboclients: state.aboclients,
    commissionActive: state.abodeals.commissionActive
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboSalesDealGrid);
