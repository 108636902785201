import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Cancel from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import EmployeePersonalSpecs from "./EmployeePersonalSpecs";
import EmployeeSaveAndProceed from "./EmployeeSaveAndProceed";
import EmployeeLoginData from "./EmployeeLoginData";
var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  titleLogin: {
    marginTop: theme.spacing(4)
  },
  leftIcon: {
    marginRight: theme.spacing(3)
  },
  cancel: {
    marginTop: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  wrapper: {
    width: "100%",
    display: "flex",
    marginTop: "70px",
    marginBottom: theme.spacing(10),
    justifyContent: "center"
  }
}));

export default function Employee(props) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xl" component="main">
        <Box className={classes.title}>
          <Box p={2}>
            <Typography component="h1" variant="h6">
              {AppConfig.pages.employee.headings.main}
            </Typography>
          </Box>
        </Box>
        <Box p={2} bgcolor="white" className={classes.title}>
          <EmployeePersonalSpecs {...props} formtype="personal" />
          <Divider variant="middle" className={classes.divider} />
          <EmployeeSaveAndProceed {...props} />
        </Box>
        <Box
          p={2}
          bgcolor="white"
          className={clsx(classes.title, classes.titleLogin)}
        >
          <EmployeeLoginData {...props} />
        </Box>
        <Button
          variant="contained"
          size="small"
          className={classes.cancel}
          onClick={props.goHome}
        >
          <Cancel className={classes.leftIcon} />
          Zurück
        </Button>
      </Container>
    </Box>
  );
}
