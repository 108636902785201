import { createReducer } from "../../utils";
import {
  PASSWORD_OVERLAY,
  PASSWORD_CHANGED,
  PASSWORD_RESET
} from "../constants";

const initialState = {
  open: false,
  changed: false
};

export default createReducer(initialState, {
  [PASSWORD_OVERLAY]: (state, payload) => {
    return Object.assign({}, state, {
      open: payload
    });
  },
  [PASSWORD_CHANGED]: (state, payload) => {
    return Object.assign({}, state, {
      changed: payload
    });
  },
  [PASSWORD_RESET]: (state, payload) => {
    return initialState;
  }
});
