import React, { Fragment } from "react";
import { Box, Typography, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Cancel from "@material-ui/icons/Cancel";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Button from "@material-ui/core/Button";
import CustomerContactItem from "./CustomerContactItem";
import CustomerContactAdd from "./CustomerContactAdd";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1),
    position: "relative"
  },
  leftIcon: {
    marginRight: theme.spacing(3)
  },
  cancel: {
    marginTop: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  wrapper: {
    width: "100%",
    display: "flex",
    marginTop: "70px",
    marginBottom: theme.spacing(10),
    justifyContent: "center"
  },
  hint: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11)
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(2)
  }
}));

var AppConfig = require("../../config.json");

export default function CustomerContacts(props) {
  const classes = useStyles();
  var currentTypes = [];

  const items = props.contacts.items.map((item, index) => {
    currentTypes.push(item.contactType);
    return (
      <CustomerContactItem
        key={item.id}
        {...item}
        isnew={false}
        saveCustomerContact={props.saveCustomerContact}
        last={index === props.contacts.items.length - 1}
      />
    );
  });

  const countItems = (arr, what) => {
    var count = 0,
      i;
    // console.warn(what);
    while ((i = arr.indexOf(what, i)) !== -1) {
      ++count;
      ++i;
    }
    return count;
  };

  const defaultContactTypes = Object.keys(
    AppConfig.pages.contactpersons.contactTypes
  );

  const missingEntries = defaultContactTypes.filter(
    n => currentTypes.indexOf(n) === -1 || countItems(currentTypes, n) < 2
  );

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xl" component="main">
        <Box className={classes.title}>
          <Box p={2}>
            <Typography component="h1" variant="h6" className={classes.heading}>
              {AppConfig.pages.contactpersons.headings.main}
            </Typography>
          </Box>
        </Box>
        <Box p={2} bgcolor="white" className={classes.title}>
          <CustomerContactAdd
            saveCustomerContact={props.saveCustomerContact}
            possibilities={missingEntries}
            customerId={props.auth.customerId}
          />

          <Grid container className={classes.root} spacing={2}>
            {missingEntries.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.hint}>
                  Sie können bis zu zwei Kontaktpersonen für folgende Bereiche
                  angeben:
                  <br />{" "}
                  {defaultContactTypes.map((item, index) => (
                    <Fragment key={index}>
                      {AppConfig.pages.contactpersons.contactTypes[item].concat(
                        index < defaultContactTypes.length - 1 ? " ," : ""
                      )}
                    </Fragment>
                  ))}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              {items}
            </Grid>
          </Grid>
        </Box>
        <Button
          variant="contained"
          size="small"
          className={classes.cancel}
          onClick={props.goHome}
        >
          <Cancel className={classes.leftIcon} />
          Zurück
        </Button>
      </Container>
    </Box>
  );
}
