import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_DATA_UPDATE,
  LOGOUT_USER,
  LOGOUT_CONTROLLED,
  LOGOUT_PREPARE
} from "../store/constants";

import LocalStorageUtils from "../utils/LocalStorageUtils";

export const loginUserSuccess = data => dispatch => {
  LocalStorageUtils.login(data);
  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: {
      token: data.token,
      username: data.username,
      email: data.email,
      id: data.id,
      customerId: data.customer.id,
      initialLogin: data.initialLogin,
      sitemapChanged: data.sitemapChanged,
      isSalesperson: data.isSalesperson,
      isCustomermaster: data.isCustomermaster,
      commissionView: data.commissionView,
      additionalConfig: data.additionalConfig
    }
  });
};

export const updateLoginData = data => dispatch => {
  dispatch({
    type: LOGIN_USER_DATA_UPDATE,
    payload: {
      email: data.email,
      username: data.username
    }
  });
};

export const loginUserFailure = error => dispatch => {
  LocalStorageUtils.notAuthorized();
  dispatch({
    type: LOGIN_USER_FAILURE,
    payload: {}
  });
};

export const loginUserRequest = () => dispatch => {
  dispatch({
    type: LOGIN_USER_REQUEST
  });
};

export const logout = () => dispatch => {
  LocalStorageUtils.logout();
  dispatch({
    type: LOGOUT_USER
  });
};
export const logoutPrepare = () => dispatch => {
  dispatch({
    type: LOGOUT_PREPARE
  });
};
export const controlledLogout = () => dispatch => {
  dispatch({
    type: LOGOUT_CONTROLLED
  });
};

export function logoutAndRedirect() {
  return dispatch => {
    dispatch(logoutPrepare());
  };
}
