import { createReducer } from "../../utils";
import {
  ABO_DEALS_DATA,
  ABO_DEALS_REQUEST,
  ABO_DEALS_PAGINATION,
  ABO_DEALS_REFRESH,
  ABO_DEALS_RESET,
  ABO_DEALS_TOGGLE_COMMISSION,
  ABO_DEALS_COMMISSION,
  ABO_DEALS_DETAIL,
  ABO_DEALS_USERID,
  ABO_DEALS_CLOSE_DETAIL
} from "../constants";

const initialState = {
  datasets: [],
  addresses: [],
  loaded: false,
  commissionActive: false,
  detail: {},
  detailView: false,
  commissionView: false,
  total: 0,
  request: {
    pagination: { page: 1, perPage: 25 },
    sort: { field: "id", order: "DESC" },
    filter: { usersId: 0 },
    search: ""
  }
};

export default createReducer(initialState, {
  [ABO_DEALS_TOGGLE_COMMISSION]: (state, payload) => {
    return {
      ...state,
      commissionActive: payload
    };
  },
  [ABO_DEALS_DATA]: (state, payload) => {
    return {
      ...state,
      loaded: true,
      datasets: payload.datasets,
      addresses: payload.addresses,
      total: payload.total
    };
  },
  [ABO_DEALS_USERID]: (state, payload) => {
    return {
      ...state,
      request: { ...state.request, filter: { usersId: payload } }
    };
  },
  [ABO_DEALS_REQUEST]: (state, payload) => {
    return {
      ...state,
      request: payload,
      loaded: false
    };
  },
  [ABO_DEALS_PAGINATION]: (state, payload) => {
    return {
      ...state,
      request: {
        ...state.request,
        pagination: {
          page: payload.page,
          perPage: payload.perPage
        }
      },
      loaded: false
    };
  },
  [ABO_DEALS_REFRESH]: (state, payload) => {
    return {
      ...state,
      loaded: false
    };
  },
  [ABO_DEALS_RESET]: (state, payload) => {
    return {
      ...state,
      datasets: [],
      addresses: [],
      loaded: false
    };
  },
  [ABO_DEALS_DETAIL]: (state, payload) => {
    return {
      ...state,
      detail: payload,
      detailView: true,
      commissionView: false
    };
  },
  [ABO_DEALS_CLOSE_DETAIL]: (state, payload) => {
    return {
      ...state,
      detail: {},
      detailView: false,
      commissionView: false
    };
  },
  [ABO_DEALS_COMMISSION]: (state, payload) => {
    return {
      ...state,
      detail: payload,
      commissionView: true,
      detailView: false
    };
  }
});
