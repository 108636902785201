import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import SentimentDissatisfied from "@material-ui/icons/SentimentDissatisfied";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  heading: {
    marginBottom: theme.spacing(3)
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "80px"
    // justifyContent: "center"
  },
  container: {}
}));

export default function Notfound(props) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xs" component="main" className={classes.container}>
        <Paper p={3} bgcolor="white">
          <Box p={3}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <SentimentDissatisfied />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
              >
                Seite nicht gefunden
              </Typography>

              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                onClick={props.goto}
              >
                Zur Startseite
              </Button>
            </div>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
