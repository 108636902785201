import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import SubcategoryList from "./SubcategoryList";
import GridListAction from "./GridListAction";
import { noStopword } from "../../utils";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    marginTop: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  heading: {
    color: theme.palette.primary.dark
  },
  title: {
    marginTop: theme.spacing(2)
  },
  searchtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2)
  }
}));

function SubcategoryGroup(props) {
  const classes = useStyles();

  var elements = [];

  const filterKey = "main".concat(
    props.usersite.main,
    "sub",
    props.usersite.sub
  );
  const hasFilter =
    props.usersite.filter.hasOwnProperty(filterKey) &&
    props.usersite.filter[filterKey].length > 0;

  props.sitemap.pages.map(cat =>
    cat.subcategories
      .filter(sub => sub.id === props.usersite.sub)
      .map(sub =>
        sub.categorygroups
          .filter(item => item.contentelements.length > 0)
          .filter(
            item =>
              !props.usersite.filter.hasOwnProperty(filterKey) ||
              props.usersite.filter[filterKey].length < 1 ||
              props.usersite.filter[filterKey].indexOf(item.id) !== -1
          )
          .map(elem => elements.push(elem))
      )
  );

  const searchKey = "main".concat(
    props.usersite.main,
    "sub",
    props.usersite.sub
  );

  const hasSearch =
    props.usersite.searchCat.hasOwnProperty(searchKey) &&
    props.usersite.searchCat[searchKey] !== "" &&
    props.usersite.searchCat[searchKey].replace(
      /[-[\]{}()*+?.,\\^$|#\s]/g,
      "\\$&"
    ).length > 3 &&
    noStopword(props.usersite.searchCat[searchKey]);

  elements = elements.filter(
    group =>
      group.contentelements.filter(item => {
        if (hasSearch) {
          var needle = props.usersite.searchCat[searchKey].replace(
            /[-[\]{}()*+?.,\\^$|#\s]/g,
            "\\$&"
          );
          var needleRegExp = new RegExp(needle, "i");
          var resultHeadline = needleRegExp.test(item.headline);
          var resultCopytext = needleRegExp.test(item.copytext);
          var resultTags = needleRegExp.test(item.tags);
          return resultHeadline || resultCopytext || resultTags;
        }
        return true;
      }).length > 0
  );

  //   const elements = props.group.contentelements.filter(item => {
  //     if (hasSearch) {
  //       var needle = props.sitemap.searchCat[searchKey].replace(
  //         /[-[\]{}()*+?.,\\^$|#\s]/g,
  //         "\\$&"
  //       );
  //       var needleRegExp = new RegExp(needle, "i");
  //       var resultHeadline = needleRegExp.test(item.headline);
  //       var resultCopytext = needleRegExp.test(item.copytext);
  //       var resultTags = needleRegExp.test(item.tags);
  //       return resultHeadline || resultCopytext || resultTags;
  //     }
  //     return true;
  //   });

  return (
    <Fragment>
      {elements.length > 0 ? (
        <Fragment>
          {hasSearch && (
            <Box className={classes.searchtitle}>
              <Box p={2}>
                <GridListAction
                  label={`Suchergebnisse für "${
                    props.usersite.searchCat[searchKey]
                  }"`}
                  hasButtons={true}
                />
              </Box>
            </Box>
          )}
          {elements.map((elem, index) => (
            <SubcategoryList
              group={elem}
              key={elem.id}
              buttons={!hasSearch && !index > 0}
            />
          ))}
        </Fragment>
      ) : hasSearch ? (
        <Box className={classes.title}>
          <Box p={2}>
            <Typography component="h1" variant="h6" className={classes.heading}>
              Keine Suchergebnisse für "{props.usersite.searchCat[searchKey]}"
            </Typography>
            {hasFilter && (
              <Typography variant="body2">
                Probieren Sie eine Suche ohne aktivierte Filter
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.searchtitle}>
          <Box p={2}>
            <Typography component="h1" variant="h6" className={classes.heading}>
              Keine Inhalte vorhanden
            </Typography>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubcategoryGroup);
