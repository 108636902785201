import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  modal: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 99999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modaloff: {
    display: "none"
  }
}));

function SaveActionNoClick(props) {
  const classes = useStyles();

  return (
    <Box className={props.inprogress ? classes.modal : classes.modaloff}>
      <CircularProgress className={classes.progress} color="secondary" />
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    inprogress: state.saving.inprogress
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveActionNoClick);
