import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Box, Button } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import { connect } from "react-redux";
import { setAboSalesDealTitle } from "../../actions/abosales";

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(1)
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: "100%",
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2)
  },
  proceed: {
    height: "100%",
    padding: theme.spacing(3, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  proceedbtn: {
    marginTop: "auto"
  }
}));

const checkForm = (title, props) => {
  if (title !== "" && props.abosales.deal.title !== title) {
    return true;
  } else {
    return false;
  }
};

const AboSalesDealProjectTitle = props => {
  const classes = useStyles();

  const [title, setTitle] = React.useState(props.abosales.deal.title);

  const saveTitle = () => {
    if (title !== "") {
      props.dispatch(setAboSalesDealTitle(title));
    }
  };

  const [error, setError] = React.useState(false);

  const handleChange = event => {
    const { value } = event.target;
    setTitle(value);
    if (value === "") {
      setError(true);
    } else {
      setError(false);
    }
  };
  function handleSubmit(evt) {
    evt.preventDefault();
    if (title === "" || !canSubmit) {
      return false;
    }
    saveTitle();
    return false;
  }
  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    setCanSubmit(checkForm(title, props));
  }, [props, title]);

  return (
    <Fragment>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className={classes.paper}>
          <TextField
            id="title"
            autoComplete="_off"
            name="title"
            label="Titel"
            required={true}
            fullWidth
            className={classes.textField}
            value={title}
            onChange={handleChange}
            error={error}
            helperText={
              error
                ? "Titel ungültig"
                : title !== ""
                ? title === props.abosales.deal.title
                  ? "Titel festgelegt"
                  : "Titel gültig"
                : "Bitte einen Titel angeben"
            }
            margin="dense"
            variant="outlined"
          />
        </Box>

        <Box className={classes.proceed}>
          <Button
            disabled={!canSubmit}
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.proceedbtn}
            onClick={saveTitle}
          >
            Speichern
            <Save className={classes.rightIcon} />
          </Button>
        </Box>
      </form>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AboSalesDealProjectTitle);
