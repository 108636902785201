import { createReducer } from "../../utils";
import {
  EMPLOYEE_SET,
  EMPLOYEE_RESET,
  EMPLOYEE_FORM_SET,
  EMPLOYEE_FORM_SAVED
} from "../constants";

const initialState = {
  form: {
    id: 0,
    salutation: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    mobile: ""
  },
  isset: false
};

export default createReducer(initialState, {
  [EMPLOYEE_FORM_SET]: (state, payload) => {
    return {
      ...state,
      form: {
        ...payload
      }
    };
  },
  [EMPLOYEE_FORM_SAVED]: (state, payload) => {
    return {
      ...state,
      form: {
        ...state.form,
        salutation: payload.salutation,
        firstname: payload.firstname,
        lastname: payload.lastname,
        email: payload.email,
        phone: payload.phone,
        mobile: payload.mobile
      },
      isset: false
    };
  },
  [EMPLOYEE_SET]: (state, payload) => {
    return {
      ...state,
      isset: payload
    };
  },
  [EMPLOYEE_RESET]: (state, payload) => {
    return initialState;
  }
});
