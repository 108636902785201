import React, { Fragment } from "react";
import {
  Audio,
  Csv,
  Doc,
  Exe,
  File,
  Img as Image,
  Json,
  Pdf,
  Ppt,
  Txt,
  Video,
  Xls,
  Xml,
  Yolii,
  Zip
} from "../icons/";

export default function CardMediaFileType(props) {
  const fileExtension = props.file
    .split(".")
    .pop()
    .toUpperCase();
  return (
    <Fragment>
      {props.filetype === "video" && (
        <Video label={fileExtension} width="70%" height="70%" />
      )}
      {props.filetype === "img" && (
        <Image label={fileExtension} width="70%" height="70%" />
      )}
      {props.filetype === "audio" && (
        <Audio label={fileExtension} width="70%" height="70%" />
      )}
      {props.filetype === "csv" && <Csv width="70%" height="70%" />}
      {(props.filetype === "doc" || props.filetype === "docx") && (
        <Doc width="70%" height="70%" />
      )}
      {(props.filetype === "xls" || props.filetype === "xlsx") && (
        <Xls width="70%" height="70%" />
      )}
      {props.filetype === "exe" && <Exe width="70%" height="70%" />}
      {props.filetype === "pdf" && <Pdf width="70%" height="70%" />}
      {props.filetype === "ppt" && <Ppt width="70%" height="70%" />}
      {props.filetype === "txt" && (
        <Txt label={fileExtension} width="70%" height="70%" />
      )}
      {props.filetype === "json" && <Json width="70%" height="70%" />}
      {props.filetype === "xml" && <Xml width="70%" height="70%" />}
      {props.filetype === "yolii" && <Yolii width="70%" height="70%" />}
      {props.filetype === "zip" && <Zip width="70%" height="70%" />}
      {props.filetype === "unknown" && <File width="70%" height="70%" />}
    </Fragment>
  );
}
