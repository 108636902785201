import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { backgroundImageStyle, ltrim } from "../utils";
import Fade from "@material-ui/core/Fade";

const Skeleton = () => <Fragment />;

var AppConfig = require("../config.json");

class BackgroundMood extends Component {
  constructor(props) {
    super(props);
    this.state = { covered: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      ltrim(this.props.pathname, "/") !== AppConfig.pages.login.url &&
      ltrim(this.props.pathname, "/") !== AppConfig.pages.forgot.url &&
      !this.state.covered
    ) {
      this.setState({ covered: true });
    } else if (
      (ltrim(this.props.pathname, "/") === AppConfig.pages.login.url ||
        ltrim(this.props.pathname, "/") === AppConfig.pages.forgot.url) &&
      this.state.covered
    ) {
      this.setState({ covered: false });
    }
  }
  componentDidMount() {
    if (
      ltrim(this.props.pathname, "/") !== AppConfig.pages.login.url &&
      ltrim(this.props.pathname, "/") !== AppConfig.pages.forgot.url &&
      !this.state.covered
    ) {
      this.setState({ covered: true });
    }
  }

  render() {
    var wrapperStyle = {
      position: "fixed",
      minHeight: "100vh",
      width: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };
    var wrappedStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgb(245, 245, 245)",
      opacity: 0.97
    };

    const styles = theme => backgroundImageStyle(this.props.theme.body);
    const BackgroundImage = withStyles(styles)(Skeleton);
    return (
      <Fragment>
        <BackgroundImage />
        <Fade in={this.state.covered} timeout={600}>
          <div style={wrapperStyle}>
            <div style={wrappedStyle} />
          </div>
        </Fade>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    theme: state.theme,
    pathname: state.router.location.pathname
  };
};

export default connect(
  mapStateToProps,
  null
)(BackgroundMood);
