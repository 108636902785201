import { applyMiddleware, compose, createStore } from "redux";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";

import monitorReducersEnhancer from "./enhancers/monitorReducer";
import rootReducer from "./reducers";

var AppConfig = require("../config.json");
const persistConfig = {
  key: AppConfig.namespace,
  storage,
  whitelist:
    process.env.NODE_ENV !== "production"
      ? [
          "theme",
          "configs",
          "customer",
          "shipping",
          "employee",
          "sitemap",
          "usersite",
          "userapp",
          "abosales",
          "aboclients"
        ]
      : [
          "theme",
          "configs",
          "customer",
          "employee",
          "shipping",
          "sitemap",
          "usersite",
          "userapp",
          "abosales",
          "aboclients"
        ]
};

const baseHistory = createHashHistory();

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer(baseHistory)
);

export default function configureStore(preloadedState) {
  var tempStore, composed;
  if (
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    composed = compose(
      applyMiddleware(thunk, routerMiddleware(baseHistory), logger),
      monitorReducersEnhancer,
      window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    if (process.env.NODE_ENV !== "production") {
      composed = compose(
        applyMiddleware(thunk, routerMiddleware(baseHistory), logger),
        monitorReducersEnhancer
      );
    } else {
      composed = compose(applyMiddleware(thunk, routerMiddleware(baseHistory)));
    }
  }
  tempStore = createStore(persistedReducer, preloadedState, composed);

  const persistor = persistStore(tempStore);

  const history = baseHistory;
  const store = tempStore;

  return { store, history, persistor };
}
