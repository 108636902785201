import {
  checkHttpStatus,
  parseJSON,
  cacheBust,
  cacheBustString,
  isJsonString
} from "../utils";
import fetch from "isomorphic-fetch";
import { doRelog, authRequest, authSuccess, authFailure } from "./auth";
import { themeColorChange, themeBgChange, themeReset } from "./theme";
import {
  loginUserSuccess,
  loginUserFailure,
  loginUserRequest,
  updateLoginData,
  controlledLogout
} from "./login";
import {
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordRequest
} from "./forgot";
import { setDownloadLink } from "./downloads";
import { setAboArticles } from "./aboarticles";
import {
  setAboClients,
  setAboClientsHasData,
  increaseAboClientsCountByOne
} from "./aboclients";
import {
  setAboSalesClientAddress,
  setAboSalesDealProgress,
  setAboSalesDealClient,
  setAboSalesDealLastChanged
} from "./abosales";
import { setAboPrintLink } from "./aboprint";
import { setDealsRefresh } from "./abodeals";
import { passwordChanged, passwordReset } from "./password";
import { setCustomerForm, setCustomerFormAfterSave } from "./customer";
import { setEmployeeForm, setEmployeeFormAfterSave } from "./employee";
import { setShippingForm, setShippingFormAfterSave } from "./shipping";
import { setCustomerContacts, setCustomerContactsLoaded } from "./contacts";
import { setDeals, setDealsUserId } from "./abodeals";
import { setAdditionalConfigs } from "./configs";
import { setSitemap } from "./sitemap";
import {
  setUsersiteFavorites,
  setUsersiteLayout,
  setUsersiteHasContent,
  setUsersiteReload
} from "./usersite";
import {
  showMessage,
  showMessageSuccess,
  showMessageError,
  showMessageInfo,
  showMessageWarning,
  showMessageByType
} from "./message";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { contentWillChange } from "./content";
import { setSaveAction } from "./saving";
import { stringify } from "query-string";
import LocalStorageUtils from "../utils/LocalStorageUtils";
import { NOT_FOUND } from "../store/constants";

var AppConfig = require("../config.json");

function errorOrAuthFailed(dispatch, error, status) {
  if (error === undefined || status === undefined) {
    return;
  }
  dispatch(hideLoading());
  dispatch(setSaveAction(false));
  if (status !== 401) {
    if (error !== "") {
      dispatch(showMessageError(error));
    }
  } else {
    dispatch(
      showMessageWarning(
        "Sitzung abgelaufen - Bitte loggen sie sich erneut ein"
      )
    );
    dispatch(controlledLogout());
  }
}

export function checkUserAuth() {
  return dispatch => {
    dispatch(showLoading());
    dispatch(authRequest());
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/authorized"
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        var serverSitemapChange = parseInt(response.data.sitemapChanged, 10);
        var appSitemapFrom = parseInt(
          LocalStorageUtils.get("sitemapChanged"),
          10
        );
        if (
          serverSitemapChange - appSitemapFrom <
          AppConfig.sitemapRefreshOnlyAfter
        ) {
          response.data.sitemapChanged = appSitemapFrom;
        }
        dispatch(authSuccess(response.data));
        dispatch(hideLoading());
      })
      .catch(err => {
        const { error } = err;
        dispatch(hideLoading());
        LocalStorageUtils.notAuthorized();
        dispatch(authFailure(error));
        dispatch(
          showMessageWarning(
            "Sitzung abgelaufen - Bitte loggen sie sich erneut ein"
          )
        );
        dispatch(passwordReset());
        dispatch(doRelog());
        dispatch(contentWillChange("login"));
      });
  };
}

export function loginUser(username, password) {
  return function(dispatch) {
    dispatch(showLoading());
    dispatch(loginUserRequest());
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/login"
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Authorization: "Basic " + btoa(username + ":" + password)
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        var themeCol = false,
          themeBg = false;
        if (
          response.data.themeColorsCustom &&
          response.data.themeColors !== "" &&
          isJsonString(response.data.themeColors)
        ) {
          var col = JSON.parse(response.data.themeColors);
          if (col.hasOwnProperty("palette")) {
            themeCol = true;
            dispatch(themeColorChange(col.palette));
          }
        }
        if (
          response.data.themeBgImageCustom &&
          response.data.themeBgImage !== ""
        ) {
          themeBg = true;
          dispatch(
            themeBgChange({
              fullscreen: response.data.themeBgImageFullscreen,
              backgroundimage: response.data.themeBgImage
            })
          );
        }
        if (themeCol === false && themeBg === false) {
          dispatch(themeReset());
        }
        dispatch(setAdditionalConfigs(response.data.additionalConfigs));

        dispatch(setSitemap(response.data.sitemap));
        dispatch(setUsersiteFavorites(response.data.sitemapSpecs.favorites));
        dispatch(setUsersiteLayout(response.data.sitemapSpecs.layout));
        dispatch(setUsersiteHasContent(response.data.sitemapSpecs.hasContent));
        dispatch(setEmployeeForm(response.data.employee));

        if (response.data.isSalesperson) {
          dispatch(setDealsUserId(response.data.id));
        }
        dispatch(setCustomerForm(response.data.customer));
        dispatch(setShippingForm(response.data.shipping));

        dispatch(loginUserSuccess(response.data));
      })
      .catch(err => {
        const { error } = err;
        dispatch(hideLoading());
        dispatch(loginUserFailure(error));
        dispatch(showMessageError(error));
      });
  };
}

export function resetUserPassword(username) {
  return function(dispatch) {
    dispatch(showLoading());
    dispatch(forgotPasswordRequest());
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/forgot"
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify({ username: username })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(forgotPasswordSuccess(response.data));
      })
      .catch(err => {
        const { error } = err;
        dispatch(hideLoading());
        dispatch(forgotPasswordFailure());
        dispatch(showMessageError(error));
      });
  };
}

export function resetPassword(form, id) {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "changepassword"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.employeesUrl,
        "/".concat(id),
        queryStr
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        if (response.data.status === "success") {
          dispatch(
            showMessageSuccess(
              "Passwort geändert - bitte loggen sie sich erneut ein"
            )
          );
          LocalStorageUtils.notAuthorized();
          dispatch(passwordChanged());
        } else {
          dispatch(showMessageWarning(response.data.message));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function getAboPrintLink(id) {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "aboprint"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodeals/",
        id,
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(setAboPrintLink(response.data.pdflink));
        dispatch(hideLoading());
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function sendAboEmail(id, rt) {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "abosendemail",
      rt: rt
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodeals/",
        id,
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        if (response.data.status === "success") {
          dispatch(
            showMessageSuccess(
              "Das PDF wurde an ihre Benutzer-Email-Adresse versendet."
            )
          );
        } else {
          dispatch(
            showMessageSuccess("Das PDF konnte nicht versendet werden.")
          );
        }
        dispatch(hideLoading());
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function reloadSitemap(id) {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "reloadsitemap"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.employeesUrl,
        "/".concat(id),
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        if (response.data.hasContent) {
          dispatch(showMessageInfo("Inhalte neu geladen"));
          dispatch(setSitemap(response.data.sitemap));
          dispatch(setUsersiteHasContent(response.data.hasContent));
          dispatch(setUsersiteReload(false));
        } else {
          dispatch(showMessageError("Es konnten keine Inhalte geladen werden"));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function reloadSitemapSilent(id) {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "reloadsitemap"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.employeesUrl,
        "/".concat(id),
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        if (response.data.hasContent) {
          dispatch(setSitemap(response.data.sitemap));
          dispatch(setUsersiteHasContent(response.data.hasContent));
        } else {
          dispatch(showMessageError("Inhalte konnten nicht geladen werden."));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function saveLogindata(form, id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    const query = {
      cachebust: cacheBustString(),
      command: "savelogindata"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.employeesUrl,
        "/".concat(id),
        queryStr
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        if (response.data.status === "success") {
          dispatch(updateLoginData(response.data));
          dispatch(
            showMessageSuccess(
              "Logindaten geändert - bitte beim nächsten Login daran denken."
            )
          );
          LocalStorageUtils.set("username", response.data.username);
        } else {
          dispatch(showMessageWarning(response.data.message));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function saveContentFavorites(form, id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    const query = {
      cachebust: cacheBustString(),
      command: "savefavorites"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.employeesUrl,
        "/".concat(id),
        queryStr
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        if (response.data.status === "success") {
          dispatch(showMessageInfo("Favoriten aktualisiert und gespeichert"));
        } else {
          dispatch(showMessageWarning(response.data.message));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function saveContentLayout(form, id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    const query = {
      cachebust: cacheBustString(),
      command: "savelayout"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.employeesUrl,
        "/".concat(id),
        queryStr
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        if (response.data.status === "success") {
          dispatch(showMessageInfo("Ansicht aktualisiert und gespeichert"));
        } else {
          dispatch(showMessageWarning(response.data.message));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}
export function saveCustomer(form, id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.customersUrl,
        "/".concat(id),
        cacheBust()
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        if (response.data.hasOwnProperty("id") && response.data.id > 0) {
          dispatch(setCustomerFormAfterSave(response.data));
          dispatch(showMessageSuccess("Kundendaten erfolgreich gespeichert."));
        } else {
          dispatch(
            showMessageError("Kundendaten: Ein Fehler ist aufgetreten.")
          );
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}
export function saveEmployee(form, id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.employeesUrl,
        "/".concat(id),
        cacheBust()
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        if (response.data.hasOwnProperty("id") && response.data.id > 0) {
          dispatch(setEmployeeFormAfterSave(response.data));
          dispatch(showMessageSuccess("Erfolgreich gespeichert."));
        } else {
          dispatch(showMessageError("Ein Fehler ist aufgetreten."));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}
export function saveShipping(form, id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.customersUrl,
        "/".concat(id),
        cacheBust()
      ),
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        if (response.data.hasOwnProperty("id") && response.data.id > 0) {
          dispatch(setShippingFormAfterSave(response.data));
          dispatch(
            showMessageSuccess("Lieferadresse erfolgreich gespeichert.")
          );
        } else {
          dispatch(
            showMessageError("Lieferadresse: Ein Fehler ist aufgetreten.")
          );
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function saveCustomerContact(form, id, isnew) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.customerscontactsUrl,
        !isnew ? "/".concat(id) : "",
        cacheBust()
      ),
      {
        method: !isnew ? "PUT" : "POST",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        dispatch(setCustomerContactsLoaded({ loaded: false }));
        if (form.hasOwnProperty("deleted") && form.deleted) {
          dispatch(showMessageInfo("Kontaktperson erfolgreich gelöscht."));
        } else {
          dispatch(
            showMessageSuccess("Kontaktperson erfolgreich gespeichert.")
          );
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function getCustomerContacts() {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "mycontacts"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.customerscontactsUrl,
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setCustomerContacts(response.data.items));
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export const setNotFound = bool => dispatch => {
  dispatch({
    type: NOT_FOUND,
    payload: bool
  });
};

export function requestDownload(type, id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(showMessageInfo("Ihr Download wird angefordert."));
    const query = {
      cachebust: cacheBustString(),
      type: type,
      id: id
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/downloads",
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());

        if (response.data.status === "success") {
          dispatch(showMessageSuccess("Ihr Download steht bereit."));
          dispatch(setDownloadLink(response.data.link));
        } else {
          dispatch(
            showMessageError(
              "Ein Fehler ist aufgetreten, der Download konnte nicht gestartet werden."
            )
          );
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function saveAboDeal(form, id, isnew, thenPrint) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodeals",
        !isnew ? "/".concat(id) : "",
        cacheBust()
      ),
      {
        method: !isnew ? "PUT" : "POST",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        dispatch(setAboSalesDealLastChanged(response.data.deal.changed));
        if (isnew) {
          dispatch(setAboSalesDealProgress(true, response.data.deal.id));
        }
        dispatch(setDealsRefresh());
        dispatch(showMessageSuccess("Abo-Angebot erfolgreich gespeichert."));
        if (thenPrint) {
          dispatch(getAboPrintLink(isnew ? response.data.deal.id : id));
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function deleteAboDeal(id) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodeals",
        "/".concat(id),
        cacheBust()
      ),
      {
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        dispatch(setDealsRefresh());
        dispatch(showMessageSuccess("Abo-Angebot erfolgreich gelöscht."));
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function getAboArticles() {
  return dispatch => {
    dispatch(showLoading());
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/aboarticles",
        cacheBust()
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setAboArticles(response.data));
        if (response.data.status !== "success") {
          dispatch(
            showMessageError(
              "Ein Fehler ist aufgetreten, die Artikel konnten nicht geladen werden."
            )
          );
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function getAboDealClient(clientId) {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "getclientaddress",
      clientId: clientId
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodealsaddresses",
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setAboSalesClientAddress(response.data.clientAddress));
        if (response.data.status !== "success") {
          dispatch(
            showMessageError(
              "Ein Fehler ist aufgetreten, der Kunde konnte nicht geladen werden."
            )
          );
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function saveCurrentAboClient(form, id, isnew) {
  return dispatch => {
    dispatch(showLoading());
    dispatch(setSaveAction(true));
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodealsaddresses",
        !isnew ? "/".concat(id) : "",
        cacheBust()
      ),
      {
        method: !isnew ? "PUT" : "POST",
        headers: new Headers({
          Accept: "application/json",
          Realm: btoa(AppConfig.frontendKey),
          "Content-Type": "application/json",
          Token: LocalStorageUtils.token()
        }),
        body: JSON.stringify(form)
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setSaveAction(false));
        dispatch(setAboSalesClientAddress(response.data.clientAddress));
        if (response.data.status !== "success") {
          dispatch(
            showMessageError(
              "Ein Fehler ist aufgetreten, der Kunde konnte nicht gespeichert werden."
            )
          );
        } else {
          dispatch(showMessageSuccess("Abo-Kunde erfolgreich gespeichert."));
        }
        if (isnew) {
          dispatch(setAboSalesDealClient(response.data.id));
          dispatch(increaseAboClientsCountByOne());
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function getAboClients(command, form) {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: command,
      ...form
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodealsaddresses",
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setAboClients(response.data));
        if (response.data.status !== "success") {
          dispatch(
            showMessageError(
              "Ein Fehler ist aufgetreten, ihr Addressbuch konnte nicht geladen werden."
            )
          );
        }
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}
export function getAboClientsCount() {
  return dispatch => {
    dispatch(showLoading());
    const query = {
      cachebust: cacheBustString(),
      command: "checkclientaddresses"
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodealsaddresses",
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(hideLoading());
        dispatch(setAboClientsHasData(response.data.count));
      })
      .catch(err => {
        const { error, status } = err;
        errorOrAuthFailed(dispatch, error, status);
      });
  };
}

export function getDeals(params) {
  return dispatch => {
    dispatch(showLoading());
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      cachebust: cacheBustString(),
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter)
    };
    const queryStr = `?${stringify(query)}`;
    return fetch(
      AppConfig.api[process.env.NODE_ENV].concat(
        AppConfig.serviceUrl,
        "/abodeals",
        queryStr
      ),
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Realm: btoa(AppConfig.frontendKey),
          Token: LocalStorageUtils.token()
        })
      }
    )
      .then(response =>
        response.text().then(text => ({
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text
        }))
      )
      .then(response => {
        let json;
        try {
          json = JSON.parse(response.body);
        } catch (e) {
          // not json, no big deal
        }
        var maxPage = page * perPage;
        var isTotal = parseInt(
          response.headers
            .get("x-total-count")
            .split("/")
            .pop(),
          10
        );
        dispatch(
          setDeals({
            datasets: json.data.items,
            addresses: json.data.addresses,
            total: isTotal
          })
        );

        if (isTotal === 0) {
          dispatch(showMessageInfo(`Keine Abo-Vorgänge vorhanden`));
        } else {
          dispatch(
            showMessage(
              `Ihre Abo-Vorgänge ${(page - 1) * perPage + 1} bis ${
                maxPage > isTotal ? isTotal : maxPage
              } von ${isTotal} wurden (neu) geladen`
            )
          );
        }

        dispatch(hideLoading());
      })
      .catch(err => {
        const { status } = err;
        errorOrAuthFailed(
          dispatch,
          "Die bisherigen Abo-Angebote konnten nicht geladen werden",
          status
        );
      });
  };
}
