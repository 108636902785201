import React from "react";

const SvgXml = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#f29c1f"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"XML"}
    </text>
    <path
      d="M15.5 24a.999.999 0 01-.707-1.707l6-6a.999.999 0 111.414 1.414l-6 6A.997.997 0 0115.5 24z"
      fill="#f29c1f"
    />
    <path
      d="M21.5 30a.997.997 0 01-.707-.293l-6-6a.999.999 0 111.414-1.414l6 6A.999.999 0 0121.5 30zm12 0a.999.999 0 01-.707-1.707l6-6a.999.999 0 111.414 1.414l-6 6A.997.997 0 0133.5 30z"
      fill="#f29c1f"
    />
    <path
      d="M39.5 24a.997.997 0 01-.707-.293l-6-6a.999.999 0 111.414-1.414l6 6A.999.999 0 0139.5 24zm-15 8a1 1 0 01-.943-1.334l6-17a1 1 0 111.886.666l-6 17A1 1 0 0124.5 32z"
      fill="#f29c1f"
    />
  </svg>
);

export default SvgXml;
