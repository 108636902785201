import { createReducer } from "../../utils";
import { THEME_COLOR_CHANGE, THEME_BG_CHANGE, THEME_RESET } from "../constants";
var AppConfig = require("../../config.json");
const bgImgDefault = AppConfig.api[process.env.NODE_ENV].concat(
  "/assets/uploads/yolii-bg-mood.jpg"
);

const initialState = {
  mui: {
    spacing: 4,
    typography: {
      useNextVariants: true
    }
  },
  palette: {
    background: {
      default: "#fbfbfb"
    },
    primary: {
      main: "#13181a",
      light: "#393e40",
      dark: "#000000",
      contrastText: "#fff"
    },
    secondary: {
      main: "#80bfff",
      light: "#b5f1ff",
      dark: "#4a8fcc",
      contrastText: "#13181a"
    },
    custom: {
      main: "#ff8008",
      light: "#ffb146",
      dark: "#c55100",
      contrastText: "#13181a"
    },
    error: {
      main: "#d32f2f",
      light: "#ff6659",
      dark: "#9a0007",
      contrastText: "#fff"
    }
  },
  body: {
    backgroundimage: bgImgDefault,
    fullscreen: true
  }
};

export default createReducer(initialState, {
  [THEME_COLOR_CHANGE]: (state, payload) => {
    return Object.assign({}, state, {
      palette: payload
    });
  },
  [THEME_BG_CHANGE]: (state, payload) => {
    return Object.assign({}, state, {
      body: payload
    });
  },
  [THEME_RESET]: (state, payload) => {
    return Object.assign({}, state, {
      palette: initialState.palette,
      body: initialState.body
    });
  }
});
