import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Subcategory from "../components/categories/Subcategory";
import { setUsersiteCurrent } from "../actions/usersite";
import { ltrim } from "../utils";

class CategorySubView extends Component {
  constructor(props) {
    super(props);
    this.evalCurrentPage = this.evalCurrentPage.bind(this);
    this.state = {
      pagetitle: ""
    };
  }
  componentDidMount() {
    this.props.dispatch(setUsersiteCurrent(this.evalCurrentPage()));
  }

  evalCurrentPage() {
    if (this.props.sitemap.pages.length < 1) {
      return { main: 0, sub: 0, group: 0 };
    }
    const paths = ltrim(this.props.pathname, "/").split("/");
    const mainPage = this.props.sitemap.pages.filter(item => {
      return item.smarturl === paths[0];
    })[0];
    const subPage = mainPage.subcategories.filter(subitem => {
      return subitem.smarturl === paths[1];
    })[0];
    this.setState({
      pagetitle: mainPage.menulabel.concat(" · ", subPage.menulabel)
    });
    return { main: mainPage.id, sub: subPage.id, group: 0 };
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>
            {`Yolii `.concat(
              this.state.pagetitle !== "" ? this.state.pagetitle : ""
            )}
          </title>
        </Helmet>
        {this.props.sitemap.pages.length &&
          this.props.usersite.main !== 0 &&
          this.props.usersite.sub !== 0 && <Subcategory {...this.props} />}
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};
export default connect(
  mapStateToProps,
  null
)(CategorySubView);
