import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  width: ${props => props.width};
  cursor: pointer;
  & > svg {
    width: 100%;
    height: auto;
    & > path,
    & > g path {
      fill: ${props => props.color};
    }
  }
  &:hover {
    & > svg {
      & > path,
      & > g path {
        fill: ${props => props.hover};
      }
    }
  }
`;

export default function YoliiIcon(props) {
  return (
    <Container {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468 103.9">
        <path d="M46.4 73.3c0-19.9-39.9-39.9-39.9-39.9v45.4l40.2 22s-.3-7.7-.3-27.5zM10.8 26.9L49.2 2.7l39.5 23.6S62 45.4 48.7 45.2c-13.4-.3-37.9-18.3-37.9-18.3zM54.6 73.7c0 19.9-.3 27.5-.3 27.5l40.2-22V33.8s-39.9 20-39.9 39.9z" />
      </svg>
    </Container>
  );
}
