import { createReducer } from "../../utils";
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_RESET
} from "../constants";

const initialState = {
  username: null,
  isResetted: false,
  isResetting: false
};

export default createReducer(initialState, {
  [FORGOT_PASSWORD_REQUEST]: (state, payload) => {
    return Object.assign({}, state, {
      isResetting: true
    });
  },
  [FORGOT_PASSWORD_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      isResetting: false,
      isResetted: true,
      username: payload.username
    });
  },
  [FORGOT_PASSWORD_RESET]: (state, payload) => {
    return Object.assign({}, state, {
      username: null,
      isResetted: false,
      isResetting: false
    });
  },
  [FORGOT_PASSWORD_FAILURE]: (state, payload) => {
    return Object.assign({}, state, {
      username: null,
      isResetted: false,
      isResetting: false
    });
  }
});
