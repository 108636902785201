import React, { Fragment } from "react";
import { resetUserPassword } from "../actions";
import { connect } from "react-redux";
import { contentWillChange } from "../actions/content";
import { Helmet } from "react-helmet";
import ForgotPasswordForm from "../components/ForgotPasswordForm";

import ReactTimeout from "react-timeout";

var AppConfig = require("../config.json");

class PasswordResetView extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.gotoLogin = this.gotoLogin.bind(this);
    this.state = { reset: false, username: "", login: false };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit(e) {
    e.preventDefault();
    if (
      !this.props.isResetting &&
      !this.props.isResetted &&
      this.state.username !== ""
    ) {
      this.setState({ reset: true });
      this.props.submitClick(this.state.username);
    }
    return false;
  }

  gotoLogin() {
    this.props.dispatch(contentWillChange(AppConfig.pages.login.url));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isResetted && !prevState.login) {
      this.setState({ login: true }, function() {
        this.props.setTimeout(this.gotoLogin, 10000);
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>YOLii Portal Passwort zurücksetzen</title>
        </Helmet>

        <ForgotPasswordForm
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          gotoLogin={this.gotoLogin}
          showResetOk={this.props.isResetted}
          buttonlabel={
            this.props.isResetting === true
              ? "Prüfe Daten"
              : this.props.isResetted === true
              ? "OK"
              : this.state.username !== ""
              ? "Zurücksetzen anfordern »"
              : "Username angeben"
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isResetting: state.forgot.isResetting,
    isResetted: state.forgot.isResetted
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
    submitClick: username => {
      resetUserPassword(username)(dispatch);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactTimeout(PasswordResetView));
