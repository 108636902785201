import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Save } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  dialogcontent: {
    padding: theme.spacing(4),
    "& .MuiPaper-elevation2": {
      boxShadow: "none"
    }
  },
  button: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  name: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  qty: {
    padding: theme.spacing(2, 5)
  },
  btnlabel: {
    width: "40px",
    textAlign: "right"
  }
}));

export default function AboSalesDealSave(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    if (props.saveDisabled) {
      setOpen(true);
    } else {
      props.clickSave(false);
    }
  }
  function handleClose() {
    setOpen(false);
  }

  return (
    <Box p={0}>
      <IconButton
        aria-label="Speichern"
        onClick={handleClickOpen}
        color={props.shouldBeSaved ? "secondary" : "inherit"}
      >
        <Save />
      </IconButton>

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.dialogcontent}>
          <Typography variant="body1" className={classes.name}>
            Speichern eines Angebotes ist erst möglich, wenn ein Titel und ein
            Kunde festgelegt wurde.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
