import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Save from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  rightIconShipping: {
    marginLeft: theme.spacing(3),
    color: "grey"
  },
  paper: {
    height: "100%",
    padding: theme.spacing(2, 3)
  },
  proceed: {
    height: "100%",
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  shippingbtn: {
    color: "grey"
  },
  proceedbtn: {
    marginTop: "auto",
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  }
}));

export default function EmployeeSaveAndProceed(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container className={classes.root} spacing={5}>
        <Hidden only="xs">
          <Grid item xs={12} sm={5} md={4}>
            <Box className={classes.proceed} />
          </Grid>
        </Hidden>

        <Hidden only="xs">
          <Grid item xs={12} sm={1} md={4} />
        </Hidden>

        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.proceed}>
            <Button
              disabled={!props.saveActive}
              variant="contained"
              color="primary"
              fullWidth
              className={classes.proceedbtn}
              onClick={props.saveEmployee}
            >
              Speichern
              <Save className={classes.rightIcon} />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
