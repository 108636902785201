import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import NoteAdd from "@material-ui/icons/NoteAdd";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AboSalesDealAddArticleTable from "./AboSalesDealAddArticleTable";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  dialog: {
    "& .MuiDialogContent-root:first-child": {
      padding: theme.spacing(0)
    }
  },
  dialogcontent: {
    padding: theme.spacing(0),
    "& .MuiPaper-elevation2": {
      boxShadow: "none"
    }
  },
  buttonWrapper: {
    position: "absolute",
    right: theme.spacing(3),
    top: 10,
    zIndex: 1,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      right: 0,
      top: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  button: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
}));

export default function AboSalesDealAddArticle(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function addArticle(item) {
    props.addArticle(item);
  }

  return (
    <Box className={classes.buttonWrapper}>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        fullWidth={fullScreen}
        className={classes.button}
        onClick={handleClickOpen}
      >
        Artikel hinzufügen
        <NoteAdd className={classes.rightIcon} />
      </Button>

      <Dialog
        open={open}
        className={classes.dialog}
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.dialogcontent}>
          <AboSalesDealAddArticleTable
            addArticle={addArticle}
            articles={props.articles}
            categories={props.categories}
            basket={props.basket}
            title="YOLii Artikelstamm"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schliessen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
