import React, { Fragment } from "react";
import { connect } from "react-redux";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Star, StarBorder } from "@material-ui/icons";

import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";

import YoliiLogo from "../misc/YoliiLogo";
import YoliiIcon from "../misc/YoliiIcon";
import { toggleDealsCommission } from "../../actions/abodeals";
import { CalculatorIcon } from "../icons";
import AboSalesDealSave from "../abo/AboSalesDealSave";
import AboSalesDealClose from "../abo/AboSalesDealClose";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1)
    }
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(4)
  },
  applabel: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  appBar: {
    background: theme.palette.primary.dark
  },
  calculator: {
    paddingRight: theme.spacing(6)
  }
}));

function MenuEditDealAppBar(props) {
  const classes = useStyles();
  const theme = useTheme();

  const Logo = () => {
    if (isWidthUp("sm", props.width)) {
      return (
        <YoliiLogo
          color="white"
          hover={theme.palette.secondary.main}
          width="120px"
        />
      );
    }
    return (
      <div style={{ width: 120 }}>
        <YoliiIcon color="white" hover={theme.palette.secondary.main} />
      </div>
    );
  };

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <span className={classes.calculator}>
            <CalculatorIcon />
          </span>

          <Logo />
          <Typography variant="subtitle1" className={classes.title}>
            <span className={classes.applabel}>YOLii Abo-Angebot</span>
          </Typography>
          {props.commissionView && (
            <IconButton
              aria-label="Provision"
              onClick={() =>
                props.dispatch(toggleDealsCommission(!props.commissionActive))
              }
              color={props.commissionActive ? "secondary" : "inherit"}
            >
              {props.commissionActive ? <Star /> : <StarBorder />}
            </IconButton>
          )}
          <AboSalesDealSave
            clickSave={props.clickSave}
            saveDisabled={props.saveDisabled}
            shouldBeSaved={props.shouldBeSaved}
          />
          <AboSalesDealClose
            clickSave={props.clickSave}
            clickClose={props.clickClose}
            shouldBeSaved={props.shouldBeSaved}
          />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    commissionActive: state.abodeals.commissionActive,
    commissionView: state.auth.commissionView,
    shouldBeSaved: state.abosales.shouldBeSaved
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default withWidth()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuEditDealAppBar)
);
