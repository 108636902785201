import { SITEMAP_SET, SITEMAP_RESET } from "../store/constants";

export const setSitemap = data => dispatch => {
  dispatch({
    type: SITEMAP_SET,
    payload: data
  });
};

export const resetSitemap = () => dispatch => {
  dispatch({
    type: SITEMAP_RESET,
    payload: {}
  });
};
