import React, { Component, Fragment } from "react";
import {
  createMuiTheme,
  withStyles,
  responsiveFontSizes
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import { SnackbarProvider } from "notistack";
import AppMessage from "../utils/AppMessage";
import LoadingBar from "react-redux-loading-bar";
import SectionIncomplete from "../utils/SectionIncomplete";
import MenuAppBar from "../components/menu/MenuAppBar";
import ContentFader from "../utils/ContentFader";
import BackgroundMood from "../utils/BackgroundMood";
import AppController from "../utils/AppController";
import PasswordChange from "../utils/PasswordChange";
import SaveActionNoClick from "../utils/SaveActionNoClick";
import DownloadListener from "../utils/DownloadListener";
import SitemapReload from "../utils/SitemapReload";
import ContentDetail from "../components/contents/ContentDetail";
import AboEditDeal from "../components/abo/AboEditDeal";
import AboPrintLink from "../components/abo/AboPrintLink";

class Scaffolding extends Component {
  render() {
    const loadingBarStyle = {
      backgroundColor: this.props.theme.palette.custom.main,
      height: "5px",
      zIndex: 99999
    };
    const typography = {
      fontWeightMedium: 500,
      body1: {
        fontWeight: 400
      },
      strong: {
        fontWeight: 600
      }
    };

    const theme = responsiveFontSizes(
      createMuiTheme(
        Object.assign(
          {
            typography: typography,
            palette: this.props.theme.palette,
            overrides: {
              MuiOutlinedInput: {
                input: {
                  paddingTop: 17,
                  paddingBottom: 17
                }
              }
            }
          },
          this.props.theme.mui
        )
      )
    );
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider preventDuplicate={false}>
          <BackgroundMood />
          <CssBaseline />
          <AppController />
          <DownloadListener />
          <SitemapReload />
          <AppMessage />
          <LoadingBar style={loadingBarStyle} showFastActions />
          <MenuAppBar />
          <PasswordChange />
          <AboEditDeal />
          <AboPrintLink />
          <ContentDetail />
          <SectionIncomplete />
          <SaveActionNoClick />
          <ContentFader>{this.props.children}</ContentFader>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(Scaffolding);
