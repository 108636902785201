import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MaterialTable from "material-table";
import MaterialTableIcons from "../../utils/MaterialTableIcons";
import MaterialTableLocalization from "../../utils/MaterialTableLocalization";

export default function AboSalesDealClientsTable(props) {
  const theme = useTheme();
  const hiddenColumnsXS = useMediaQuery(theme.breakpoints.down("xs"));
  const hiddenColumnsSM = useMediaQuery(theme.breakpoints.down("sm"));
  const cellStyle = {
    paddingTop: "7px",
    paddingBottom: "7px"
  };
  return (
    <Fragment>
      <MaterialTable
        localization={MaterialTableLocalization}
        icons={MaterialTableIcons}
        columns={[
          { title: "ID", field: "id", hidden: true },
          { title: "Firma", field: "company", cellStyle: cellStyle },
          {
            title: "Anrede",
            field: "salutation",
            cellStyle: cellStyle,
            hidden: hiddenColumnsSM
          },
          {
            title: "Vorname",
            field: "firstname",
            cellStyle: cellStyle,
            hidden: hiddenColumnsSM
          },
          {
            title: "Nachname",
            field: "lastname",
            cellStyle: cellStyle,
            hidden: hiddenColumnsXS
          },
          { title: "PLZ", field: "zipcode", cellStyle: cellStyle },
          { title: "Ort", field: "city", cellStyle: cellStyle },
          {
            title: "Email",
            field: "email",
            cellStyle: cellStyle,
            hidden: hiddenColumnsSM
          }
        ]}
        options={{ pageSize: 10 }}
        onRowClick={(event, rowData) => props.selectClient(rowData.id)}
        data={props.data}
        // actions={[
        //   {
        //     icon: tableIcons.SaveAlt,
        //     tooltip: "Auswählen",
        //     onClick: (event, rowData) => alert("You saved " + rowData.id)
        //   }
        // ]}
        title={props.title}
      />
    </Fragment>
  );
}
