import { createReducer } from "../../utils";
import { DOWNLOAD_REQUEST, DOWNLOAD_START, DOWNLOAD_IDLE } from "../constants";

const initialState = {
  link: "",
  requested: false
};

export default createReducer(initialState, {
  [DOWNLOAD_REQUEST]: (state, payload) => {
    return {
      ...state,
      requested: true
    };
  },
  [DOWNLOAD_START]: (state, payload) => {
    return {
      ...state,
      link: payload
    };
  },
  [DOWNLOAD_IDLE]: (state, payload) => {
    return initialState;
  }
});
