import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SelectInputSimple from "../SelectInputSimple";
import { validate } from "../../utils";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(1)
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  textField: {},
  checkbox: {
    marginRight: theme.spacing(12)
  },
  paper: {
    height: "100%",
    padding: theme.spacing(1, 2)
  }
}));

export default function ShippingSpecs(props) {
  const classes = useStyles();
  var fields = {};
  Object.keys(AppConfig.forms.shipping).map(n => {
    return Object.assign(fields, {
      [n]: props.shipping.form[n]
    });
  });
  const [values, setValues] = React.useState(fields);

  var errorFields = {};
  Object.keys(AppConfig.forms.shipping)
    .filter(n => AppConfig.forms.shipping[n].hasOwnProperty("regxp"))
    .map(n => {
      return Object.assign(errorFields, {
        [n]: false
      });
    });
  const [errors, setErrors] = React.useState(errorFields);
  // const [checkbox, setCheckbox] = React.useState(
  //   props.order.shipping.shippingaddress
  // );

  // const handleChangeCheckbox = event => {
  //   setCheckbox(event.target.checked);
  //   props.setShippingAddress(event.target.checked);
  // };
  const handleChange = event => {
    const { name, value } = event.target;
    setChange(name, value);
  };
  const handleCheckbox = name => event => {
    setChange(name, event.target.checked);
  };
  const setChange = (name, value) => {
    setValues({ ...values, [name]: value });

    if (AppConfig.forms.shipping[name].hasOwnProperty("regxp")) {
      if (!validate(value, AppConfig.forms.shipping[name].regxp)) {
        setErrors({ ...errors, [name]: true });
        props.setFormValue(props.formtype, name, "");
        return;
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
    props.setFormValue(props.formtype, name, value);
  };

  return (
    <Fragment>
      <form noValidate autoComplete="off">
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.paper}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.useShippingAddress}
                    onChange={handleCheckbox("useShippingAddress")}
                  />
                }
                label="Abweichende Lieferanschrift verwenden?"
              />
            </Box>
          </Grid>
        </Grid>

        {values.useShippingAddress && (
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <SelectInputSimple
                  id="ship_salutation"
                  name="ship_salutation"
                  disabled={false}
                  fullWidth
                  label={AppConfig.forms.shipping.ship_salutation.label}
                  required={
                    AppConfig.forms.shipping.ship_salutation.required &&
                    values.useShippingAddress
                  }
                  value={values.ship_salutation}
                  onChange={setChange}
                  options={AppConfig.forms.shipping.ship_salutation.options}
                />
              </Box>
            </Grid>
            <Hidden only="xs">
              <Grid item xs={12} sm={6} />
            </Hidden>

            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="ship_firstname"
                  autoComplete="_off"
                  name="ship_firstname"
                  label={AppConfig.forms.shipping.ship_firstname.label}
                  required={
                    AppConfig.forms.shipping.ship_firstname.required &&
                    values.useShippingAddress
                  }
                  fullWidth
                  className={classes.textField}
                  value={values.ship_firstname}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="ship_lastname"
                  autoComplete="_off"
                  name="ship_lastname"
                  label={AppConfig.forms.shipping.ship_lastname.label}
                  required={
                    AppConfig.forms.shipping.ship_lastname.required &&
                    values.useShippingAddress
                  }
                  fullWidth
                  className={classes.textField}
                  value={values.ship_lastname}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="ship_company"
                  autoComplete="_off"
                  name="ship_company"
                  label={AppConfig.forms.shipping.ship_company.label}
                  required={
                    AppConfig.forms.shipping.ship_company.required &&
                    values.useShippingAddress
                  }
                  fullWidth
                  className={classes.textField}
                  value={values.ship_company}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Hidden only="xs">
              <Grid item xs={12} sm={6} />
            </Hidden>

            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="ship_street"
                  autoComplete="_off"
                  name="ship_street"
                  label={AppConfig.forms.shipping.ship_street.label}
                  required={
                    AppConfig.forms.shipping.ship_street.required &&
                    values.useShippingAddress
                  }
                  fullWidth
                  className={classes.textField}
                  value={values.ship_street}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Hidden only="xs">
              <Grid item xs={12} sm={6} />
            </Hidden>

            <Grid item xs={12} sm={4} md={3}>
              <Box className={classes.paper}>
                <TextField
                  id="ship_zipcode"
                  autoComplete="_off"
                  name="ship_zipcode"
                  error={errors.zipcode}
                  label={AppConfig.forms.shipping.ship_zipcode.label}
                  required={
                    AppConfig.forms.shipping.ship_zipcode.required &&
                    values.useShippingAddress
                  }
                  fullWidth
                  className={classes.textField}
                  value={values.ship_zipcode}
                  helperText={
                    errors.ship_zipcode
                      ? "PLZ unvollständig"
                      : values.ship_zipcode.length > 0
                      ? "PLZ gültig"
                      : "5-stellige PLZ angeben"
                  }
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Box className={classes.paper}>
                <TextField
                  id="ship_city"
                  autoComplete="_off"
                  name="ship_city"
                  label={AppConfig.forms.shipping.ship_city.label}
                  required={
                    AppConfig.forms.shipping.ship_city.required &&
                    values.useShippingAddress
                  }
                  fullWidth
                  className={classes.textField}
                  value={values.ship_city}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </form>
    </Fragment>
  );
}
