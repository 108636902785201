import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { contentWillChange } from "../actions/content";
import { getCustomerContacts, saveCustomerContact } from "../actions/";

import CustomerContacts from "../components/customer/CustomerContacts";

var AppConfig = require("../config.json");

class CustomerContactsView extends Component {
  constructor(props) {
    super(props);
    this.saveCustomerContact = this.saveCustomerContact.bind(this);
    this.goHome = this.goHome.bind(this);
  }

  componentDidMount() {
    if (!this.props.contacts.loaded) {
      this.props.dispatch(getCustomerContacts());
    }

    this.setState({ mount: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contacts.loaded && !this.props.contacts.loaded) {
      this.props.dispatch(getCustomerContacts());
    }
  }

  saveCustomerContact(form, id, isnew) {
    this.props.dispatch(saveCustomerContact(form, id, isnew));
  }
  goHome() {
    this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{AppConfig.pages.contactpersons.label}</title>
        </Helmet>
        <CustomerContacts
          {...this.props}
          saveCustomerContact={this.saveCustomerContact}
          goHome={this.goHome}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.contacts,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContactsView);
