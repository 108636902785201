import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { animateScroll as scroll } from "react-scroll";

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    scroll.scrollToTop({
      duration: 150,
      smooth: true
    });
  }, [pathname]);

  return children;
};

export default withRouter(ScrollToTop);
