import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { loadingBarReducer } from "react-redux-loading-bar";

import auth from "./auth";
import theme from "./theme";
import notification from "./notification";
import section from "./section";
import notfound from "./notfound";
import content from "./content";
import upload from "./upload";
import configs from "./configs";
import menu from "./menu";
import customer from "./customer";
import shipping from "./shipping";
import contacts from "./contacts";
import employee from "./employee";
import password from "./password";
import sitemap from "./sitemap";
import usersite from "./usersite";
import saving from "./saving";
import forgot from "./forgot";
import downloads from "./downloads";
import abodeals from "./abodeals";
import abosales from "./abosales";
import aboclients from "./aboclients";
import aboarticles from "./aboarticles";
import aboprint from "./aboprint";
import userapp from "./userapp";

export default history =>
  combineReducers({
    auth,
    theme,
    notification,
    section,
    notfound,
    content,
    upload,
    configs,
    menu,
    customer,
    employee,
    shipping,
    contacts,
    password,
    sitemap,
    usersite,
    saving,
    forgot,
    downloads,
    abodeals,
    abosales,
    aboclients,
    aboarticles,
    aboprint,
    userapp,
    loadingBar: loadingBarReducer,
    router: connectRouter(history)
  });
