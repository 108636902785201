import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import ReactPlayer from "react-player";
import { resetUsersiteDetail } from "../../actions/usersite";
import clsx from "clsx";
import CardMediaFileType from "./CardMediaFileType";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import OpenInNew from "@material-ui/icons/OpenInNew";
import { startDownloadRequest } from "../../actions/downloads";
import { requestDownload } from "../../actions";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialogTitle-root": {
      paddingTop: theme.spacing(2),
      paddingBottom: 0
    }
  },
  heading: {
    color: theme.palette.primary.dark
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  mediavideo: {
    position: "relative",
    background: "#f7f7f7",
    height: 0,
    "& video": {
      outline: "none"
    },
    "& .reactplayer": {
      position: "absolute",
      left: "3%",
      top: "3%"
    }
  },
  media: {
    height: 0
  },
  mediageneric: {
    position: "relative",
    background: "#f7f7f7",
    paddingTop: "33.33%",
    "& svg": {
      position: "absolute",
      left: "15%",
      top: "15%"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  leftIcon: {
    marginRight: theme.spacing(3),
    position: "relative !important",
    top: "0px !important",
    left: "0px !important"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  pdf: {
    margin: theme.spacing(3, 0, 2)
  },
  buttonpdf: {
    width: "300px",
    position: "absolute",
    right: 0,
    bottom: "5px"
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));
const getPaddingTop = values => {
  var returnValue = "56.25%",
    ratio = 0;
  if (values === undefined || !values.hasOwnProperty("mediaSize")) {
    return returnValue;
  }
  if (
    values.mediaSize.hasOwnProperty("w") &&
    values.mediaSize.hasOwnProperty("h")
  ) {
    ratio = (values.mediaSize.h / values.mediaSize.w) * 100;
  }
  if (ratio > 0) {
    returnValue = (Math.round(ratio * 100) / 100).toString() + "%";
  }
  return returnValue;
};

const getElement = props => {
  var elements = [],
    category = "";
  props.sitemap.pages.map(cat =>
    cat.subcategories.map(sub =>
      sub.categorygroups
        .filter(gr => gr.contentelements.length > 0)
        .map(gr =>
          gr.contentelements
            .filter(item => props.usersite.detail === item.id)
            .map(elem => {
              elements.push(elem);
              category = sub.smarturl;
              return elem;
            })
        )
    )
  );
  return { element: elements[0], category: category };
};
function ContentDetail(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [values, setValues] = React.useState({});
  const [ratio, setRatio] = React.useState({});
  const [subcatUrl, setSubcatUrl] = React.useState({});

  const handleClose = () => {
    props.dispatch(resetUsersiteDetail());
  };

  const handleClickDownload = id => {
    props.dispatch(startDownloadRequest());
    props.dispatch(requestDownload("element", id));
  };

  React.useEffect(() => {
    if (
      props.usersite.hasContent &&
      props.sitemap.pages.length &&
      props.usersite.detailView
    ) {
      const { element, category } = getElement(props);
      setValues(element);
      setRatio(getPaddingTop(element));
      setSubcatUrl(category);
    }
  }, [props]);

  return (
    <Fragment>
      {props.usersite.hasContent &&
        props.sitemap.pages.length > 0 &&
        props.usersite.detailView && (
          <Dialog
            fullScreen={fullScreen}
            open={props.usersite.detailView}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classes.root}
            maxWidth="md"
            fullWidth={true}
          >
            <DialogTitle disableTypography className={classes.dialogTitle}>
              <Typography variant="h6" className={classes.heading}>
                {values.headline}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {values.file !== "" && values.filetype === "video" ? (
                <Box
                  className={classes.mediavideo}
                  style={{ paddingTop: ratio }}
                >
                  <ReactPlayer
                    url={values.file}
                    playing
                    controls
                    width="94%"
                    height="94%"
                    className="reactplayer"
                  />
                </Box>
              ) : values.file !== "" &&
                values.filetype === "img" &&
                (values.file.indexOf(".jpg") !== -1 ||
                  values.file.indexOf(".jpeg") !== -1 ||
                  values.file.indexOf(".png") !== -1 ||
                  values.file.indexOf(".gif") !== -1) ? (
                <CardMedia
                  className={classes.media}
                  style={{ paddingTop: ratio }}
                  image={values.file}
                  title={values.headline}
                />
              ) : (
                <Box className={clsx(classes.media, classes.mediageneric)}>
                  <CardMediaFileType {...values} />
                  {/* {values.filetype === "pdf" && (
                    <Fragment>
                      <Button
                        variant="outlined"
                        color="default"
                        className={classes.buttonpdf}
                        onClick={() => window.open(values.file)}
                      >
                        <OpenInNew className={classes.leftIcon} />
                        In neuem Fenster öffnen
                      </Button>
                    </Fragment>
                  )} */}
                </Box>
              )}

              <div className={classes.paper}>
                <Typography paragraph className={classes.bodytext}>
                  {values.copytext}
                </Typography>
                <Divider />
              </div>
            </DialogContent>
            <DialogActions>
              {values.filetype === "pdf" && (
                <Button
                  onClick={() => window.open(values.file)}
                  color="primary"
                >
                  PDF öffnen
                </Button>
              )}
              {subcatUrl === "downloads" && (
                <Button
                  onClick={() => handleClickDownload(props.usersite.detail)}
                  color="primary"
                >
                  Datei jetzt downloaden
                </Button>
              )}
              <Button onClick={handleClose} color="primary" autoFocus>
                Schliessen
              </Button>
            </DialogActions>
          </Dialog>
        )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentDetail);
