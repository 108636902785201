import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FolderIcon from "@material-ui/icons/Folder";
import CardMediaFileType from "../contents/CardMediaFileType";
import Link from "@material-ui/core/Link";
import MenuToolTip from "../menu/MenuToolTip";
import { startDownloadRequest } from "../../actions/downloads";
import { requestDownload } from "../../actions";

import { CloudDownload as DownloadIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    borderRadius: 0,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& > div": {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
      justifyContent: "space-between",
      "& > div": {
        width: "50%"
      },
      "& > div:first-child": {
        width: "48%"
      }
    },
    [theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
      justifyContent: "left",
      "& > div": {
        width: "72%"
      },
      "& > div:first-child": {
        width: "27%"
      }
    }
  },
  cardheadline: {
    marginBottom: 0
  },
  cardcontent: {
    paddingBottom: 0
  },
  media: {
    height: 0,
    paddingTop: "40%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15%"
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "25%"
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  mediageneric: {
    position: "relative",
    background: "#f7f7f7",
    "& svg": {
      position: "absolute",
      left: "15%",
      top: "15%"
    },
    [theme.breakpoints.down("xs")]: {
      background: "#fff"
    }
  }
}));

function YoliiSoftwareDownload(props) {
  const classes = useStyles(props);

  const handleClickDownload = () => {
    props.dispatch(startDownloadRequest());
    props.dispatch(requestDownload("yolii", 1));
  };

  // const cardmedia = <CardMediaFileType {...props} />;

  return (
    <Card className={classes.card} elevation={0}>
      <Box className={clsx(classes.media, classes.mediageneric)}>
        <CardMediaFileType filetype="yolii" file="f.yolii" />
      </Box>
      <Box>
        <CardContent className={classes.cardcontent}>
          <Typography
            gutterBottom
            variant="h6"
            component="h5"
            className={classes.cardheadline}
          >
            Download YOLii
          </Typography>
        </CardContent>
        <CardActions disableSpacing className={classes.cardactions}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.button, classes.buttoncontainer)}
            onClick={handleClickDownload}
          >
            {"Version ".concat(props.configs.portalYoliiSoftwareVersion)}
            <DownloadIcon className={classes.rightIcon} />
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    configs: state.configs.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YoliiSoftwareDownload);
