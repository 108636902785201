import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import isEqual from "lodash/isEqual";

import {
  setShippingCanProceed,
  setShippingCanNotProceed
} from "../actions/shipping";
import { contentWillChange } from "../actions/content";
import { saveShipping } from "../actions/";

import Shipping from "../components/customer/Shipping";

var AppConfig = require("../config.json");

class ShippingView extends Component {
  constructor(props) {
    super(props);
    this.setFormValue = this.setFormValue.bind(this);
    this.checkForms = this.checkForms.bind(this);
    this.saveShipping = this.saveShipping.bind(this);
    this.goHome = this.goHome.bind(this);

    var shippingform = {};
    Object.keys(AppConfig.forms.shipping).map(n => {
      return Object.assign(shippingform, {
        [n]: this.props.shipping.form[n]
      });
    });

    this.state = {
      mount: true,
      typed: false,
      shippingform: shippingform
    };
  }
  setFormValue(form, name, value) {
    this.setState(
      {
        shippingform: { ...this.state.shippingform, [name]: value },
        typed: true
      },
      this.checkForms
    );
  }
  saveShipping() {
    this.props.dispatch(
      saveShipping(this.state.shippingform, this.props.customerId)
    );
  }
  goHome() {
    this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
  }
  checkForms() {
    const notFilledPersonal = Object.keys(AppConfig.forms.shipping).filter(
      n =>
        AppConfig.forms.shipping[n].required &&
        ((Array.isArray(AppConfig.forms.shipping[n].default) &&
          this.state.shippingform[n].length < 1) ||
          this.state.shippingform[n] === "")
    );
    if (
      !this.state.shippingform.useShippingAddress &&
      this.props.shipping.form.useShippingAddress
    ) {
      this.props.dispatch(setShippingCanProceed());
      return;
    }
    var equalForms = isEqual(this.state.shippingform, this.props.shipping.form);
    if (this.state.typed && !equalForms && notFilledPersonal.length < 1) {
      this.props.dispatch(setShippingCanProceed());
    } else {
      if (this.props.shipping.isset) {
        this.props.dispatch(setShippingCanNotProceed());
      }
    }
  }
  componentDidMount() {
    this.setState({ mount: false }, this.checkForms);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mount || prevState.mount) {
      return;
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{AppConfig.pages.shipping.label}</title>
        </Helmet>
        <Shipping
          {...this.props}
          setFormValue={this.setFormValue}
          saveActive={this.props.shipping.isset}
          saveShipping={this.saveShipping}
          goHome={this.goHome}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    shipping: state.shipping,
    customerId: state.auth.customerId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingView);
