import React from "react";
import PropTypes from "prop-types";

const SvgVideo = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#ff5364"
    />
    <path
      d="M24.5 28a1 1 0 01-1-1V13a1.001 1.001 0 011.538-.844l11 7a1.003 1.003 0 01-.001 1.688l-11 7c-.163.103-.35.156-.537.156zm1-13.179v10.357L33.637 20 25.5 14.821z"
      fill="#c8bdb8"
    />
    <path
      d="M28.5 35c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15zm0-28c-7.168 0-13 5.832-13 13s5.832 13 13 13 13-5.832 13-13-5.832-13-13-13z"
      fill="#c8bdb8"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {props.label}
    </text>
  </svg>
);
SvgVideo.propTypes = {
  label: PropTypes.string
};
SvgVideo.defaultProps = {
  label: "VIDEO"
};
export default SvgVideo;
