import {
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_REQUEST
} from "../store/constants";

export const forgotPasswordSuccess = data => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: { username: data.username }
  });
};
export const forgotPasswordFailure = () => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_FAILURE,
    payload: {}
  });
};
export const forgotPasswordReset = () => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_RESET,
    payload: {}
  });
};
export const forgotPasswordRequest = () => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
    payload: {}
  });
};
