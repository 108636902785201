import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ContentElementGrid from "./ContentElementGrid";
import ContentElementList from "./ContentElementList";
import GridListAction from "./GridListAction";
import { noStopword } from "../../utils";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    marginTop: theme.spacing(1)
  },
  root: {
    flexGrow: 1
  },
  heading: {
    color: theme.palette.primary.dark,
    fontSize: "1.15rem"
  },
  title: {
    marginTop: theme.spacing(2)
  }
}));

function SubcategoryList(props) {
  const classes = useStyles();

  const searchKey = "main".concat(
    props.usersite.main,
    "sub",
    props.usersite.sub
  );

  const hasSearch =
    props.usersite.searchCat.hasOwnProperty(searchKey) &&
    props.usersite.searchCat[searchKey] !== "" &&
    props.usersite.searchCat[searchKey].replace(
      /[-[\]{}()*+?.,\\^$|#\s]/g,
      "\\$&"
    ).length > 3 &&
    noStopword(props.usersite.searchCat[searchKey]);

  const elements = props.group.contentelements.filter(item => {
    if (hasSearch) {
      var needle = props.usersite.searchCat[searchKey].replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        "\\$&"
      );
      var needleRegExp = new RegExp(needle, "i");
      var resultHeadline = needleRegExp.test(item.headline);
      var resultCopytext = needleRegExp.test(item.copytext);
      var resultTags = needleRegExp.test(item.tags);
      return resultHeadline || resultCopytext || resultTags;
    }
    return true;
  });

  return (
    <Fragment>
      {elements.length > 0 ? (
        <Fragment>
          <Box className={classes.title}>
            <Box p={2}>
              <GridListAction
                label={props.group.menulabel}
                hasButtons={props.buttons}
              />
            </Box>
          </Box>
          <Grid container className={classes.root} spacing={3}>
            {elements.map(elem =>
              props.usersite.layout === "grid" ? (
                <ContentElementGrid
                  isContentPage={true}
                  {...elem}
                  key={elem.id}
                />
              ) : (
                <ContentElementList
                  isContentPage={true}
                  {...elem}
                  key={elem.id}
                />
              )
            )}
          </Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubcategoryList);
