import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  setDealsRefresh,
  setDealsRequest,
  setDealsPagination,
  setDealsUserId
} from "../actions/abodeals";
import {
  setAboSalesDealFromSavedItem,
  resetAboSalesDeal
} from "../actions/abosales";
import { getDeals, getAboArticles, deleteAboDeal } from "../actions";
import { getTimestampFromUTC } from "../utils";
import { contentWillChange } from "../actions/content";
import DealsList from "../components/abo/DealsList";
import { animateScroll as scroll } from "react-scroll";

var AppConfig = require("../config.json");

class AboDealsListView extends Component {
  constructor(props) {
    super(props);

    this.loadDeals = this.loadDeals.bind(this);
    this.refreshDeals = this.refreshDeals.bind(this);
    this.changeDealsRequest = this.changeDealsRequest.bind(this);
    this.setPageNum = this.setPageNum.bind(this);
    this.setPerPage = this.setPerPage.bind(this);
    this.abohome = this.abohome.bind(this);
    this.loadArticles = this.loadArticles.bind(this);
    this.editClick = this.editClick.bind(this);
    this.deleteClick = this.deleteClick.bind(this);
  }
  componentDidMount() {
    if (!this.props.aboarticles.loaded) {
      this.loadArticles();
    }
    if (!this.props.abodeals.loaded) {
      this.loadDeals();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!this.props.abodeals.loaded && prevProps.abodeals.loaded) ||
      (!this.props.abodeals.loaded && this.props.abodeals.datasets.length < 1)
    ) {
      this.loadDeals();
    }
    if (this.props.abodeals.loaded && !prevProps.abodeals.loaded) {
      scroll.scrollToTop({
        duration: 300,
        smooth: true
      });
    }
  }

  loadArticles() {
    this.props.dispatch(getAboArticles());
  }

  abohome() {
    this.props.dispatch(contentWillChange(AppConfig.pages.abodashboard.url));
  }

  refreshDeals() {
    this.props.dispatch(setDealsRefresh());
  }

  changeDealsRequest(data) {
    this.props.dispatch(setDealsRequest(data));
  }

  setPageNum(num) {
    const { pagination } = this.props.abodeals.request;
    this.props.dispatch(
      setDealsPagination({
        ...pagination,
        page: num
      })
    );
  }
  setPerPage(num) {
    const { pagination } = this.props.abodeals.request;
    this.props.dispatch(
      setDealsPagination({
        ...pagination,
        perPage: num
      })
    );
  }

  deleteClick(id) {
    if (id === this.props.abosales.progress.id) {
      this.props.dispatch(resetAboSalesDeal());
    }

    this.props.dispatch(deleteAboDeal(id));
  }

  editClick(item) {
    var address = [],
      data = {
        addressId: item.addressId,
        articles: item.articles,
        changed: getTimestampFromUTC(item.changed),
        title: item.title,
        id: item.id,
        clientAddress: {
          company: "",
          salutation: "",
          firstname: "",
          lastname: "",
          zipcode: "",
          city: "",
          phone: "",
          mobile: "",
          email: "",
          country: ""
        }
      };
    if (item.addressId > 0) {
      address = this.props.abodeals.addresses.filter(
        n => n.id === item.addressId
      );
    }

    if (address.length) {
      address = address[0];
      data = {
        ...data,
        clientAddress: {
          ...data.clientAddress,
          company: address.company,
          salutation: address.salutation,
          firstname: address.firstname,
          lastname: address.lastname,
          zipcode: address.zipcode,
          city: address.city,
          phone: address.phone,
          mobile: address.mobile,
          email: address.email,
          country: address.country
        }
      };
    }
    this.props.dispatch(setAboSalesDealFromSavedItem(data));
  }

  loadDeals() {
    if (
      this.props.userId > 0 &&
      this.props.abodeals.request.filter.usersId > 0
    ) {
      this.props.dispatch(getDeals(this.props.abodeals.request));
    }
    if (
      this.props.userId > 0 &&
      this.props.abodeals.request.filter.usersId === 0
    ) {
      this.props.dispatch(setDealsUserId(this.props.userId));
    }
    // if (this.props.groupMaster) {
    //   var params = this.props.orders.request;
    //   params.filter = null;
    //   this.props.dispatch(getOrders(params));
    // } else if (
    //   this.props.userId > 0 &&
    //   this.props.orders.request.filter.usersId > 0
    // ) {
    //   this.props.dispatch(getOrders(this.props.orders.request));
    // }
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{AppConfig.pages.abodealslist.label}</title>
        </Helmet>
        {this.props.aboarticles.loaded && (
          <DealsList
            {...this.props}
            refreshDeals={this.refreshDeals}
            setDealsRequest={this.setDealsRequest}
            setPerPage={this.setPerPage}
            setPageNum={this.setPageNum}
            abohome={this.abohome}
            editClick={this.editClick}
            deleteClick={this.deleteClick}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    abodeals: state.abodeals,
    aboarticles: state.aboarticles,
    abosales: state.abosales,
    userId: state.auth.userId,
    pathname: state.router.location.pathname
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboDealsListView);
