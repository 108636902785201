import React from "react";

const SvgZip = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#556080"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"ZIP"}
    </text>
    <g fill="#c8bdb8">
      <path d="M28.5 24v-2h2v-2h-2v-2h2v-2h-2v-2h2v-2h-2v-2h2V8h-2V6h-2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-4v5c0 2.757 2.243 5 5 5s5-2.243 5-5v-5h-4zm2 5c0 1.654-1.346 3-3 3s-3-1.346-3-3v-3h6v3z" />
      <path d="M26.5 30h2a1 1 0 100-2h-2a1 1 0 100 2z" />
    </g>
  </svg>
);

export default SvgZip;
