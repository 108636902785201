import {
  SHOW_SYSTEM_MESSAGE,
  DISMISS_SYSTEM_MESSAGE
} from "../store/constants";

export const showMessage = msg => dispatch => {
  dispatch({
    type: SHOW_SYSTEM_MESSAGE,
    payload: {
      message: msg,
      variant: "default"
    }
  });
};
export const showMessageSuccess = msg => dispatch => {
  dispatch({
    type: SHOW_SYSTEM_MESSAGE,
    payload: {
      message: msg,
      variant: "success"
    }
  });
};
export const showMessageError = msg => dispatch => {
  dispatch({
    type: SHOW_SYSTEM_MESSAGE,
    payload: {
      message: msg,
      variant: "error"
    }
  });
};
export const showMessageWarning = msg => dispatch => {
  dispatch({
    type: SHOW_SYSTEM_MESSAGE,
    payload: {
      message: msg,
      variant: "warning"
    }
  });
};
export const showMessageInfo = msg => dispatch => {
  dispatch({
    type: SHOW_SYSTEM_MESSAGE,
    payload: {
      message: msg,
      variant: "info"
    }
  });
};
export const showMessageByType = (msg, variant) => dispatch => {
  dispatch({
    type: SHOW_SYSTEM_MESSAGE,
    payload: {
      message: msg,
      variant: variant
    }
  });
};
export const resetMessage = () => dispatch => {
  dispatch({
    type: DISMISS_SYSTEM_MESSAGE,
    payload: {}
  });
};
