import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Home,
  Face,
  LocalShipping,
  Devices,
  Business,
  SupervisorAccount as ContactPersons,
  RecordVoiceOver as Training,
  ImageSearch as Marketing,
  Cast as ContentIcon,
  ExtensionOutlined as ExtensionOutlinedIcon
} from "@material-ui/icons";
import MenuToolTip from "./MenuToolTip";
import { CalculatorIcon } from "../icons";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  listitem: {
    color: theme.palette.primary.light
  }
}));

export default function MenuDrawerItems(props) {
  const classes = useStyles();

  const isSelected = smarturl => {
    return props.pathname.substr(0, smarturl.length) === smarturl;
  };

  const getIcon = smarturl => {
    var icon;
    switch (smarturl) {
      case "technik":
        icon = <Devices className={classes.listitem} />;
        break;
      case "marketing":
        icon = <Marketing className={classes.listitem} />;
        break;
      case "schulung":
        icon = <Training className={classes.listitem} />;
        break;
      case "content":
        icon = <ContentIcon className={classes.listitem} />;
        break;
      case "vertrieb":
        icon = <ExtensionOutlinedIcon className={classes.listitem} />;
        break;
      default:
        icon = null;
        break;
    }
    return (
      <MenuToolTip
        title={AppConfig.pages[smarturl].label}
        withBadge={!props.menuOpen && !props.mobile}
        icon={icon}
      />
    );
  };
  return (
    <List>
      {props.mobile && <Divider />}

      <ListItem
        selected={isSelected(AppConfig.pages.dashboard.url)}
        button
        key={AppConfig.pages.dashboard.url}
        className={classes.listitem}
        onClick={() => props.handleClick(AppConfig.pages.dashboard.url)}
      >
        <ListItemIcon className={classes.listitem}>
          <MenuToolTip
            title={AppConfig.pages.dashboard.label}
            withBadge={!props.menuOpen && !props.mobile}
            icon={<Home className={classes.listitem} />}
          />
        </ListItemIcon>
        <ListItemText
          className={classes.listitem}
          primary={AppConfig.pages.dashboard.menulabel}
        />
      </ListItem>

      <Divider />

      <ListItem
        selected={isSelected(AppConfig.pages.employee.url)}
        button
        key={AppConfig.pages.employee.url}
        className={classes.listitem}
        onClick={() => props.handleClick(AppConfig.pages.employee.url)}
      >
        <ListItemIcon className={classes.listitem}>
          <MenuToolTip
            title={AppConfig.pages.employee.label}
            withBadge={!props.menuOpen && !props.mobile}
            icon={<Face className={classes.listitem} />}
          />
        </ListItemIcon>
        <ListItemText
          className={classes.listitem}
          primary={AppConfig.pages.employee.menulabel}
        />
      </ListItem>

      {props.isCustomermaster && (
        <Fragment>
          <Divider />

          <ListItem
            selected={isSelected(AppConfig.pages.customer.url)}
            button
            key={AppConfig.pages.customer.url}
            className={classes.listitem}
            onClick={() => props.handleClick(AppConfig.pages.customer.url)}
          >
            <ListItemIcon className={classes.listitem}>
              <MenuToolTip
                title={AppConfig.pages.customer.label}
                withBadge={!props.menuOpen && !props.mobile}
                icon={<Business className={classes.listitem} />}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.listitem}
              primary={AppConfig.pages.customer.menulabel}
            />
          </ListItem>

          <ListItem
            selected={isSelected(AppConfig.pages.shipping.url)}
            button
            key={AppConfig.pages.shipping.url}
            className={classes.listitem}
            onClick={() => props.handleClick(AppConfig.pages.shipping.url)}
          >
            <ListItemIcon className={classes.listitem}>
              <MenuToolTip
                title={AppConfig.pages.shipping.label}
                withBadge={!props.menuOpen && !props.mobile}
                icon={<LocalShipping className={classes.listitem} />}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.listitem}
              primary={AppConfig.pages.shipping.menulabel}
            />
          </ListItem>

          <ListItem
            selected={isSelected(AppConfig.pages.contactpersons.url)}
            button
            key={AppConfig.pages.contactpersons.url}
            className={classes.listitem}
            onClick={() =>
              props.handleClick(AppConfig.pages.contactpersons.url)
            }
          >
            <ListItemIcon className={classes.listitem}>
              <MenuToolTip
                title={AppConfig.pages.contactpersons.label}
                withBadge={!props.menuOpen && !props.mobile}
                icon={<ContactPersons className={classes.listitem} />}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.listitem}
              primary={AppConfig.pages.contactpersons.menulabel}
            />
          </ListItem>
        </Fragment>
      )}

      {props.sitemap.pages.length > 0 && <Divider />}

      {props.sitemap.pages.map(item => (
        <ListItem
          selected={isSelected(item.smarturl)}
          button
          key={item.id}
          className={classes.listitem}
          onClick={() => props.handleContentClick(item.smarturl)}
        >
          <ListItemIcon className={classes.listitem}>
            {getIcon(item.smarturl)}
          </ListItemIcon>
          <ListItemText className={classes.listitem} primary={item.menulabel} />
        </ListItem>
      ))}

      {props.isSalesperson && <Divider />}

      {props.isSalesperson && (
        <ListItem
          selected={isSelected(AppConfig.pages.abodashboard.url)}
          button
          key={AppConfig.pages.abodashboard.url}
          className={classes.listitem}
          onClick={() => props.handleClick(AppConfig.pages.abodashboard.url)}
        >
          <ListItemIcon className={classes.listitem}>
            <MenuToolTip
              title={AppConfig.pages.abodashboard.label}
              withBadge={!props.menuOpen && !props.mobile}
              icon={<CalculatorIcon className={classes.listitem} />}
            />
          </ListItemIcon>
          <ListItemText
            className={classes.listitem}
            primary={AppConfig.pages.abodashboard.menulabel}
          />
        </ListItem>
      )}
    </List>
  );
}
