import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Box
} from "@material-ui/core";
import Create from "@material-ui/icons/Create";
import Delete from "@material-ui/icons/DeleteOutline";
import DealsListTableDataClient from "./DealsListTableDataClient";
import DealsListTableSums from "./DealsListTableSums";
import ConfirmDeleteClick from "../misc/ConfirmDeleteClick";
import { getDateFromUTC, getTimeFromUTC } from "../../utils";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: "100%",
    padding: theme.spacing(2, 3)
  },
  table: {
    marginTop: theme.spacing(8)
  }
}));

export default function DealsListTable(props) {
  const classes = useStyles();
  const theme = useTheme();
  const hiddenColumnsXS = useMediaQuery(theme.breakpoints.down("xs"));
  const hiddenColumnsSM = useMediaQuery(theme.breakpoints.down("sm"));

  function handleChangePage(event, newPage) {
    props.setPageNum(newPage + 1);
  }

  function handleChangeRowsPerPage(event) {
    props.setPerPage(event.target.value);
  }

  return (
    <Fragment>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" width={20}>
              Nr
            </TableCell>
            {!hiddenColumnsXS && <TableCell width={200}>Titel</TableCell>}
            <TableCell>Kunde</TableCell>

            {!hiddenColumnsSM && !hiddenColumnsXS && (
              <TableCell width={230}>Summe</TableCell>
            )}
            <TableCell width={140} align="right">
              Gespeichert
            </TableCell>
            <TableCell align="center" width={40} />

            <TableCell align="center" width={40} />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.datasets.map(n => (
            <TableRow key={`pos_${n.id}`}>
              <TableCell component="th" scope="row">
                {n.id}
              </TableCell>
              {!hiddenColumnsXS && (
                <TableCell component="th" scope="row">
                  {n.title === "" ? (
                    <Box className={classes.hint}>ohne Titel</Box>
                  ) : (
                    n.title
                  )}
                </TableCell>
              )}
              <TableCell component="th" scope="row">
                {n.addressId === 0 ? (
                  <Box className={classes.hint}>ohne Kunde</Box>
                ) : (
                  <DealsListTableDataClient addressId={n.addressId} />
                )}
              </TableCell>
              {!hiddenColumnsSM && !hiddenColumnsXS && (
                <TableCell component="th" scope="row">
                  <DealsListTableSums articles={n.articles} />
                </TableCell>
              )}
              <TableCell component="th" scope="row" align="right">
                {getDateFromUTC(n.changed)}
                <br />
                {getTimeFromUTC(n.changed)}
              </TableCell>

              <TableCell component="th" scope="row" align="center">
                <ConfirmDeleteClick
                  size="small"
                  message={`Wollen Sie dieses Angebot (Nr. ${
                    n.id
                  }) wirklich löschen?`}
                  deleteAction={() => props.deleteClick(n.id)}
                  canDelete={true}
                />
              </TableCell>

              <TableCell component="th" scope="row" align="center">
                <IconButton
                  className={classes.button}
                  aria-label="Edit"
                  size="small"
                  onClick={() => props.editClick(n)}
                >
                  <Create />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={props.total}
        rowsPerPage={props.perPage}
        page={props.page}
        backIconButtonProps={{
          "aria-label": "Vorige Seite"
        }}
        nextIconButtonProps={{
          "aria-label": "Nächste Seite"
        }}
        SelectProps={{
          inputProps: { "aria-label": "Datensätze pro Seite" },
          native: true
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}
