import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  dialogtitle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(0)
  },
  dialogcontent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="in" ref={ref} {...props} />;
});

export default function AboDealsAddHint(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClose() {
    props.setAboHint(true);
    setOpen(false);
  }
  //
  React.useEffect(() => {
    if (props.parentOpen && !props.userapp.hintSeen) {
      setTimeout(function() {
        setOpen(true);
      }, 800);
    }
  }, [props]);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogtitle}>
          Hinweis
        </DialogTitle>
        <DialogContent className={classes.dialogcontent}>
          <Box className={classes.root}>
            Ihre Vorgänge werden nicht automatisch gespeichert!
            <br />
            Bevor Sie ein neues Angebot anlegen oder ein bestehendes bearbeiten,
            speichern Sie bitte ihre Eingaben in dieser Ansicht (oben rechts),
            um darauf später weiterhin Zugriff zu haben.
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schliessen und nicht mehr anzeigen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
