import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ContentElementGrid from "./ContentElementGrid";
import ContentElementList from "./ContentElementList";

import GridListAction from "./GridListAction";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative"
  },
  root: {
    flexGrow: 1
  }
}));

function FeatureList(props) {
  const classes = useStyles();

  var featured = [];
  props.sitemap.pages.map(cat =>
    cat.subcategories.map(sub =>
      sub.categorygroups
        .filter(gr => gr.contentelements.length > 0)
        .map(gr =>
          gr.contentelements
            .filter(item => item.feature)
            .map(elem => featured.push(elem))
        )
    )
  );

  return (
    <Fragment>
      {featured.length > 0 && (
        <Fragment>
          <Grid container className={classes.root} spacing={5}>
            <Grid item xs={12}>
              <Box className={clsx(classes.paper, classes.paperlist)}>
                <GridListAction
                  label={AppConfig.pages.dashboard.headings.feature}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container className={classes.root} spacing={3}>
            {featured.map(feat =>
              props.usersite.layout === "grid" ? (
                <ContentElementGrid
                  isContentPage={false}
                  {...feat}
                  key={feat.id}
                />
              ) : (
                <ContentElementList
                  isContentPage={false}
                  {...feat}
                  key={feat.id}
                />
              )
            )}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureList);
