import { createReducer } from "../../utils";
import {
  ABO_SALES_DEAL_IN_PROGRESS,
  ABO_SALES_SET_DEAL_CHANGED,
  ABO_SALES_SET_DEAL_ARTICLES,
  ABO_SALES_SET_DEAL_CLIENT,
  ABO_SALES_SET_DEAL_TITLE,
  ABO_SALES_SET_DEAL_MONTH,
  ABO_SALES_SET_DEAL_COMPLETE,
  ABO_SALES_SET_DEAL_EDITMODE,
  ABO_SALES_START_DEAL,
  ABO_SALES_SET_CLIENT_ADDRESS,
  ABO_SALES_SET_DEAL_FROM_SAVED_ITEMS,
  ABO_SALES_DEAL_RESET
} from "../constants";

const initialState = {
  changed: 0,
  shouldBeSaved: false,
  deal: {
    articles: [],
    title: "",
    clientId: 0,
    months: 0,
    complete: false
  },
  clientAddress: {
    company: "",
    salutation: "",
    firstname: "",
    lastname: "",
    zipcode: "",
    city: "",
    phone: "",
    mobile: "",
    email: "",
    country: ""
  },
  progress: {
    edit: false,
    isset: false,
    id: 0
  }
};

export default createReducer(initialState, {
  [ABO_SALES_SET_DEAL_FROM_SAVED_ITEMS]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: false,
      changed: payload.changed,
      deal: {
        ...state.deal,
        articles: payload.articles,
        title: payload.title,
        clientId: payload.addressId
      },
      progress: {
        edit: true,
        isset: true,
        id: payload.id
      },
      clientAddress: {
        ...state.clientAddress,
        ...payload.clentAddress
      }
    };
  },
  [ABO_SALES_SET_CLIENT_ADDRESS]: (state, payload) => {
    return {
      ...state,
      clientAddress: {
        ...state.clientAddress,
        ...payload
      }
    };
  },
  [ABO_SALES_START_DEAL]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: false,
      deal: {
        articles: [],
        title: "",
        clientId: 0,
        months: 0,
        complete: false
      },
      progress: {
        isset: true,
        edit: true,
        id: 0
      }
    };
  },
  [ABO_SALES_DEAL_IN_PROGRESS]: (state, payload) => {
    return {
      ...state,
      progress: {
        ...state.progress,
        isset: payload.isset,
        id: payload.id
      }
    };
  },
  [ABO_SALES_SET_DEAL_EDITMODE]: (state, payload) => {
    return {
      ...state,
      progress: {
        ...state.progress,
        edit: payload
      }
    };
  },
  [ABO_SALES_SET_DEAL_TITLE]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: true,
      deal: {
        ...state.deal,
        title: payload
      }
    };
  },
  [ABO_SALES_SET_DEAL_COMPLETE]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: true,
      deal: {
        ...state.deal,
        complete: payload
      }
    };
  },
  [ABO_SALES_SET_DEAL_ARTICLES]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: true,
      deal: {
        ...state.deal,
        articles: payload
      }
    };
  },
  [ABO_SALES_SET_DEAL_CLIENT]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: true,
      deal: {
        ...state.deal,
        clientId: payload
      }
    };
  },
  [ABO_SALES_SET_DEAL_MONTH]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: true,
      deal: {
        ...state.deal,
        months: payload
      }
    };
  },
  [ABO_SALES_SET_DEAL_CHANGED]: (state, payload) => {
    return {
      ...state,
      shouldBeSaved: false,
      changed: payload
    };
  },
  [ABO_SALES_DEAL_RESET]: (state, payload) => {
    return initialState;
  }
});
