import { createReducer } from "../../utils";
import { SET_SAVING } from "../constants";

const initialState = {
  inprogress: false
};

export default createReducer(initialState, {
  [SET_SAVING]: (state, payload) => {
    return Object.assign({}, state, {
      inprogress: payload
    });
  }
});
