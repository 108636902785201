import {
  SHIPPING_SET,
  SHIPPING_FORM_SET,
  SHIPPING_RESET,
  SHIPPING_FORM_SAVED
} from "../store/constants";

export const setShippingForm = data => dispatch => {
  dispatch({
    type: SHIPPING_FORM_SET,
    payload: data
  });
};
export const setShippingFormAfterSave = data => dispatch => {
  dispatch({
    type: SHIPPING_FORM_SAVED,
    payload: data
  });
};
export const setShipping = data => dispatch => {
  dispatch({
    type: SHIPPING_SET,
    payload: data
  });
};
export const resetShipping = () => dispatch => {
  dispatch({
    type: SHIPPING_RESET,
    payload: {}
  });
};
export const setShippingCanProceed = () => dispatch => {
  dispatch({
    type: SHIPPING_SET,
    payload: true
  });
};
export const setShippingCanNotProceed = () => dispatch => {
  dispatch({
    type: SHIPPING_SET,
    payload: false
  });
};
