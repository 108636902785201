import {
  CUSTOMER_SET,
  CUSTOMER_FORM_SET,
  CUSTOMER_RESET,
  CUSTOMER_FORM_SAVED
} from "../store/constants";

export const setCustomerForm = data => dispatch => {
  dispatch({
    type: CUSTOMER_FORM_SET,
    payload: data
  });
};
export const setCustomerFormAfterSave = data => dispatch => {
  dispatch({
    type: CUSTOMER_FORM_SAVED,
    payload: data
  });
};
export const setCustomer = data => dispatch => {
  dispatch({
    type: CUSTOMER_SET,
    payload: data
  });
};
export const resetCustomer = () => dispatch => {
  dispatch({
    type: CUSTOMER_RESET,
    payload: {}
  });
};
export const setCustomerCanProceed = () => dispatch => {
  dispatch({
    type: CUSTOMER_SET,
    payload: true
  });
};
export const setCustomerCanNotProceed = () => dispatch => {
  dispatch({
    type: CUSTOMER_SET,
    payload: false
  });
};
