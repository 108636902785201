import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Dashboard from "../components/Dashboard";

class DashboardView extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>YOLii Dashboard</title>
        </Helmet>
        {!this.props.initialLogin && <Dashboard {...this.props} />}
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    username: state.auth.username,
    isSalesperson: state.auth.isSalesperson,
    isCustomermaster: state.auth.isCustomermaster,
    initialLogin: state.auth.initialLogin,
    userId: state.auth.userId,
    employee: state.employee,
    pathname: state.router.location.pathname,
    configs: state.configs.data
  };
};
export default connect(
  mapStateToProps,
  null
)(DashboardView);
