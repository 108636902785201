import React, { Fragment } from "react";
import { connect } from "react-redux";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  AccountCircleOutlined,
  Menu as MenuIcon,
  FavoriteBorder as FavoriteIcon,
  Star,
  StarBorder
} from "@material-ui/icons";

import {
  MenuItem,
  Menu,
  Divider,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Slide
} from "@material-ui/core";

import YoliiLogo from "../misc/YoliiLogo";
import YoliiIcon from "../misc/YoliiIcon";
import {
  contentWillChange,
  contentWillChangeWithReset
} from "../../actions/content";
import { logoutPrepare } from "../../actions/login";
import { toggleDealsCommission } from "../../actions/abodeals";
import { openPasswordOverlay } from "../../actions/password";
import { menuOpen, menuClose } from "../../actions/menu";
import { ltrim } from "../../utils";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1)
    }
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(4)
  },
  applabel: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  appBar: {
    background: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1
  }
}));
function HideOnScroll(props) {
  const { children, auth, initialLogin } = props;
  const trigger = !auth;

  return (
    <Slide appear={false} direction="down" in={!trigger && !initialLogin}>
      {children}
    </Slide>
  );
}

function MenuAppBar(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isDashboard =
    ltrim(props.pathname, "/") === AppConfig.pages.dashboard.url;

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function toggleDrawer() {
    if (props.menu.open) {
      props.dispatch(menuClose());
    } else {
      props.dispatch(menuOpen());
    }
  }
  function handleHomeClick() {
    if (isDashboard) {
      return;
    }
    props.dispatch(contentWillChangeWithReset(AppConfig.pages.dashboard.url));
  }

  function logout() {
    setAnchorEl(null);
    props.dispatch(logoutPrepare());
    props.dispatch(contentWillChangeWithReset("logout"));
  }

  function gotoAccount() {
    setAnchorEl(null);
    props.dispatch(contentWillChange(AppConfig.pages.employee.url));
  }

  function logindata() {
    setAnchorEl(null);
    props.dispatch(contentWillChangeWithReset(AppConfig.pages.logindata.url));
  }

  function passwordChange() {
    setAnchorEl(null);
    props.dispatch(openPasswordOverlay());
  }

  function getLabel() {
    for (var key in AppConfig.pages) {
      if (
        ltrim(props.pathname, "/").split("/")[0] === AppConfig.pages[key].url
      ) {
        return AppConfig.pages[key].menulabel;
      }
    }
    return AppConfig.defaultTitle;
  }
  const label = getLabel();

  const Logo = () => {
    if (isWidthUp("sm", props.width)) {
      return (
        <YoliiLogo
          color="white"
          hover={theme.palette.secondary.main}
          width="120px"
          onClick={handleHomeClick}
        />
      );
    }
    return (
      <div style={{ width: 120 }}>
        <YoliiIcon
          color="white"
          hover={theme.palette.secondary.main}
          onClick={handleHomeClick}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <HideOnScroll
        auth={props.auth && !props.logout}
        initialLogin={props.initialLogin}
      >
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label={AppConfig.pages.dashboard.label}
              onClick={toggleDrawer}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <Fragment>
              <Logo />
              <Typography variant="subtitle1" className={classes.title}>
                <span className={classes.applabel}>{label}</span>
              </Typography>
            </Fragment>
            <div>
              {props.isSalesperson &&
                props.commissionView &&
                ltrim(props.pathname, "/").indexOf(
                  AppConfig.pages.abodashboard.url
                ) !== -1 && (
                  <IconButton
                    aria-label="Provision"
                    onClick={() =>
                      props.dispatch(
                        toggleDealsCommission(!props.commissionActive)
                      )
                    }
                    color={props.commissionActive ? "secondary" : "inherit"}
                  >
                    {props.commissionActive ? <Star /> : <StarBorder />}
                  </IconButton>
                )}
              {props.favorites.length > 0 && (
                <IconButton
                  aria-label="Favoriten"
                  onClick={() =>
                    props.dispatch(
                      contentWillChangeWithReset(AppConfig.pages.favorites.url)
                    )
                  }
                  color="inherit"
                >
                  <FavoriteIcon />
                </IconButton>
              )}

              <IconButton
                aria-label="Benutzer Account"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                edge="end"
              >
                <AccountCircleOutlined />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={gotoAccount}>Mein Account</MenuItem>
                <Divider />
                <MenuItem onClick={passwordChange}>Passwort ändern</MenuItem>
                <Divider />
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth.isAuthenticated,
    initialLogin: state.auth.initialLogin,
    logout: state.auth.isLoggingOut,
    pathname: state.router.location.pathname,
    menu: state.menu,
    favorites: state.usersite.favorites,
    isSalesperson: state.auth.isSalesperson,
    commissionActive: state.abodeals.commissionActive,
    commissionView: state.auth.commissionView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default withWidth()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuAppBar)
);
