import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { contentWillChange } from "../actions/content";
import { Helmet } from "react-helmet";
import AboDashboard from "../components/abo/AboDashboard";

var AppConfig = require("../config.json");

class AboDashboardView extends Component {
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  handleMenuClick(type) {
    switch (type) {
      case "new":
        // this.props.dispatch(contentWillChange(AppConfig.pages.url));
        break;
      case "deals":
        this.props.dispatch(
          contentWillChange(AppConfig.pages.abodealslist.url)
        );
        break;
      case "edit":
        // this.props.dispatch(contentWillChange(next));
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>YOLii Abonnements</title>
        </Helmet>
        {this.props.isSalesperson && (
          <AboDashboard
            {...this.props}
            handleMenuClick={this.handleMenuClick}
          />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    isSalesperson: state.auth.isSalesperson,
    abosales: state.abosales
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboDashboardView);
