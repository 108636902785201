import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%"
  },
  media: {
    height: 260,
    [theme.breakpoints.down("xs")]: {
      height: 160
    }
  }
}));

export default function CategoryMoodImage(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={0} square={true}>
      <CardMedia
        className={classes.media}
        image={props.moodImage}
        title={props.title}
      />
    </Card>
  );
}
