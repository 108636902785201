import React, { Fragment } from "react";
import { connect } from "react-redux";

import {
  Typography,
  Grid,
  Table,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatToPriceEuro } from "../../utils";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  basket: {
    marginTop: theme.spacing(3),
    "& .MuiPaper-elevation2": {
      boxShadow: "none"
    }
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3)
  },
  paperCalc: {
    position: "relative",
    padding: theme.spacing(1, 0),
    paddingBottom: theme.spacing(3)
  }
}));

const calcRent = (articles, categories) => {
  var sumRent = 0;
  articles.map(data => {
    const amount = getPriceSpecs(categories, data);
    return (sumRent += amount);
  });
  return sumRent;
};

const getPriceSpecs = (categories, data) => {
  var currentCat = categories.filter(item => data.artcatsId === item.id);
  if (currentCat.length === 0) {
    return 0;
  }
  if (currentCat[0].rent) {
    return data.preismiete * data.qty;
  }
  return 0;
};

function AboSalesDealCommissionContainer(props) {
  const classes = useStyles();

  const [rent, setRent] = React.useState(
    calcRent(props.articles, props.categories)
  );

  const hasRuntimes =
    props.configs.hasOwnProperty("aboconfig") &&
    props.configs.aboconfig.hasOwnProperty("runtimes") &&
    props.configs.aboconfig.runtimes.length > 0;

  const getBonusWithCustomerProtection = item => {
    return (item.creditpartner + item.factor) * rent;
  };
  const getBonusNoCustomerProtection = item => {
    return (
      (((item.creditpartner + item.factor) * rent) / 100) *
      props.configs.aboconfig.customerprotection
    );
  };

  React.useEffect(() => {
    const sums = calcRent(props.articles, props.categories);
    setRent(sums);
  }, [props]);

  return (
    <Fragment>
      {hasRuntimes && (
        <Grid container className={classes.root} spacing={5}>
          {props.articles.length === 0 && (
            <Grid item xs={12} bgcolor="white">
              <Box className={classes.paper} bgcolor="white">
                <Typography variant="button">Provision</Typography>
                <Box className={classes.hint}>
                  Für Anzeige der Provision erst Artikel auswählen
                </Box>
              </Box>
            </Grid>
          )}
          {props.articles.length > 0 &&
            props.configs.aboconfig.runtimes.map(item => (
              <Grid
                item
                xs={12}
                md={6}
                bgcolor="white"
                key={item.runtimemonths}
              >
                <Box className={classes.paper} bgcolor="white">
                  <Typography variant="button">
                    Provision {item.runtimename}
                  </Typography>
                </Box>
                <Box className={classes.paperCalc} bgcolor="white">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Position</TableCell>
                        <TableCell align="right">Preis</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <strong>Ohne Kundenschutz</strong>
                        </TableCell>
                        <TableCell align="right" width={130}>
                          <strong>
                            {formatToPriceEuro(
                              getBonusNoCustomerProtection(item)
                            )}
                          </strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <strong>Mit Kundenschutz</strong>
                        </TableCell>
                        <TableCell align="right" width={130}>
                          <strong>
                            {formatToPriceEuro(
                              getBonusWithCustomerProtection(item)
                            )}
                          </strong>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            ))}
        </Grid>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    configs: state.configs.data,
    articles: state.abosales.deal.articles,
    categories: state.aboarticles.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboSalesDealCommissionContainer);
