import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import configureStore from "./store/configureStore";

import "./index.scss";

const { store, history, persistor } = configureStore(window.REDUX_INITIAL_DATA);

export default class AppProvider extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
