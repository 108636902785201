import { createReducer } from "../../utils";
import {
  SHIPPING_SET,
  SHIPPING_FORM_SET,
  SHIPPING_RESET,
  SHIPPING_FORM_SAVED
} from "../constants";

const initialState = {
  form: {
    useShippingAddress: false,
    ship_company: "",
    ship_salutation: "",
    ship_firstname: "",
    ship_lastname: "",
    ship_street: "",
    ship_zipcode: "",
    ship_city: "",
    ship_country: ""
  },
  isset: false
};

export default createReducer(initialState, {
  [SHIPPING_FORM_SET]: (state, payload) => {
    return {
      ...state,
      form: {
        ...payload
      }
    };
  },
  [SHIPPING_FORM_SAVED]: (state, payload) => {
    return {
      ...state,
      form: {
        ...state.form,
        useShippingAddress: payload.useShippingAddress,
        ship_company: payload.ship_company,
        ship_salutation: payload.ship_salutation,
        ship_firstname: payload.ship_firstname,
        ship_lastname: payload.ship_lastname,
        ship_street: payload.ship_street,
        ship_zipcode: payload.ship_zipcode,
        ship_city: payload.ship_city
      },
      isset: false
    };
  },
  [SHIPPING_SET]: (state, payload) => {
    return {
      ...state,
      isset: payload
    };
  },
  [SHIPPING_RESET]: (state, payload) => {
    return initialState;
  }
});
