import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Typography,
  Button,
  Box,
  IconButton,
  Popover
} from "@material-ui/core";
import { HighlightOff, CheckCircleOutline } from "@material-ui/icons";
import SubcategoryFilter from "./SubcategoryFilter";
import SubcategorySearch from "./SubcategorySearch";
import {
  resetUsersiteFilter,
  setUsersiteCatSearch,
  resetUsersiteCatSearch
} from "../../actions/usersite";

const useStyles = makeStyles(theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    flexBasis: 200
  },
  filterReset: {
    position: "absolute",
    top: 8,
    left: 60
  },
  paper: {
    width: "calc(100vw - 32px)",
    maxWidth: "400px",
    position: "relative",
    padding: theme.spacing(2, 3)
  },
  papersearch: {
    marginBottom: theme.spacing(2)
  },

  rightIcon: {
    marginLeft: theme.spacing(3)
  }
}));

export default function SubcategoryActions(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const filterKey = "main".concat(props.mainPage.id, "sub", props.subPage.id);
  const searchKey = filterKey;
  const subFilter = props.usersite.filter.hasOwnProperty(filterKey)
    ? props.usersite.filter[filterKey]
    : [];
  const searchStr = props.usersite.searchCat.hasOwnProperty(searchKey)
    ? props.usersite.searchCat[searchKey]
    : "";

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setSearch = str => {
    props.dispatch(setUsersiteCatSearch({ key: searchKey, data: str }));
  };

  return (
    <Fragment>
      <Button
        aria-describedby={id}
        variant="contained"
        color={
          (props.usersite.filter.hasOwnProperty(filterKey) &&
            props.usersite.filter[filterKey].length > 0) ||
          searchStr !== ""
            ? "secondary"
            : "primary"
        }
        fullWidth
        disabled={props.disabled}
        onClick={handleClick}
      >
        Suchen und Filtern
        {(props.usersite.filter.hasOwnProperty(filterKey) &&
          props.usersite.filter[filterKey].length > 0) ||
        searchStr !== "" ? (
          <CheckCircleOutline className={classes.rightIcon} />
        ) : null}
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Box
          className={clsx(classes.paper, classes.papersearch)}
          bgcolor="white"
        >
          <SubcategorySearch
            title={props.mainPage.menulabel.concat(
              " · ",
              props.subPage.menulabel
            )}
            setSearch={setSearch}
            searchStr={searchStr}
          />
        </Box>
        <Box className={classes.paper} bgcolor="white">
          {props.usersite.filter.hasOwnProperty(filterKey) &&
            props.usersite.filter[filterKey].length > 0 && (
              <IconButton
                className={classes.filterReset}
                size="small"
                onClick={() =>
                  props.dispatch(
                    resetUsersiteFilter({ key: filterKey, data: [] })
                  )
                }
              >
                <HighlightOff fontSize="inherit" />
              </IconButton>
            )}
          <Typography gutterBottom variant="body1">
            Filtern
          </Typography>
          <SubcategoryFilter
            filter={subFilter}
            filterKey={filterKey}
            groups={props.subPage.categorygroups}
            dispatch={props.dispatch}
          />
        </Box>
      </Popover>
    </Fragment>
  );
}
