import React, { Fragment } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Chip from "@material-ui/core/Chip";
import Badge from "@material-ui/core/Badge";
import MaterialTable, { MTableToolbar } from "material-table";
import MaterialTableIcons from "../../utils/MaterialTableIcons";
import MaterialTableLocalization from "../../utils/MaterialTableLocalization";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

const useStyles = makeStyles(theme => ({
  filter: {
    margin: theme.spacing(0, 3),
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  table: {
    tableLayout: "auto"
  },
  filterTitle: {
    margin: theme.spacing(0, 6)
  }
}));
export default function AboSalesDealAddArticleTable(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [articles, setArticles] = React.useState(props.articles);
  const [categories, setCategories] = React.useState(props.categories);
  const [filters, setFilters] = React.useState([]);

  const hiddenColumnsXS = useMediaQuery(theme.breakpoints.down("xs"));
  const hiddenColumnsSM = useMediaQuery(theme.breakpoints.down("sm"));
  const cellStyle = {
    paddingTop: "7px",
    paddingBottom: "7px"
  };
  const cellStyleArtnum = {
    paddingTop: "7px",
    paddingBottom: "7px",
    width: "160px !important"
  };
  const cellStyleQty = {
    paddingTop: "7px",
    paddingBottom: "7px",
    width: "20px !important"
  };

  const getItemFromBasket = data => {
    var inBasket = props.basket.filter(item => item.id === data.id),
      qty = 0;
    if (inBasket.length !== 0) {
      qty = inBasket[0].qty;
    }
    return (
      <StyledBadge badgeContent={qty} color="secondary">
        <ShoppingCartIcon color={qty > 0 ? "action" : "disabled"} />
      </StyledBadge>
    );
  };
  const handleFilterClick = id => {
    setFilters([id]);
    // if (filters.indexOf(id) !== -1) {
    //   setFilters(filters.filter(item => item !== id));
    // } else {
    //   setFilters([...filters, id]);
    // }
  };
  React.useEffect(() => {
    var articleItems = props.articles.filter(item =>
      filters.length === 0 ? true : filters.indexOf(item.artcatsId) !== -1
    );
    setArticles(articleItems);
  }, [filters, props.articles]);

  return (
    <Fragment>
      <MaterialTable
        localization={MaterialTableLocalization}
        icons={MaterialTableIcons}
        className={classes.table}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <div className={classes.filterTitle}>Filter</div>
              <div className={classes.filter}>
                {categories.map(item => (
                  <Chip
                    key={item.id}
                    label={item.categoryname}
                    color={
                      filters.indexOf(item.id) !== -1 ? `secondary` : `default`
                    }
                    onClick={() => handleFilterClick(item.id)}
                  />
                ))}
              </div>
            </div>
          )
        }}
        columns={[
          {
            title: "Art. Nr.",
            field: "articlenum",
            cellStyle: cellStyleArtnum
          },
          {
            title: "Name",
            field: "articlename",
            cellStyle: cellStyle
          },
          {
            title: "Anz",
            field: "qty",
            cellStyle: cellStyleQty,
            render: rowData => getItemFromBasket(rowData)
          }
        ]}
        options={{
          pageSize: 20,
          headerStyle: {
            width: "auto !important"
          }
        }}
        onRowClick={(event, rowData) => props.addArticle(rowData)}
        data={articles}
        // actions={[
        //   {
        //     icon: tableIcons.SaveAlt,
        //     tooltip: "Auswählen",
        //     onClick: (event, rowData) => alert("You saved " + rowData.id)
        //   }
        // ]}
        title={props.title}
      />
    </Fragment>
  );
}
