import {
  ABO_DEALS_DATA,
  ABO_DEALS_REQUEST,
  ABO_DEALS_PAGINATION,
  ABO_DEALS_REFRESH,
  ABO_DEALS_RESET,
  ABO_DEALS_DETAIL,
  ABO_DEALS_USERID,
  ABO_DEALS_COMMISSION,
  ABO_DEALS_TOGGLE_COMMISSION
} from "../store/constants";

export const setDeals = data => dispatch => {
  dispatch({
    type: ABO_DEALS_DATA,
    payload: data
  });
};
export const setDealsUserId = data => dispatch => {
  dispatch({
    type: ABO_DEALS_USERID,
    payload: data
  });
};

export const setDealsRequest = data => dispatch => {
  dispatch({
    type: ABO_DEALS_REQUEST,
    payload: data
  });
};

export const setDealsPagination = data => dispatch => {
  dispatch({
    type: ABO_DEALS_PAGINATION,
    payload: data
  });
};

export const setDealsRefresh = () => dispatch => {
  dispatch({
    type: ABO_DEALS_REFRESH,
    payload: {}
  });
};
export const resetDeals = () => dispatch => {
  dispatch({
    type: ABO_DEALS_RESET,
    payload: {}
  });
};

export const setDealsDetail = data => dispatch => {
  dispatch({
    type: ABO_DEALS_DETAIL,
    payload: data
  });
};
export const setDealsCommission = data => dispatch => {
  dispatch({
    type: ABO_DEALS_COMMISSION,
    payload: data
  });
};
export const toggleDealsCommission = data => dispatch => {
  dispatch({
    type: ABO_DEALS_TOGGLE_COMMISSION,
    payload: data
  });
};
