import { createReducer } from "../../utils";
import { SHOW_SYSTEM_MESSAGE, DISMISS_SYSTEM_MESSAGE } from "../constants";

const initialState = {
  message: "",
  variant: "default"
};

export default createReducer(initialState, {
  [SHOW_SYSTEM_MESSAGE]: (state, payload) => {
    return {
      ...state,
      message: payload.message,
      variant: payload.variant
    };
  },
  [DISMISS_SYSTEM_MESSAGE]: (state, payload) => {
    return initialState;
  }
});
