import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Container } from "@material-ui/core";

import MenuEditDealAppBar from "../menu/MenuEditDealAppBar";
import { setAboSalesDealEditMode } from "../../actions/abosales";
import {
  setAboPrintSaveFirst,
  setAboPrintRuntime
} from "../../actions/aboprint";
import { saveAboDeal, getAboPrintLink, sendAboEmail } from "../../actions";
import { setUserAppSettingsAboHint } from "../../actions/userapp";
import AboDealsAddHint from "./AboDealsAddHint";
import AboSalesDealContainer from "./AboSalesDealContainer";
import { ltrim } from "../../utils";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(4)
  },
  container: {
    marginTop: theme.spacing(4)
  },
  dialog: {
    overflowX: "hidden"
  },
  dialogPaper: {
    backgroundColor: "rgba(245,245,245,1)",
    overflowX: "hidden",
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16)
  }
}));

function AboEditDeal(props) {
  const classes = useStyles();

  const clickClose = () => {
    props.dispatch(setAboSalesDealEditMode(false));
  };
  const clickSave = saveFirst => {
    var id = props.abosales.progress.id,
      isnew = props.abosales.progress.id === 0,
      form = {
        articles: props.abosales.deal.articles,
        title: props.abosales.deal.title,
        usersId: props.userId
      };
    if (props.abosales.deal.clientId > 0) {
      form = {
        ...form,
        addressId: props.abosales.deal.clientId
      };
    }
    props.dispatch(saveAboDeal(form, id, isnew, saveFirst));
  };

  const printAbo = runtime => {
    props.dispatch(setAboPrintRuntime(runtime));
    if (props.abosales.shouldBeSaved || props.abosales.progress.id === 0) {
      props.dispatch(setAboPrintSaveFirst(true));
      clickSave(true);
    } else {
      props.dispatch(getAboPrintLink(props.abosales.progress.id));
    }
  };

  const sendAboPerMail = runtime => {
    props.dispatch(sendAboEmail(props.abosales.progress.id, runtime));
  };

  const editOpen =
    props.abosales.progress.edit &&
    props.auth &&
    props.isSalesperson &&
    ltrim(props.pathname, "/").indexOf(AppConfig.pages.abodashboard.url) !== -1;

  return (
    <Fragment>
      <AboDealsAddHint
        parentOpen={editOpen}
        userapp={props.userapp}
        setAboHint={bool => props.dispatch(setUserAppSettingsAboHint(bool))}
      />
      <Dialog
        fullScreen
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
        open={editOpen}
        onClose={clickClose}
        disableEscapeKeyDown={true}
        className={classes.dialog}
      >
        <MenuEditDealAppBar
          clickSave={clickSave}
          clickClose={clickClose}
          saveDisabled={
            props.abosales.deal.title === "" ||
            props.abosales.deal.clientId === 0
          }
        />
        {editOpen && (
          <Container
            maxWidth="xl"
            component="main"
            className={classes.container}
          >
            <AboSalesDealContainer
              clickSave={clickSave}
              printAbo={printAbo}
              sendAboPerMail={sendAboPerMail}
            />
          </Container>
        )}
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth.isAuthenticated,
    userId: state.auth.userId,
    logout: state.auth.isLoggingOut,
    pathname: state.router.location.pathname,
    isSalesperson: state.auth.isSalesperson,
    abosales: state.abosales,
    aboprint: state.aboprint,
    userapp: state.userapp
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboEditDeal);
