import { createReducer } from "../../utils";
import {
  CUSTOMER_SET,
  CUSTOMER_FORM_SET,
  CUSTOMER_RESET,
  CUSTOMER_FORM_SAVED
} from "../constants";

const initialState = {
  form: {
    id: 0,
    customernumber: "",
    company: "",
    salutation: "",
    firstname: "",
    lastname: "",
    street: "",
    zipcode: "",
    city: "",
    country: "",
    email: "",
    phone: "",
    mobile: "",
    useShippingAddress: 0,
    ship_company: "",
    ship_salutation: "",
    ship_firstname: "",
    ship_lastname: "",
    ship_street: "",
    ship_zipcode: "",
    ship_city: "",
    ship_country: ""
  },
  isset: false
};

export default createReducer(initialState, {
  [CUSTOMER_FORM_SET]: (state, payload) => {
    return {
      ...state,
      form: {
        ...payload
      }
    };
  },
  [CUSTOMER_FORM_SAVED]: (state, payload) => {
    return {
      ...state,
      form: {
        ...state.form,
        company: payload.company,
        salutation: payload.salutation,
        firstname: payload.firstname,
        lastname: payload.lastname,
        street: payload.street,
        zipcode: payload.zipcode,
        city: payload.city,
        country: payload.country,
        email: payload.email,
        phone: payload.phone,
        mobile: payload.mobile,
        useShippingAddress: payload.useShippingAddress,
        ship_company: payload.ship_company,
        ship_salutation: payload.ship_salutation,
        ship_firstname: payload.ship_firstname,
        ship_lastname: payload.ship_lastname,
        ship_street: payload.ship_street,
        ship_zipcode: payload.ship_zipcode,
        ship_city: payload.ship_city
      },
      isset: false
    };
  },
  [CUSTOMER_SET]: (state, payload) => {
    return {
      ...state,
      isset: payload
    };
  },
  [CUSTOMER_RESET]: (state, payload) => {
    return initialState;
  }
});
