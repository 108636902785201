import { ABO_ARTICLES_SET, ABO_ARTICLES_RESET } from "../store/constants";

export const setAboArticles = data => dispatch => {
  dispatch({
    type: ABO_ARTICLES_SET,
    payload: data
  });
};
export const resetAboArticles = () => dispatch => {
  dispatch({
    type: ABO_ARTICLES_RESET,
    payload: {}
  });
};
