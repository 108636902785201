import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  width: ${props => props.width};
  cursor: pointer;
  & > svg {
    width: 100%;
    height: auto;
    & > path,
    & > g path {
      fill: ${props => props.color};
    }
  }
  &:hover {
    & > svg {
      & > path,
      & > g path {
        fill: ${props => props.hover};
      }
    }
  }
`;

export default function YoliiLogo(props) {
  return (
    <Container {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468 103.9">
        <path d="M74.2 1.4L44.5 51.8V98h-8.9V51.8L6 1.4h10.3l23.8 40.5L63.9 1.4h10.3zM102.5 84.5C92.8 74.9 88 63.3 88 49.7c0-13.6 4.8-25.2 14.5-34.8C112.1 5.3 123.7.5 137.3.5c13.6 0 25.2 4.8 34.8 14.4 9.6 9.6 14.4 21.3 14.4 34.8 0 13.6-4.8 25.2-14.4 34.8-9.6 9.6-21.3 14.5-34.8 14.5-13.7 0-25.2-4.8-34.8-14.5zm6.2-63.3c-7.9 7.9-11.8 17.4-11.8 28.5 0 11.1 3.9 20.6 11.8 28.5 7.9 7.9 17.4 11.8 28.5 11.8 11.1 0 20.6-3.9 28.5-11.8 7.9-7.9 11.8-17.4 11.8-28.5 0-11.1-3.9-20.6-11.8-28.5-7.9-7.9-17.4-11.8-28.5-11.8-11.1 0-20.6 3.9-28.5 11.8zM223.9 1.4v87.7h44.9V98H215V1.4h8.9zM295.1 25.7h8.9V98h-8.9V25.7zM294.9 1.6h8.9v10.1h-8.9V1.6z" />
        <g>
          <path d="M415.7 72.2c0-19.9-39.9-39.9-39.9-39.9v45.4l40.2 22s-.3-7.7-.3-27.5zM380 25.8l38.4-24.2 39.5 23.6s-26.7 19.1-40 18.9c-13.4-.3-37.9-18.3-37.9-18.3zM423.8 72.6c0 19.9-.3 27.5-.3 27.5l40.2-22V32.7s-39.9 20-39.9 39.9z" />
        </g>
        <path d="M329.9 25.6h8.9v72.3h-8.9V25.6zM329.8 1.5h8.9v10.1h-8.9V1.5z" />
      </svg>
    </Container>
  );
}
