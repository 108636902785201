import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Favorites from "../components/contents/Favorites";

export default class FavoritesView extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>YOLii Inhalte Favoriten</title>
        </Helmet>
        <Favorites />
      </Fragment>
    );
  }
}
