import React from "react";
import PropTypes from "prop-types";

const SvgTxt = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#95a5a5"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {props.label}
    </text>
    <path
      d="M18.5 13h-6a1 1 0 110-2h6a1 1 0 110 2zm3 5h-9a1 1 0 110-2h9a1 1 0 110 2zm4 0c-.26 0-.521-.11-.71-.29-.181-.19-.29-.44-.29-.71s.109-.52.3-.71c.36-.37 1.04-.37 1.41 0 .18.19.29.45.29.71 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29zm12 0h-8a1 1 0 110-2h8a1 1 0 110 2zm-25 15c-.26 0-.521-.11-.71-.29-.181-.19-.29-.45-.29-.71 0-.26.109-.52.29-.71.37-.37 1.05-.37 1.42.01.18.18.29.44.29.7 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29zm12 0h-8a1 1 0 110-2h8a1 1 0 110 2zm19-15h-2a1 1 0 110-2h2a1 1 0 110 2zm-9 5h-22a1 1 0 110-2h22a1 1 0 110 2zm9 0h-6a1 1 0 110-2h6a1 1 0 110 2zm-27 5h-4a1 1 0 110-2h4a1 1 0 110 2zm14 0h-10a1 1 0 110-2h10a1 1 0 110 2zm13 0h-9a1 1 0 110-2h9a1 1 0 110 2z"
      fill="#c8bdb8"
    />
  </svg>
);

SvgTxt.propTypes = {
  label: PropTypes.string
};
SvgTxt.defaultProps = {
  label: "TEXT"
};
export default SvgTxt;
