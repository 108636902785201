import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CreateOutlined, Save } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  dialog: {
    "& .MuiDialogContent-root:first-child": {
      padding: theme.spacing(0),
      width: "180px"
    }
  },
  dialogcontent: {
    padding: theme.spacing(4),
    "& .MuiPaper-elevation2": {
      boxShadow: "none"
    }
  },
  button: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  name: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  qty: {
    padding: theme.spacing(2, 5)
  },
  btnlabel: {
    width: "40px",
    textAlign: "right"
  }
}));

export default function AboSalesDealChangeArticleQty(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [qty, setQty] = React.useState(props.qty);

  const handleChange = event => {
    const { value } = event.target;
    if (value === "") {
      setQty(value);
      return;
    }
    if (isNaN(value)) {
      setQty(1);
      return;
    }
    setQty(Math.round(value));
  };

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleSubmit(evt) {
    evt.preventDefault();
    if (isNaN(qty) || qty < 1) {
      return false;
    }
    changeQty();
    return false;
  }
  function changeQty() {
    handleClose();
    props.changeQty(props.id, qty);
  }

  return (
    <Box p={0}>
      <Button
        size="small"
        variant="text"
        color="primary"
        disableRipple
        fullWidth={true}
        className={classes.button}
        onClick={handleClickOpen}
        disabled={!props.canChange}
      >
        <Box p={0} className={classes.btnlabel}>
          {props.qty}
        </Box>
        <CreateOutlined className={classes.rightIcon} />
      </Button>

      <Dialog
        open={open}
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.dialogcontent}>
          <Typography variant="body2" className={classes.name}>
            {props.name}
          </Typography>
          <Box className={classes.qty}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <TextField
                autoFocus
                autoComplete="_off"
                margin="dense"
                name="qty"
                label="Anzahl"
                fullWidth
                type="text"
                value={qty}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        color="primary"
                        aria-label="Anzahl ändern"
                        onClick={changeQty}
                        disabled={isNaN(qty) || qty < 1}
                      >
                        <Save />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                onChange={handleChange}
              />
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
