import React, { Fragment } from "react";
import { loginUser } from "../actions";
import { connect } from "react-redux";
import { contentWillChange } from "../actions/content";
import { resetAboSalesDeal } from "../actions/abosales";
import { Helmet } from "react-helmet";
import LoginForm from "../components/LoginForm";

import ReactTimeout from "react-timeout";

var AppConfig = require("../config.json");

class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.goHome = this.goHome.bind(this);
    this.gotoReset = this.gotoReset.bind(this);
    this.state = { auth: false, username: "", password: "", dashboard: false };
  }
  componentDidMount() {
    this.props.dispatch(resetAboSalesDeal());
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  goHome() {
    this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
  }

  gotoReset() {
    this.props.dispatch(contentWillChange(AppConfig.pages.forgot.url));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAuthenticated && !prevState.dashboard) {
      this.setState({ dashboard: true }, function() {
        this.props.setTimeout(this.goHome, 100);
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (
      !this.props.isAuthenticating &&
      !this.props.isAuthenticated &&
      this.state.username !== "" &&
      this.state.password !== ""
    ) {
      this.setState({ auth: true });
      this.props.submitClick(this.state.username, this.state.password);
    }
    return false;
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>YOLii Portal Login</title>
        </Helmet>

        <LoginForm
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          gotoReset={this.gotoReset}
          buttonlabel={
            this.props.isAuthenticating === true
              ? "Prüfe Daten"
              : this.props.isAuthenticated === true
              ? "OK"
              : this.state.username !== "" && this.state.password !== ""
              ? "Anmelden »"
              : "Logindaten eingeben"
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
    submitClick: (username, password) => {
      loginUser(username, password)(dispatch);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactTimeout(LoginView));
