import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, Hidden, Grid } from "@material-ui/core";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  dialogtitle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  dialogcontent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  btnSearch: {
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    color: "white",
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.easeOut,
      duration: 200
    }),
    "&:hover": {
      color: theme.palette.primary.main
    },
    "&:disabled": {
      backgroundColor: "lightgrey"
    },
    [theme.breakpoints.down("xs")]: {
      right: 0,
      top: "45px",
      marginLeft: 0
    }
  }
}));

const checkForm = (values, props) => {
  var minLenFields = [],
    propFields = {},
    hasError = false;
  Object.keys(AppConfig.forms.searchabos)
    .filter(n => AppConfig.forms.searchabos[n].hasOwnProperty("minlen"))
    .map(n => {
      return minLenFields.push(n);
    });

  Object.keys(AppConfig.forms.searchabos).map(n => {
    return (propFields[n] = props[n]);
  });

  for (let index = 0; index < minLenFields.length; index++) {
    var name = minLenFields[index];
    if (AppConfig.forms.searchabos[name].hasOwnProperty("minlen")) {
      if (values[name].length < AppConfig.forms.searchabos[name].minlen) {
        hasError = true;
      }
    }
  }
  const notFilled = Object.keys(AppConfig.forms.searchabos).filter(
    n => values[n] === ""
  );

  if (!hasError && notFilled.length < values.length) {
    return true;
  } else {
    return false;
  }
};

export default function AboDealsSearch(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function search(form) {
    handleClose();
    props.searchAboDeals(form);
  }
  var fields = {};
  Object.keys(AppConfig.forms.searchabos).map(n => {
    return Object.assign(fields, {
      [n]: ""
    });
  });

  const [values, setValues] = React.useState(fields);

  const handleChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (AppConfig.forms.searchabos[name].hasOwnProperty("minlen")) {
      if (value.length < AppConfig.forms.searchabos[name].minlen) {
        setErrors({ ...errors, [name]: true });
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
  };

  var errorFields = {};
  Object.keys(AppConfig.forms.searchabos)
    .filter(n => AppConfig.forms.searchabos[n].hasOwnProperty("regxp"))
    .map(n => {
      return Object.assign(errorFields, {
        [n]: false
      });
    });
  const [errors, setErrors] = React.useState(errorFields);

  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    setCanSubmit(checkForm(values, props));
  }, [props, values]);

  return (
    <Fragment>
      <Button
        size="small"
        color="primary"
        className={classes.btnSearch}
        aria-label="Search"
        onClick={handleClickOpen}
        disabled={props.disabled}
      >
        <Search />
      </Button>

      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogtitle}>
          Suche in meinen Vorgängen
        </DialogTitle>
        <DialogContent className={classes.dialogcontent}>
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="id"
                  autoComplete="_off"
                  name="id"
                  error={errors.id}
                  label={AppConfig.forms.searchabos.id.label}
                  fullWidth
                  className={classes.textField}
                  value={values.id}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                  helperText={
                    errors.id
                      ? "mindestens 3 Ziffern eingeben"
                      : values.id.length > 0
                      ? "Für Suchanfrage gültig"
                      : "mindestens 3 Ziffern eingeben"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="title"
                  autoComplete="_off"
                  name="title"
                  error={errors.title}
                  label={AppConfig.forms.searchabos.title.label}
                  fullWidth
                  className={classes.textField}
                  value={values.title}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                  helperText={
                    errors.title
                      ? "mindestens 3 Zeichen eingeben"
                      : values.title.length > 0
                      ? "Für Suchanfrage gültig"
                      : "mindestens 3 Zeichen eingeben"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="company"
                  autoComplete="_off"
                  name="company"
                  error={errors.company}
                  label={AppConfig.forms.searchabos.company.label}
                  fullWidth
                  className={classes.textField}
                  value={values.company}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                  helperText={
                    errors.company
                      ? "mindestens 3 Zeichen eingeben"
                      : values.company.length > 0
                      ? "Für Suchanfrage gültig"
                      : "mindestens 3 Zeichen eingeben"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="zipcode"
                  autoComplete="_off"
                  name="zipcode"
                  error={errors.zipcode}
                  label={AppConfig.forms.searchabos.zipcode.label}
                  fullWidth
                  className={classes.textField}
                  value={values.zipcode}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                  helperText={
                    errors.zipcode
                      ? "mindestens 3 Zeichen eingeben"
                      : values.zipcode.length > 0
                      ? "Für Suchanfrage gültig"
                      : "mindestens 3 Zeichen eingeben"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <TextField
                  id="city"
                  autoComplete="_off"
                  name="city"
                  error={errors.city}
                  label={AppConfig.forms.searchabos.city.label}
                  fullWidth
                  className={classes.textField}
                  value={values.city}
                  onChange={handleChange}
                  margin="dense"
                  variant="outlined"
                  helperText={
                    errors.city
                      ? "mindestens 3 Zeichen eingeben"
                      : values.city.length > 0
                      ? "Für Suchanfrage gültig"
                      : "mindestens 3 Zeichen eingeben"
                  }
                />
              </Box>
            </Grid>
            <Hidden only="xs">
              <Grid item xs={12} sm={6} />
            </Hidden>
            <Grid item xs={12}>
              <Box className={classes.proceed}>
                <Button
                  disabled={!canSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.proceedbtn}
                  onClick={search}
                >
                  Suchen <Search className={classes.rightIcon} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
