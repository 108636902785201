import { MENU_SET, MENU_TOGGLE } from "../store/constants";

export const setMenu = data => dispatch => {
  dispatch({
    type: MENU_SET,
    payload: data
  });
};
export const menuOpen = () => dispatch => {
  dispatch({
    type: MENU_TOGGLE,
    payload: true
  });
};
export const menuClose = () => dispatch => {
  dispatch({
    type: MENU_TOGGLE,
    payload: false
  });
};
