import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from "@material-ui/core";
import { Done } from "@material-ui/icons";
import AboSalesDealChangeArticleQty from "./AboSalesDealChangeArticleQty";
import ConfirmDeleteClick from "../misc/ConfirmDeleteClick";
import { formatToPriceEuro } from "../../utils";

const useStyles = makeStyles(theme => ({
  filter: {
    margin: theme.spacing(0, 3),
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  table: {
    marginTop: theme.spacing(6)
  },
  price: {
    padding: theme.spacing(0)
  },
  qtyCell: {
    padding: theme.spacing(0)
  }
}));
export default function AboSalesDealBasketArticleTable(props) {
  const classes = useStyles();

  const [articles, setArticles] = React.useState(props.articles);

  const [filters, setFilters] = React.useState([]);

  const getPrice = data => {
    var currentCat = props.categories.filter(
      item => data.artcatsId === item.id
    );
    if (currentCat.length === 0) {
      return <Box className={classes.price}>k.A.</Box>;
    }
    if (currentCat[0].rent) {
      return (
        <Box className={classes.price}>
          {formatToPriceEuro(data.preismiete)}
        </Box>
      );
    }
    return (
      <Box className={classes.price}>{formatToPriceEuro(data.vknetto)}</Box>
    );
  };

  const getSum = data => {
    var currentCat = props.categories.filter(
        item => data.artcatsId === item.id
      ),
      price = data.vknetto,
      miete = data.preismiete;
    if (currentCat.length === 0) {
      return <Box className={classes.price}>k.A.</Box>;
    }
    if (currentCat[0].rent) {
      return (
        <Box className={classes.price}>
          {formatToPriceEuro(miete * data.qty)}
        </Box>
      );
    }
    return (
      <Box className={classes.price}>{formatToPriceEuro(price * data.qty)}</Box>
    );
  };
  const isRent = data => {
    var currentCat = props.categories.filter(
      item => data.artcatsId === item.id
    );
    if (currentCat.length === 0 || !currentCat[0].rent) {
      return null;
    }
    return <Done size="small" />;
  };

  // const handleFilterClick = id => {
  //   if (filters.indexOf(id) !== -1) {
  //     setFilters(filters.filter(item => item !== id));
  //   } else {
  //     setFilters([...filters, id]);
  //   }
  // };
  React.useEffect(() => {
    var articleItems = props.articles.filter(item =>
      filters.length === 0 ? true : filters.indexOf(item.artcatsId) !== -1
    );
    setArticles(articleItems);
  }, [filters, props.articles]);

  return (
    <Fragment>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell width={140}>Art.Num</TableCell>
            <TableCell>Name</TableCell>
            <TableCell width={20} align="center">
              Abo
            </TableCell>
            <TableCell width={80} align="center" className={classes.qtyCell}>
              Anzahl
            </TableCell>
            <TableCell width={130} align="right">
              Preis
            </TableCell>
            <TableCell width={130} align="right">
              Summe
            </TableCell>
            <TableCell width={20} align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.map(n => (
            <TableRow key={`pos_${n.id}`}>
              <TableCell component="th" scope="row" width={140}>
                {n.articlenum}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.articlename}
              </TableCell>
              <TableCell component="th" scope="row" align="center" width={20}>
                {isRent(n)}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                align="center"
                width={80}
                className={classes.qtyCell}
              >
                <AboSalesDealChangeArticleQty
                  qty={n.qty}
                  id={n.id}
                  name={n.articlename}
                  changeQty={props.changeQty}
                  canChange={props.restricted.indexOf(n.id) !== -1}
                />
              </TableCell>
              <TableCell component="th" scope="row" align="right" width={130}>
                {getPrice(n)}
              </TableCell>
              <TableCell component="th" scope="row" align="right" width={130}>
                {getSum(n)}
              </TableCell>
              <TableCell component="th" scope="row" align="center" width={20}>
                <ConfirmDeleteClick
                  size="small"
                  message={`Wollen Sie "${n.articlename}" wirklich löschen?`}
                  deleteAction={() => props.deleteArticle(n.id)}
                  canDelete={props.restricted.indexOf(n.id) !== -1}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
}
