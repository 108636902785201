import {
  THEME_COLOR_CHANGE,
  THEME_BG_CHANGE,
  THEME_RESET
} from "../store/constants";

// export function themeChange(theme) {
//   return {
//     type: THEME_CHANGE,
//     payload: theme
//   };
// }

// export function themeReset() {
//   return {
//     type: THEME_CHANGE,
//     payload: {}
//   };
// }

export const themeColorChange = palette => dispatch => {
  dispatch({
    type: THEME_COLOR_CHANGE,
    payload: palette
  });
};
export const themeBgChange = bg => dispatch => {
  dispatch({
    type: THEME_BG_CHANGE,
    payload: bg
  });
};
export const themeReset = () => dispatch => {
  dispatch({
    type: THEME_RESET,
    payload: {}
  });
};
