import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Maincategory from "../components/categories/Maincategory";
import {
  setUsersiteCurrent,
  resetUsersiteFilter,
  resetUsersiteSearch
} from "../actions/usersite";
import { ltrim } from "../utils";

class CategoryMainView extends Component {
  constructor(props) {
    super(props);
    this.evalCurrentPage = this.evalCurrentPage.bind(this);
    this.state = {
      pagetitle: ""
    };
  }
  componentDidMount() {
    this.props.dispatch(setUsersiteCurrent(this.evalCurrentPage()));
    // this.props.dispatch(resetUsersiteFilter());
    this.props.dispatch(resetUsersiteSearch());
  }

  evalCurrentPage() {
    if (this.props.sitemap.pages.length < 1) {
      return { main: 0, sub: 0, group: 0 };
    }
    const mainPage = this.props.sitemap.pages.filter(item => {
      return item.smarturl === ltrim(this.props.pathname, "/");
    })[0];
    this.setState({ pagetitle: mainPage.menulabel });
    return { main: mainPage.id, sub: 0, group: 0 };
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>
            {`Yolii `.concat(
              this.state.pagetitle !== "" ? this.state.pagetitle : ""
            )}
          </title>
        </Helmet>
        {this.props.usersite.main !== 0 && this.props.sitemap.pages.length && (
          <Maincategory {...this.props} />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};
export default connect(
  mapStateToProps,
  null
)(CategoryMainView);
