import { createReducer } from "../../utils";
import { CONFIGS_SET, CONFIGS_RESET } from "../constants";

const initialState = {
  data: {}
};

export default createReducer(initialState, {
  [CONFIGS_SET]: (state, payload) => {
    return Object.assign({}, state, {
      data: payload
    });
  },
  [CONFIGS_RESET]: (state, payload) => {
    return Object.assign({}, state, {
      data: {}
    });
  }
});
