import React from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import { requireAuth } from "./requireAuth";
import AuthUtils from "../utils/AuthUtils";

const RouteAuthenticated = ({ isAuthenticated, component, ...rest }) => {
  return (
    <Route
      render={props => {
        return !AuthUtils.checkAuth(isAuthenticated) ? (
          <Redirect to="/login" />
        ) : (
          React.createElement(
            requireAuth(component),
            Object.assign({}, { ...rest })
          )
        );
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
};

export default connect(
  mapStateToProps,
  null
)(RouteAuthenticated);
