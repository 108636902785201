import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Typography, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AboSalesDealAddArticle from "./AboSalesDealAddArticle";
import AboSalesDealBasketArticleTable from "./AboSalesDealBasketArticleTable";
import { setAboSalesDealArticles } from "../../actions/abosales";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  basket: {
    marginTop: theme.spacing(3),
    "& .MuiPaper-elevation2": {
      boxShadow: "none"
    }
  },
  root: {
    flexGrow: 1
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3)
  }
}));

function AboSalesDealArticleContainer(props) {
  const classes = useStyles();
  const clickAdd = addItem => {
    var newItem = {
      ...addItem,
      id: parseInt(addItem.id),
      qty: parseInt(addItem.qty),
      artcatsId: parseInt(addItem.artcatsId),
      preismiete: parseFloat(addItem.preismiete),
      eknetto: parseFloat(addItem.eknetto),
      vknetto: parseFloat(addItem.vknetto)
    };
    delete newItem.tableData;
    if (props.abosales.deal.articles.length === 0) {
      props.dispatch(setAboSalesDealArticles([{ ...newItem, qty: 1 }]));
      return;
    }
    var wasInBasket = false;
    var articles = props.abosales.deal.articles.map(item => {
      if (item.id === newItem.id) {
        wasInBasket = true;
        return { ...item, qty: item.qty + 1 };
      }
      return item;
    });
    if (!wasInBasket) {
      articles.push({ ...newItem, qty: 1 });
    }
    props.dispatch(setAboSalesDealArticles(articles));
  };

  const articleSet = props.aboarticles.items.filter(item => {
    return props.aboarticles.restricted.indexOf(item.id) !== -1;
  });

  const changeQty = (id, qty) => {
    var articles = props.abosales.deal.articles.map(item => {
      if (item.id === id) {
        return { ...item, qty: qty };
      }
      return item;
    });
    props.dispatch(setAboSalesDealArticles(articles));
  };
  const deleteArticle = id => {
    var articles = props.abosales.deal.articles.filter(item => item.id !== id);
    props.dispatch(setAboSalesDealArticles(articles));
  };
  return (
    <Fragment>
      <Grid container className={classes.root} spacing={5}>
        <Grid item xs={12} bgcolor="white">
          <Box className={classes.paper} bgcolor="white">
            <Typography variant="button">Artikel</Typography>
            <AboSalesDealAddArticle
              articles={articleSet}
              categories={props.aboarticles.categories}
              basket={props.abosales.deal.articles}
              addArticle={clickAdd}
            />
            {props.abosales.deal.articles.length < 1 && (
              <Box className={classes.hint}>Keine Artikel ausgewählt</Box>
            )}
            {props.abosales.deal.articles.length > 0 && (
              <Box className={classes.basket} p={0} boxShadow={0}>
                <AboSalesDealBasketArticleTable
                  articles={props.abosales.deal.articles}
                  categories={props.aboarticles.categories}
                  restricted={props.aboarticles.restricted}
                  changeQty={changeQty}
                  deleteArticle={deleteArticle}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    aboarticles: state.aboarticles,
    abosales: state.abosales
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboSalesDealArticleContainer);
