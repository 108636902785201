import {
  EMPLOYEE_SET,
  EMPLOYEE_FORM_SET,
  EMPLOYEE_RESET,
  EMPLOYEE_FORM_SAVED
} from "../store/constants";

export const setEmployeeForm = data => dispatch => {
  dispatch({
    type: EMPLOYEE_FORM_SET,
    payload: data
  });
};
export const setEmployeeFormAfterSave = data => dispatch => {
  dispatch({
    type: EMPLOYEE_FORM_SAVED,
    payload: data
  });
};
export const setEmployee = data => dispatch => {
  dispatch({
    type: EMPLOYEE_SET,
    payload: data
  });
};
export const resetEmployee = () => dispatch => {
  dispatch({
    type: EMPLOYEE_RESET,
    payload: {}
  });
};
export const setEmployeeCanProceed = () => dispatch => {
  dispatch({
    type: EMPLOYEE_SET,
    payload: true
  });
};
export const setEmployeeCanNotProceed = () => dispatch => {
  dispatch({
    type: EMPLOYEE_SET,
    payload: false
  });
};
