import { createReducer } from "../../utils";
import { SITEMAP_SET, SITEMAP_RESET } from "../constants";

const initialState = {
  pages: []
};

export default createReducer(initialState, {
  [SITEMAP_SET]: (state, payload) => {
    return Object.assign({}, state, {
      pages: payload
    });
  },
  [SITEMAP_RESET]: (state, payload) => {
    return initialState;
  }
});
