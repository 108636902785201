import React, { Fragment } from "react";
import { connect } from "react-redux";

import {
  Typography,
  Grid,
  Table,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import {
  Print as PrintIcon,
  MailOutline as MailIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { formatToPriceEuro, validate } from "../../utils";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  basket: {
    marginTop: theme.spacing(3),
    "& .MuiPaper-elevation2": {
      boxShadow: "none"
    }
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  actions: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3)
  },
  paperCalc: {
    position: "relative",
    padding: theme.spacing(1, 0),
    paddingBottom: theme.spacing(3)
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  rowspan: {
    borderBottom: 0
  }
}));

const calcSums = (articles, categories) => {
  var sumRent = 0,
    sumDirect = 0;
  articles.map(data => {
    const { rent, amount } = getPriceSpecs(categories, data);
    if (rent) {
      return (sumRent += amount);
    }
    return (sumDirect += amount);
  });
  return { rent: sumRent, direct: sumDirect };
};

const getPriceSpecs = (categories, data) => {
  var currentCat = categories.filter(item => data.artcatsId === item.id);
  if (currentCat.length === 0) {
    return { rent: false, amount: 0 };
  }
  if (currentCat[0].rent) {
    return { rent: true, amount: data.preismiete * data.qty };
  }
  return { rent: false, amount: data.vknetto * data.qty };
};

function AboSalesDealRuntimesContainer(props) {
  const classes = useStyles();

  const [sums, setSums] = React.useState(
    calcSums(props.articles, props.categories)
  );

  const hasRuntimes =
    props.configs.hasOwnProperty("aboconfig") &&
    props.configs.aboconfig.hasOwnProperty("runtimes") &&
    props.configs.aboconfig.runtimes.length > 0;

  const getRuntimeBonus = item => {
    if (item.creditkunde === 0) {
      return 0;
    }
    return sums.rent * item.creditkunde * -1;
  };

  const getDirectPaymentDiscount = item => {
    if (item.creditkunde === 0) {
      return sums.direct;
    }
    const discount = sums.rent * item.creditkunde;
    return sums.direct - discount;
  };

  React.useEffect(() => {
    const sums = calcSums(props.articles, props.categories);
    setSums(sums);
  }, [props]);

  const handleClickPrint = runtime => {
    props.printAbo(runtime);
  };
  const handleClickEmail = runtime => {
    props.sendAboPerMail(runtime);
  };
  const checkEmployeeEmail = () => {
    return !validate(props.email, "email");
  };

  return (
    <Fragment>
      {hasRuntimes && (
        <Grid container className={classes.root} spacing={5}>
          {props.articles.length === 0 && (
            <Grid item xs={12} bgcolor="white">
              <Box className={classes.paper} bgcolor="white">
                <Typography variant="button">Laufzeit-Angebote</Typography>
                <Box className={classes.hint}>
                  Für Anzeige der Laufzeit-Angebote erst Artikel auswählen
                </Box>
              </Box>
            </Grid>
          )}
          {props.articles.length > 0 &&
            props.configs.aboconfig.runtimes.map(item => (
              <Grid
                item
                xs={12}
                md={6}
                bgcolor="white"
                key={item.runtimemonths}
              >
                <Box className={classes.paper} bgcolor="white">
                  <Typography variant="button">
                    Angebot {item.runtimename}
                  </Typography>
                </Box>
                <Box className={classes.paperCalc} bgcolor="white">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>Position</TableCell>
                        <TableCell align="right">Preis</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3} component="th" scope="row">
                          <strong>Monatsabo *</strong>
                        </TableCell>
                        <TableCell align="right" width={130}>
                          <strong>{formatToPriceEuro(sums.rent)}</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3} component="th" scope="row">
                          <strong>Einmalkosten Dienstleistung/Zubehör *</strong>
                        </TableCell>
                        <TableCell align="right" width={130}>
                          <strong>{formatToPriceEuro(sums.direct)}</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={2}
                          colSpan={2}
                          width={150}
                          className={classes.rowspan}
                        />
                        <TableCell component="th" scope="row">
                          <strong>Laufzeit-Rabatt</strong>
                        </TableCell>
                        <TableCell align="right" width={130}>
                          <strong>
                            {formatToPriceEuro(getRuntimeBonus(item))}
                          </strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <strong>Rest-Einmalzahlung *</strong>
                        </TableCell>
                        <TableCell align="right" width={130}>
                          <strong>
                            {formatToPriceEuro(getDirectPaymentDiscount(item))}
                          </strong>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Grid container className={classes.actions} spacing={5}>
                    <Grid item xs={12} md={6} bgcolor="white">
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        fullWidth={true}
                        className={classes.button}
                        onClick={() => handleClickPrint(item.runtimemonths)}
                      >
                        Druck-PDF anfordern
                        <PrintIcon className={classes.rightIcon} />
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} bgcolor="white">
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        fullWidth={true}
                        className={classes.button}
                        onClick={() => handleClickEmail(item.runtimemonths)}
                        disabled={checkEmployeeEmail()}
                      >
                        Druck-PDF als Email
                        <MailIcon className={classes.rightIcon} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
        </Grid>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    configs: state.configs.data,
    articles: state.abosales.deal.articles,
    categories: state.aboarticles.categories,
    email: state.employee.form.email
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboSalesDealRuntimesContainer);
