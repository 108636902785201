import {
  CONTACTS_SET,
  CONTACTS_RESET,
  CONTACTS_LOADED
} from "../store/constants";

export const setCustomerContacts = data => dispatch => {
  dispatch({
    type: CONTACTS_SET,
    payload: data
  });
};
export const resetCustomerContacts = () => dispatch => {
  dispatch({
    type: CONTACTS_RESET,
    payload: {}
  });
};

export const setCustomerContactsLoaded = data => dispatch => {
  dispatch({
    type: CONTACTS_LOADED,
    payload: data.loaded
  });
};
