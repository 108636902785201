import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import isEqual from "lodash/isEqual";

import {
  setCustomerCanProceed,
  setCustomerCanNotProceed
} from "../actions/customer";
import { contentWillChange } from "../actions/content";
import { saveCustomer } from "../actions/";

import Customer from "../components/customer/Customer";

var AppConfig = require("../config.json");

class CustomerView extends Component {
  constructor(props) {
    super(props);
    this.setFormValue = this.setFormValue.bind(this);
    this.checkForms = this.checkForms.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.gotoShipping = this.gotoShipping.bind(this);
    this.goHome = this.goHome.bind(this);

    var personalform = {
      id: this.props.customer.form.id,
      customernumber: this.props.customer.form.customernumber
    };
    Object.keys(AppConfig.forms.customer).map(n => {
      return Object.assign(personalform, {
        [n]: this.props.customer.form[n]
      });
    });

    this.state = {
      mount: true,
      typed: false,
      personalform: personalform
    };
  }
  setFormValue(form, name, value) {
    this.setState(
      {
        personalform: { ...this.state.personalform, [name]: value },
        typed: true
      },
      this.checkForms
    );
  }
  saveCustomer() {
    const form = Object.assign({}, this.state.personalform);
    delete form.id;
    this.props.dispatch(saveCustomer(form, this.state.personalform.id));
  }
  goHome() {
    this.props.dispatch(contentWillChange(AppConfig.pages.dashboard.url));
  }
  gotoShipping() {
    this.props.dispatch(contentWillChange(AppConfig.pages.shipping.url));
  }
  checkForms() {
    const notFilledPersonal = Object.keys(AppConfig.forms.customer).filter(
      n =>
        AppConfig.forms.customer[n].required &&
        ((Array.isArray(AppConfig.forms.customer[n].default) &&
          this.state.personalform[n].length < 1) ||
          this.state.personalform[n] === "")
    );
    var equalForms = isEqual(this.state.personalform, this.props.customer.form);
    if (this.state.typed && !equalForms && notFilledPersonal.length < 1) {
      this.props.dispatch(setCustomerCanProceed());
    } else {
      if (this.props.customer.isset) {
        this.props.dispatch(setCustomerCanNotProceed());
      }
    }
  }
  componentDidMount() {
    this.setState({ mount: false }, this.checkForms);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mount || prevState.mount) {
      return;
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{AppConfig.pages.customer.label}</title>
        </Helmet>
        <Customer
          {...this.props}
          setFormValue={this.setFormValue}
          saveActive={this.props.customer.isset}
          saveCustomer={this.saveCustomer}
          goHome={this.goHome}
          gotoShipping={this.gotoShipping}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: state.customer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerView);
