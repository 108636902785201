import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { resetMessage } from "../actions/message";
import ReactTimeout from "react-timeout";

class AppMessage extends Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.message !== "" && this.props.message !== prevProps.message) {
      this.props.enqueueSnackbar(this.props.message, {
        variant: this.props.variant,
        preventDuplicate: false,
        autoHideDuration: this.props.variant === "error" ? 5000 : 2000
      });
      this.props.setTimeout(this.dismiss, 200);
    }
  }

  dismiss() {
    this.props.dispatch(resetMessage());
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = state => {
  return {
    message: state.notification.message,
    variant: state.notification.variant
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ReactTimeout(AppMessage)));
