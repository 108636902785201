import { createReducer } from "../../utils";
import {
  SHOW_SECTION_INCOMPLETE,
  DISMISS_SECTION_INCOMPLETE
} from "../constants";

const initialState = {
  link: "",
  label: "",
  isActive: false
};

export default createReducer(initialState, {
  [SHOW_SECTION_INCOMPLETE]: (state, payload) => {
    return Object.assign({}, state, {
      link: payload.link,
      label: payload.label,
      isActive: true
    });
  },
  [DISMISS_SECTION_INCOMPLETE]: (state, payload) => {
    return Object.assign({}, state, {
      link: "",
      label: "",
      isActive: false
    });
  }
});
