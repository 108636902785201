import { createReducer } from "../../utils";
import { CONTENT_CHANGE, CONTENT_CHANGE_RESET } from "../constants";

const initialState = {
  goto: "",
  resetSitemapSelects: false
};

export default createReducer(initialState, {
  [CONTENT_CHANGE]: (state, payload) => {
    return Object.assign({}, state, {
      goto: payload.url,
      resetSitemapSelects: false
    });
  },
  [CONTENT_CHANGE_RESET]: (state, payload) => {
    return Object.assign({}, state, {
      goto: payload.url,
      resetSitemapSelects: true
    });
  }
});
