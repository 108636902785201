import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatToPriceEuro } from "../../utils";

const useStyles = makeStyles(theme => ({
  wrap: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  hint: {
    color: theme.palette.secondary.dark,
    fontStyle: "italic"
  },
  label: {
    width: "60%"
  },
  sum: {
    flexGrow: 1,
    wordBreak: "keep-all",
    textAlign: "right"
  }
}));

const calcSums = (articles, categories) => {
  var sumRent = 0,
    sumDirect = 0;
  articles.map(data => {
    const { rent, amount } = getPriceSpecs(categories, data);
    if (rent) {
      return (sumRent += amount);
    }
    return (sumDirect += amount);
  });
  return { rent: sumRent, direct: sumDirect };
};

const getPriceSpecs = (categories, data) => {
  var currentCat = categories.filter(item => data.artcatsId === item.id);
  if (currentCat.length === 0) {
    return { rent: false, amount: 0 };
  }
  if (currentCat[0].rent) {
    return {
      rent: true,
      amount: parseFloat(data.preismiete) * parseInt(data.qty)
    };
  }
  return {
    rent: false,
    amount: parseFloat(data.vknetto) * parseInt(data.qty)
  };
};

function DealsListTableSums(props) {
  const classes = useStyles();

  const [sums, setSums] = React.useState(
    calcSums(props.articles, props.categories)
  );

  React.useEffect(() => {
    const sums = calcSums(props.articles, props.categories);
    setSums(sums);
  }, [props]);

  return (
    <Box className={classes.wrap}>
      {sums.rent > 0 || sums.direct > 0 ? (
        <Fragment>
          <Box className={classes.label}>Monatsabo *:</Box>
          <Box className={classes.sum}>{formatToPriceEuro(sums.rent)}</Box>
          <Box className={classes.label}>Einmalkosten *:</Box>
          <Box className={classes.sum}>{formatToPriceEuro(sums.direct)}</Box>
        </Fragment>
      ) : (
        <Box className={classes.hint}>ohne Artikel</Box>
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    categories: state.aboarticles.categories
  };
};

export default connect(
  mapStateToProps,
  null
)(DealsListTableSums);
