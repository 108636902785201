import { createReducer } from "../../utils";
import { MENU_SET, MENU_TOGGLE } from "../constants";

const initialState = {
  open: false,
  navigation: {}
};

export default createReducer(initialState, {
  [MENU_TOGGLE]: (state, payload) => {
    return Object.assign({}, state, {
      open: payload
    });
  },
  [MENU_SET]: (state, payload) => {
    return Object.assign({}, state, {
      navigation: {
        ...state.navigation,
        payload
      }
    });
  }
});
