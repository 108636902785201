import React, { Component } from "react";
import { Helmet } from "react-helmet";
import routes from "./routes";
import { Scaffolding } from "./views";
import ScrollToTop from "./utils/ScrollToTop";

class App extends Component {
  render() {
    return (
      <ScrollToTop>
        <Helmet>
          <meta charSet="utf-8" />
          <title>YOLii Online Kunden-Portal</title>
          <meta
            name="description"
            content="YOLii Online Kunden-Portal · E+S Gesund Leben GmbH"
          />
        </Helmet>
        <Scaffolding>{routes}</Scaffolding>
      </ScrollToTop>
    );
  }
}

export default App;
