import React from "react";
import { connect } from "react-redux";
import Save from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import { resetPassword } from "../actions";
import { closePasswordOverlay } from "../actions/password";
import { logoutPrepare } from "../actions/login";
import { contentWillChange } from "../actions/content";
import clsx from "clsx";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Divider,
  Typography,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { validate } from "./";

var AppConfig = require("../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  bodytext: {
    textAlign: "center"
  },
  error: {
    textAlign: "center",
    color: theme.palette.error.main
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const checkForm = (values, props) => {
  var regexFields = [],
    hasError = false;

  Object.keys(AppConfig.forms.password)
    .filter(n => AppConfig.forms.password[n].hasOwnProperty("regxp"))
    .map(n => {
      return regexFields.push(n);
    });

  for (let index = 0; index < regexFields.length; index++) {
    var name = regexFields[index];
    if (AppConfig.forms.password[name].hasOwnProperty("regxp")) {
      if (!validate(values[name], AppConfig.forms.password[name].regxp)) {
        hasError = true;
      }
    }
  }

  const notFilled = Object.keys(AppConfig.forms.password).filter(
    n =>
      AppConfig.forms.password[n].required &&
      ((Array.isArray(AppConfig.forms.password[n].default) &&
        values[n].length < 1) ||
        values[n] === "")
  );

  if (!hasError && notFilled.length < 1) {
    return true;
  } else {
    return false;
  }
};

const logoutAfterChange = props => {
  props.dispatch(logoutPrepare());
  props.dispatch(contentWillChange("logout"));
};

function PasswordChange(props) {
  const classes = useStyles(props);

  var fields = {};
  Object.keys(AppConfig.forms.password).map(n => {
    return Object.assign(fields, {
      [n]: ""
    });
  });

  const [values, setValues] = React.useState(fields);

  var errorFields = {};
  Object.keys(AppConfig.forms.password)
    .filter(n => AppConfig.forms.password[n].hasOwnProperty("regxp"))
    .map(n => {
      return Object.assign(errorFields, {
        [n]: false
      });
    });
  const [errors, setErrors] = React.useState(errorFields);

  const [canSubmit, setCanSubmit] = React.useState(false);
  const [passwordDontMatch, setPasswordDontMatch] = React.useState("");
  const [showPassword, setPasswordVisibility] = React.useState(false);
  const [willLogout, setWillLogout] = React.useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const pwError = "Passwort noch nicht gültig";
  const pwHint = "Format wie beschrieben wählen";
  const pwRepeat = "Passwort wiederholen";
  const pwOk = "Passwort gültig";

  const handleChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (AppConfig.forms.password[name].hasOwnProperty("regxp")) {
      if (!validate(value, AppConfig.forms.password[name].regxp)) {
        setErrors({ ...errors, [name]: true });
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
  };

  const submitForm = () => {
    if (values.newpw !== values.newpwrepeat) {
      setPasswordDontMatch("Die Passwörter stimmen nicht überein");
      return;
    } else if (values.oldpw === values.newpw) {
      setPasswordDontMatch("Bitte geben Sie ein neues Passwort an");
      return;
    } else {
      props.dispatch(resetPassword(values, props.employeeId));
    }
  };

  const goLogout = () => {
    props.dispatch(logoutPrepare());
    props.dispatch(contentWillChange("logout"));
  };

  const handleClose = () => {
    setCanSubmit(false);
    var fields = {};
    Object.keys(AppConfig.forms.password).map(n => {
      return Object.assign(fields, {
        [n]: ""
      });
    });
    setValues(fields);
    var errorFields = {};
    Object.keys(AppConfig.forms.password)
      .filter(n => AppConfig.forms.password[n].hasOwnProperty("regxp"))
      .map(n => {
        return Object.assign(errorFields, {
          [n]: false
        });
      });
    setErrors(errorFields);
    props.dispatch(closePasswordOverlay());
  };
  React.useEffect(() => {
    if (props.password.changed) {
      logoutAfterChange(props);
      // if (!willLogout) {
      //   setWillLogout(true);
      //   setTimeout(() => logoutAfterChange(props), 2000);
      // } else if (!props.password.open) {
      //   setWillLogout(false);
      // }
    } else {
      setPasswordDontMatch("");
      setCanSubmit(checkForm(values, props));
    }
  }, [props, values, willLogout]);

  return (
    <Dialog
      open={props.password.open || (props.initialLogin && !props.isLoggingOut)}
      onClose={handleClose}
      disableBackdropClick={props.initialLogin}
      disableEscapeKeyDown={props.initialLogin}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <div className={classes.paper}>
          <Typography variant="h6" className={classes.heading}>
            {props.initialLogin
              ? "Bitte ändern Sie initial ihr Passwort."
              : "Passwort ändern"}
          </Typography>
          <Typography variant="subtitle2" className={classes.bodytext}>
            Nachdem Sie erfolgreich ein neues Passwort festgelegt haben, werden
            Sie automatisch ausgeloggt und können sich dann mit dem neuen
            Passwort anmelden.
          </Typography>
          <Divider />
          <TextField
            autoFocus
            margin="dense"
            id="oldpw"
            name="oldpw"
            error={errors.oldpw}
            label={AppConfig.forms.password.oldpw.label}
            required={AppConfig.forms.password.oldpw.required}
            fullWidth
            className={clsx(classes.textField, classes.headingMargin)}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            variant="outlined"
            onChange={handleChange}
          />
          <Divider />

          <TextField
            margin="dense"
            id="newpw"
            name="newpw"
            disabled={willLogout}
            error={errors.newpw}
            label={AppConfig.forms.password.newpw.label}
            required={AppConfig.forms.password.newpw.required}
            fullWidth
            className={classes.textField}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            variant="outlined"
            onChange={handleChange}
            helperText={
              errors.newpw ? pwError : values.newpw.length > 0 ? pwOk : pwHint
            }
          />
          <TextField
            margin="dense"
            id="newpwrepeat"
            name="newpwrepeat"
            disabled={willLogout}
            error={errors.newpwrepeat}
            label={AppConfig.forms.password.newpwrepeat.label}
            required={AppConfig.forms.password.newpwrepeat.required}
            fullWidth
            className={classes.textField}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            variant="outlined"
            onChange={handleChange}
            helperText={
              errors.newpwrepeat
                ? pwError
                : values.newpwrepeat.length > 0
                ? pwOk
                : pwRepeat
            }
          />

          {passwordDontMatch !== "" && (
            <Typography variant="body1" className={classes.error}>
              {passwordDontMatch}
            </Typography>
          )}

          <Typography
            variant="body2"
            className={clsx(classes.bodytext, classes.headingMargin)}
          >
            Hinweis: ein Passwort muss mindestens 8 Zeichen und mindestens eine
            Zahl enthalten. Zulässige Sonderzeichen: -_. (Minus, Unterstrich,
            Punkt)
          </Typography>

          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            fullWidth
            onClick={submitForm}
            disabled={!canSubmit || willLogout}
          >
            Passwort ändern
            <Save className={classes.rightIcon} />
          </Button>

          {!props.initialLogin ? (
            <Button
              className={classes.submit}
              fullWidth
              onClick={handleClose}
              disabled={willLogout}
            >
              Schliessen
            </Button>
          ) : (
            <Button
              className={classes.submit}
              fullWidth
              onClick={goLogout}
              disabled={willLogout}
            >
              Logout
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    password: state.password,
    initialLogin: state.auth.initialLogin,
    employeeId: state.auth.employeeId,
    isLoggingOut: state.auth.isLoggingOut
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordChange);
