import { CONTENT_CHANGE, CONTENT_CHANGE_RESET } from "../store/constants";

export const contentWillChange = href => dispatch => {
  dispatch({
    type: CONTENT_CHANGE,
    payload: { url: href }
  });
};
export const contentWillChangeWithReset = href => dispatch => {
  dispatch({
    type: CONTENT_CHANGE_RESET,
    payload: { url: href }
  });
};
export const contentDidChange = () => dispatch => {
  dispatch({
    type: CONTENT_CHANGE,
    payload: { url: "" }
  });
};
