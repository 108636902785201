import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Paper, Typography } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import AboAddOrResumeButton from "./AboAddOrResumeButton";
import AboDealsSearch from "./AboDealsSearch";
import DealsListTable from "./DealsListTable";

import {
  startNewAboSalesDeal,
  setAboSalesDealEditMode
} from "../../actions/abosales";
import Autorenew from "@material-ui/icons/Autorenew";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark
  },
  title: {
    position: "relative",
    marginBottom: theme.spacing(1)
  },
  datasets: {
    position: "relative",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3)
    }
  },
  leftIcon: {
    marginRight: theme.spacing(3)
  },
  backbtn: {
    marginTop: theme.spacing(3)
  },
  tablecontainer: {
    overflowX: "auto",
    width: "100%"
  },
  refresh: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 1
  },
  actionholder: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "relative"
  },
  error: {
    color: theme.palette.error.main,
    fontStyle: "italic",
    padding: theme.spacing(2, 3)
  },
  loading: {
    color: theme.palette.secondary.main,
    fontStyle: "italic",
    padding: theme.spacing(2, 3)
  },
  commissionheadline: {
    display: "inline-block",
    marginLeft: theme.spacing(3),
    fontWeight: 400,
    fontSize: "1rem"
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "70px",
    paddingBottom: theme.spacing(10)
  }
}));

export default function DealsList(props) {
  const classes = useStyles();

  const clickResume = () => {
    props.dispatch(setAboSalesDealEditMode(true));
  };
  const clickNew = () => {
    props.dispatch(startNewAboSalesDeal());
  };

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xl" component="main">
        <Box className={classes.title}>
          <Box p={2}>
            <Typography component="h1" variant="h6" className={classes.heading}>
              {AppConfig.pages.abodealslist.headings.main}
              {props.abodeals.commissionActive ? (
                <Box className={classes.commissionheadline}>
                  (Provisionsansicht)
                </Box>
              ) : (
                ""
              )}
            </Typography>
          </Box>
          <AboAddOrResumeButton
            resume={props.abosales.progress.isset}
            clickResume={clickResume}
            clickNew={clickNew}
          />
          {/* <AboDealsSearch disabled={props.abodeals.datasets.length === 0} /> */}
          <AboDealsSearch disabled={true} />
        </Box>
        <Paper bgcolor="white" className={classes.datasets}>
          <Box p={2} className={classes.actionholder}>
            <Button
              variant="outlined"
              size="small"
              className={classes.refresh}
              aria-label="Refresh"
              onClick={props.refreshDeals}
            >
              Neu laden <Autorenew />
            </Button>
          </Box>
          {props.abodeals.datasets.length === 0 ? (
            <Typography
              variant="subtitle2"
              className={
                props.abodeals.loaded ? classes.error : classes.loading
              }
            >
              {props.abodeals.loaded ? "Keine Datensätze vorhanden" : "Lade…"}
            </Typography>
          ) : (
            <Box className={classes.tablecontainer}>
              <DealsListTable
                datasets={props.abodeals.datasets}
                setPageNum={props.setPageNum}
                setPerPage={props.setPerPage}
                page={props.abodeals.request.pagination.page - 1}
                perPage={props.abodeals.request.pagination.perPage}
                total={props.abodeals.total}
                editClick={props.editClick}
                deleteClick={props.deleteClick}
              />
            </Box>
          )}
        </Paper>
        <Button
          variant="contained"
          size="small"
          className={classes.backbtn}
          onClick={props.abohome}
        >
          <ChevronLeft className={classes.leftIcon} />
          Zurück
        </Button>
      </Container>
    </Box>
  );
}
