import { createReducer } from "../../utils";
import {
  ABO_ARTICLES_SET,
  ABO_ARTICLES_SWITCH_SET,
  ABO_ARTICLES_RESET
} from "../constants";

const initialState = {
  items: [],
  availableSets: [],
  activeSet: "",
  restricted: [],
  categories: [],
  loaded: false
};

export default createReducer(initialState, {
  [ABO_ARTICLES_SET]: (state, payload) => {
    return {
      ...state,
      items: payload.items,
      activeSet: payload.activeSet,
      restricted: payload.restricted,
      categories: payload.categories,
      loaded: true
    };
  },
  [ABO_ARTICLES_SWITCH_SET]: (state, payload) => {
    return {
      ...state,
      activeSet: payload.activeSet,
      restricted: payload.restricted
    };
  },
  [ABO_ARTICLES_RESET]: (state, payload) => {
    return initialState;
  }
});
