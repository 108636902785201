import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTimeout from "react-timeout";
import { contentWillChange } from "../actions/content";
import SectionIncompleteOverlay from "../components/SectionIncompleteOverlay";

class SectionIncomplete extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.prepareGoBack = this.prepareGoBack.bind(this);
    this.state = { back: false };
  }
  goBack() {
    this.props.dispatch(contentWillChange(this.props.section.link));
  }
  prepareGoBack(evt) {
    if (this.state.back) {
      return;
    }
    this.setState({ back: true });
    this.props.setTimeout(this.goBack, 300);
  }
  componentWillUpdate(nextProps, nextState) {
    if (!nextProps.section.isActive && this.state.back) {
      this.setState({ back: false });
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.section.isActive && (
          <SectionIncompleteOverlay
            fade={this.state.back}
            buttonLabel={this.props.section.label}
            hanldeClick={this.prepareGoBack}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    section: state.section
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactTimeout(SectionIncomplete));
