import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTimeout from "react-timeout";
import { contentDidChange } from "../actions/content";
import { setNotFound } from "../actions";
import { push } from "connected-react-router";
import { ltrim } from "./";
import styled, { css } from "styled-components";
import MenuDrawer from "../components/menu/MenuDrawer";

const Container = styled.div`
  transition: opacity 0.2s linear, transform 0.2s ease-out;
  opacity: 1;
  transform: translateY(0);
  flex-grow: 1;
  ${props =>
    props.invisible &&
    css`
      opacity: 0;
      transform: translateY(-10px);
    `};
`;

const Wrapper = styled.div`
  display: flex;
`;

class ContentFader extends Component {
  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.resetNotFound = this.resetNotFound.bind(this);
    this.state = { change: false };
  }

  goNext() {
    this.props.dispatch(push("/" + this.props.goto));
  }
  resetNotFound() {
    this.props.dispatch(setNotFound(false));
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      this.props.goto !== nextProps.goto &&
      nextProps.goto !== "" &&
      ltrim(this.props.pathname, "/") !== nextProps.goto &&
      !this.state.change
    ) {
      this.setState({ change: true });

      this.props.setTimeout(this.goNext, 250);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.change) {
      if (ltrim(this.props.pathname, "/") === this.props.goto) {
        this.props.dispatch(contentDidChange());
        this.setState({ change: false });
      }
      if (this.props.notfound !== prevProps.notfound && prevProps.notfound) {
        this.props.setTimeout(this.resetNotFound, 220);
      }
    }
  }

  render() {
    const { change } = this.state;
    return (
      <Wrapper>
        <MenuDrawer />
        <Container invisible={change}>{this.props.children}</Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
    goto: state.content.goto,
    notfound: state.notfound.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactTimeout(ContentFader));
