import {
  USERSITE_SET_CURRENT,
  USERSITE_SET_FILTER,
  USERSITE_SET_SEARCH,
  USERSITE_SET_DETAIL,
  USERSITE_RESET_DETAIL,
  USERSITE_SET_FAVORITES,
  USERSITE_SET_CAT_SEARCH,
  USERSITE_SET_LAYOUT,
  USERSITE_RESET_CURRENT,
  USERSITE_RESET_ALL_EXCEPT_FAV,
  USERSITE_RESET,
  USERSITE_RELOAD,
  USERSITE_HAS_CONTENT
} from "../store/constants";

export const setUsersiteHasContent = data => dispatch => {
  dispatch({
    type: USERSITE_HAS_CONTENT,
    payload: data
  });
};
export const setUsersiteReload = data => dispatch => {
  dispatch({
    type: USERSITE_RELOAD,
    payload: data
  });
};
export const setUsersiteFilter = data => dispatch => {
  dispatch({
    type: USERSITE_SET_FILTER,
    payload: data
  });
};
export const resetUsersiteFilter = data => dispatch => {
  dispatch({
    type: USERSITE_SET_FILTER,
    payload: data
  });
};
export const setUsersiteCatSearch = data => dispatch => {
  dispatch({
    type: USERSITE_SET_CAT_SEARCH,
    payload: data
  });
};
export const resetUsersiteCatSearch = data => dispatch => {
  dispatch({
    type: USERSITE_SET_CAT_SEARCH,
    payload: data
  });
};
export const setUsersiteSearch = data => dispatch => {
  dispatch({
    type: USERSITE_SET_SEARCH,
    payload: data
  });
};
export const setUsersiteFavorites = data => dispatch => {
  dispatch({
    type: USERSITE_SET_FAVORITES,
    payload: data
  });
};
export const resetUsersiteSearch = () => dispatch => {
  dispatch({
    type: USERSITE_SET_SEARCH,
    payload: ""
  });
};
export const setUsersiteCurrent = data => dispatch => {
  dispatch({
    type: USERSITE_SET_CURRENT,
    payload: data
  });
};

export const resetUsersiteCurrent = () => dispatch => {
  dispatch({
    type: USERSITE_RESET_CURRENT,
    payload: {}
  });
};
export const setUsersiteDetail = data => dispatch => {
  dispatch({
    type: USERSITE_SET_DETAIL,
    payload: data
  });
};
export const setUsersiteLayout = data => dispatch => {
  dispatch({
    type: USERSITE_SET_LAYOUT,
    payload: data
  });
};

export const resetUsersiteDetail = () => dispatch => {
  dispatch({
    type: USERSITE_RESET_DETAIL,
    payload: {}
  });
};
export const resetUsersiteWithoutFav = () => dispatch => {
  dispatch({
    type: USERSITE_RESET_ALL_EXCEPT_FAV,
    payload: {}
  });
};
export const resetUsersite = () => dispatch => {
  dispatch({
    type: USERSITE_RESET,
    payload: {}
  });
};
