import { createReducer } from "../../utils";
import { USER_APP_SETTINGS, USER_APP_SETTINGS_ABO_HINT } from "../constants";

const initialState = {
  hintSeen: false
};

export default createReducer(initialState, {
  [USER_APP_SETTINGS]: (state, payload) => {
    return payload;
  },
  [USER_APP_SETTINGS_ABO_HINT]: (state, payload) => {
    return {
      ...state,
      hintSeen: payload
    };
  }
});
