import React from "react";

const SvgCsv = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#f36fa0"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"CSV"}
    </text>
    <path
      d="M23.5 16v-4h-12v22h33V16h-21zm-10-2h8v2h-8v-2zm0 4h8v2h-8v-2zm0 4h8v2h-8v-2zm0 4h8v2h-8v-2zm8 6h-8v-2h8v2zm21 0h-19v-2h19v2zm0-4h-19v-2h19v2zm0-4h-19v-2h19v2zm-19-4v-2h19v2h-19z"
      fill="#c8bdb8"
    />
  </svg>
);

export default SvgCsv;
