import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { TextField, IconButton, InputAdornment, Box } from "@material-ui/core";
import {
  Search as SearchIcon,
  HighlightOff as CancelIcon
} from "@material-ui/icons";
import { setUsersiteSearch, resetUsersiteSearch } from "../../actions/usersite";
import { noStopword } from "../../utils";

const useStyles = makeStyles(theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  searchwrapper: {
    display: "flex",
    paddingRight: theme.spacing(2)
  },
  iconSearch: {
    marginTop: theme.spacing(3)
  },
  margin: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  textField: {
    flexBasis: 200,
    flexGrow: 1
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function SubcategorySearch(props) {
  const classes = useStyles();

  const [searchStr, setSearchStr] = React.useState(props.searchStr);
  const [error, setError] = React.useState("");

  const handleChange = event => {
    const searchStr = event.target.value;
    setSearchStr(searchStr);
    if (
      searchStr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&").length <= 3 ||
      !noStopword(searchStr)
    ) {
      setError("Der Suchbegriff ist ungültig");
    } else {
      setError("");
    }
  };

  const resetSearch = () => {
    setSearchStr("");
    props.setSearch("");
  };

  const handleSearchClick = event => {
    if (
      searchStr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&").length > 3 &&
      noStopword(searchStr)
    ) {
      props.setSearch(searchStr);
    }
    event.preventDefault();
  };

  return (
    <Fragment>
      <form onSubmit={handleSearchClick}>
        <Box className={classes.searchwrapper}>
          <TextField
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            type="text"
            label={`Suchen in ${props.title}`}
            fullWidth
            value={searchStr}
            onChange={handleChange}
            error={error !== ""}
            helperText={
              error !== ""
                ? error
                : searchStr.length > 0
                ? "Suchbegriff gültig"
                : "Mindestens 3 Zeichen (Buchstaben und Zahlen)"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Suchen"
                    onClick={resetSearch}
                    disabled={searchStr === ""}
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Box>
            <IconButton
              edge="end"
              aria-label="Suchen"
              className={classes.iconSearch}
              onClick={handleSearchClick}
              disabled={error !== "" || searchStr === ""}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
}
