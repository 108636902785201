import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Hidden,
  TextField,
  Typography,
  Box,
  Divider,
  Button
} from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import SelectInputSimple from "../SelectInputSimple";
import { validate } from "../../utils";
import withConfirm from "material-ui-confirm";
import isEqual from "lodash/isEqual";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(1)
  },
  headingMargin: {
    marginTop: theme.spacing(3)
  },
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  divider: {
    marginBottom: theme.spacing(5)
  },
  radioLabel: {
    color: theme.palette.grey[600]
  },
  checkbox: {
    marginRight: theme.spacing(12)
  },
  paper: {
    height: "100%",
    padding: theme.spacing(1, 2)
  },
  proceed: {
    height: "100%",
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  proceedbtn: {
    marginTop: "auto",
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  },
  deletebtn: {
    color: theme.palette.error.main,
    marginBottom: "20px"
  }
}));

const checkForm = (values, props) => {
  var regexFields = [],
    propFields = {},
    hasError = false;
  Object.keys(AppConfig.forms.contacts)
    .filter(n => AppConfig.forms.contacts[n].hasOwnProperty("regxp"))
    .map(n => {
      return regexFields.push(n);
    });

  Object.keys(AppConfig.forms.contacts).map(n => {
    return (propFields[n] = props[n]);
  });

  for (let index = 0; index < regexFields.length; index++) {
    var name = regexFields[index];
    if (AppConfig.forms.contacts[name].hasOwnProperty("regxp")) {
      if (!validate(values[name], AppConfig.forms.contacts[name].regxp)) {
        hasError = true;
      }
    }
  }
  var equalForms = props.isnew ? false : isEqual(propFields, values);

  const notFilled = Object.keys(AppConfig.forms.contacts).filter(
    n =>
      AppConfig.forms.contacts[n].required &&
      ((Array.isArray(AppConfig.forms.contacts[n].default) &&
        values[n].length < 1) ||
        values[n] === "")
  );

  if (!hasError && notFilled.length < 1 && !equalForms) {
    return true;
  } else {
    return false;
  }
};

const CustomerContactItem = props => {
  const classes = useStyles();
  var fields = {};
  Object.keys(AppConfig.forms.contacts).map(n => {
    return Object.assign(fields, {
      [n]: props[n]
    });
  });
  const [values, setValues] = React.useState(fields);

  const handleDelete = deletedItem => () => {
    props.saveCustomerContact(
      { deleted: true },
      props.isnew ? null : props.id,
      props.isnew
    );
  };

  const saveContact = () => {
    props.saveCustomerContact(
      values,
      props.isnew ? null : props.id,
      props.isnew
    );
  };

  const setChangeSelect = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (AppConfig.forms.contacts[name].hasOwnProperty("regxp")) {
      if (!validate(value, AppConfig.forms.contacts[name].regxp)) {
        setErrors({ ...errors, [name]: true });
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
  };

  var errorFields = {};
  Object.keys(AppConfig.forms.contacts)
    .filter(n => AppConfig.forms.contacts[n].hasOwnProperty("regxp"))
    .map(n => {
      return Object.assign(errorFields, {
        [n]: false
      });
    });
  const [errors, setErrors] = React.useState(errorFields);

  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    setCanSubmit(checkForm(values, props));
  }, [props, values]);

  return (
    <Fragment>
      <form noValidate autoComplete="off">
        {!props.isnew && (
          <Box>
            <Typography variant="subtitle1" className={classes.heading}>
              {AppConfig.pages.contactpersons.headings[props.contactType]}
            </Typography>
          </Box>
        )}
        <Grid container className={classes.root}>
          {props.isnew && (
            <Grid item xs={12} sm={6}>
              <Box className={classes.paper}>
                <SelectInputSimple
                  id="contactType"
                  name="contactType"
                  disabled={false}
                  fullWidth
                  label={AppConfig.forms.contacts.contactType.label}
                  required={AppConfig.forms.contacts.contactType.required}
                  value={values.contactType}
                  onChange={setChangeSelect}
                  options={props.contactTypes}
                  optionsLabels={
                    AppConfig.forms.contacts.contactType.optionsLabels
                  }
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <SelectInputSimple
                id="salutation"
                name="salutation"
                disabled={false}
                fullWidth
                label={AppConfig.forms.contacts.salutation.label}
                required={AppConfig.forms.contacts.salutation.required}
                value={values.salutation}
                onChange={setChangeSelect}
                options={AppConfig.forms.contacts.salutation.options}
              />
            </Box>
          </Grid>
          {!props.isnew && (
            <Hidden only="xs">
              <Grid item xs={12} sm={6} />
            </Hidden>
          )}

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="firstname"
                autoComplete="_off"
                name="firstname"
                label={AppConfig.forms.contacts.firstname.label}
                required={AppConfig.forms.contacts.firstname.required}
                fullWidth
                className={classes.textField}
                value={values.firstname}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="lastname"
                autoComplete="_off"
                name="lastname"
                label={AppConfig.forms.contacts.lastname.label}
                required={AppConfig.forms.contacts.lastname.required}
                fullWidth
                className={classes.textField}
                value={values.lastname}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="email"
                autoComplete="_off"
                name="email"
                type="email"
                error={errors.email}
                label={AppConfig.forms.contacts.email.label}
                required={AppConfig.forms.contacts.email.required}
                fullWidth
                className={classes.textField}
                value={values.email}
                helperText={
                  errors.email
                    ? "Email-Adresse unvollständig"
                    : values.email.length > 0
                    ? "Email-Adressformat gültig"
                    : "Email-Adresse angeben"
                }
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.paper}>
              <TextField
                id="phone"
                autoComplete="_off"
                name="phone"
                type="tel"
                label={AppConfig.forms.contacts.phone.label}
                required={AppConfig.forms.contacts.phone.required}
                fullWidth
                className={classes.textField}
                value={values.phone}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} sm={6} md={8} />
          </Hidden>

          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.proceed}>
              <Button
                disabled={!canSubmit}
                variant="contained"
                color="primary"
                fullWidth
                className={classes.proceedbtn}
                onClick={saveContact}
              >
                Speichern
                <Save className={classes.rightIcon} />
              </Button>
            </Box>
          </Grid>

          {!props.isnew && (
            <Fragment>
              <Hidden only="xs">
                <Grid item xs={12} sm={8} md={10} />
              </Hidden>

              <Grid item xs={12} sm={4} md={2}>
                <Box className={classes.proceed}>
                  <Button
                    fullWidth
                    className={classes.deletebtn}
                    onClick={props.confirm(handleDelete(props.id), {
                      description: `Ansprechpartner ${values.firstname} ${
                        values.lastname
                      } wirklich löschen?`,
                      title: "Sind Sie sicher?",
                      cancellationText: "Abbrechen",
                      confirmationText: "Ja, löschen",
                      dialogProps: {
                        maxWidth: "xs"
                      }
                    })}
                  >
                    Löschen
                    <Delete className={classes.rightIcon} />
                  </Button>
                </Box>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </form>
      {!props.last && <Divider className={classes.divider} />}
    </Fragment>
  );
};

CustomerContactItem.propTypes = {
  isnew: PropTypes.bool,
  last: PropTypes.bool,
  contactTypes: PropTypes.array
};
CustomerContactItem.defaultProps = {
  isnew: false,
  last: false,
  contactTypes: []
};

export default withConfirm(CustomerContactItem);
