import { createReducer } from "../../utils";
import { CONTACTS_SET, CONTACTS_RESET, CONTACTS_LOADED } from "../constants";

const initialState = {
  items: [],
  loaded: false
};

export default createReducer(initialState, {
  [CONTACTS_SET]: (state, payload) => {
    return {
      ...state,
      items: [...payload],
      loaded: true
    };
  },
  [CONTACTS_RESET]: (state, payload) => {
    return initialState;
  },
  [CONTACTS_LOADED]: (state, payload) => {
    return {
      ...state,
      loaded: payload
    };
  }
});
