import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  badge: {
    cursor: "pointer"
  }
}));
const StyledBadge = withStyles(theme => ({
  badge: {
    top: "50%",
    right: -3,
    border: `1px solid ${theme.palette.primary.dark}`
  }
}))(Badge);

const DarkTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip);

function MenuToolTip(props) {
  const classes = useStyles();

  return (
    <Fragment>
      {!props.withBadge ? (
        props.icon
      ) : (
        <DarkTooltip title={props.title} placement={props.placement}>
          <StyledBadge color="primary" className={classes.badge}>
            {props.icon}
          </StyledBadge>
        </DarkTooltip>
      )}
    </Fragment>
  );
}

MenuToolTip.propTypes = {
  withBadge: PropTypes.bool,
  icon: PropTypes.object,
  title: PropTypes.string,
  placement: PropTypes.string
};
MenuToolTip.defaultProps = {
  placement: "right",
  withBadge: true
};

export default MenuToolTip;
