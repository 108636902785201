import React from "react";

const SvgPpt = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#f6712e"
    />
    <text
      x="50%"
      y={49}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#FFF"
      fontSize={12}
      fontWeight={500}
      fontFamily="'Roboto'"
    >
      {"PPT"}
    </text>
    <path d="M39.5 30h-24V14h24v16zm-22-2h20V16h-20v12z" fill="#c8bdb8" />
    <path
      d="M20.499 35a1 1 0 01-.857-1.515l3-5a1 1 0 111.715 1.029l-3 5a.997.997 0 01-.858.486zm14.002 0a.998.998 0 01-.858-.485l-3-5a1 1 0 111.715-1.029l3 5A1 1 0 0134.501 35zM27.5 16a1 1 0 01-1-1v-3a1 1 0 112 0v3a1 1 0 01-1 1z"
      fill="#c8bdb8"
    />
    <path fill="#d3ccc9" d="M17.5 16h20v12h-20z" />
  </svg>
);

export default SvgPpt;
