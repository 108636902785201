import { createReducer } from "../../utils";
import {
  SET_UPLOAD,
  RESET_UPLOAD,
  FAILED_UPLOAD,
  BEGIN_UPLOAD
} from "../constants";

const initialState = {
  path: "",
  type: "",
  validDate: 0,
  isSuccess: false,
  isUploading: false,
  isError: false
};

export default createReducer(initialState, {
  [SET_UPLOAD]: (state, payload) => {
    return Object.assign({}, state, {
      path: payload.path,
      type: payload.type,
      validDate: payload.validDate,
      isUploading: false,
      isSuccess: true,
      isError: false
    });
  },
  [BEGIN_UPLOAD]: (state, payload) => {
    return Object.assign({}, state, {
      path: "",
      type: payload.type,
      validDate: payload.validDate,
      isUploading: true,
      isSuccess: false,
      isError: false
    });
  },
  [FAILED_UPLOAD]: (state, payload) => {
    return Object.assign({}, state, {
      path: "",
      type: "",
      validDate: 0,
      isUploading: false,
      isSuccess: false,
      isError: true
    });
  },
  [RESET_UPLOAD]: (state, payload) => {
    return Object.assign({}, state, {
      path: "",
      type: "",
      validDate: 0,
      isUploading: false,
      isSuccess: false,
      isError: false
    });
  }
});
