import AuthUtils from "./AuthUtils";

var AppConfig = require("../config.json");

const regexes = {
  email: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
  date: /^(0[1-9]|1[0-2]).([1-9]|[12][0-9]|3[01]).(\d{4})$/i,
  number: /^\d+$/,
  password: /^(?=.*[0-9])([a-zA-Z0-9.\-_]){8,}$/i,
  zipcode: /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
  username: /^([a-zA-Z0-9.\-_]){6,}$/i
};

export function noStopword(value) {
  return AppConfig.stopwords.indexOf(value) === -1;
}

export function validate(value, regexp) {
  return value.match(regexes[regexp]) !== null;
}
export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action.payload) : state;
  };
}

export function formatToPriceEuro(x) {
  return x.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol"
  });
}
export function formatTimestampToDateGerman(x) {
  const date = new Date(x * 1000);
  return date.toLocaleString("de-DE", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
}

export function backgroundImageStyle({ backgroundimage, fullscreen }) {
  if (backgroundimage !== "") {
    var body = {
      backgroundImage: `url(${backgroundimage})`
    };
    if (fullscreen) {
      body = Object.assign(
        {
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          height: "100%"
        },
        body
      );
    }
    return Object.assign(
      {
        "@global": {
          body: body
        }
      },
      {}
    );
  }
}

export function getApiUrl(str) {
  return AppConfig.api[process.env.NODE_ENV].concat(str);
}

export function ltrim(s, c) {
  if (c === "]") c = "\\]";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp("^[" + c + "]+|$", "g"), "");
}
export function rtrim(s, c) {
  if (c === "]") c = "\\]";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp("^|[" + c + "]+$", "g"), "");
}

export function getContentItemParentSpecs(props) {
  var subCatGroup = [],
    subCat = [],
    mainCat = [];

  props.sitemap.pages.map(cat =>
    cat.subcategories.map(sub =>
      sub.categorygroups
        .filter(gr => gr.id === props.catgroupsId)
        .map(elem => subCatGroup.push(elem))
    )
  );
  if (subCatGroup.length > 0) {
    props.sitemap.pages.map(cat =>
      cat.subcategories
        .filter(sub => sub.id === subCatGroup[0].categoriesId)
        .map(elem => subCat.push(elem))
    );
    if (subCat.length > 0) {
      mainCat = props.sitemap.pages.filter(
        cat => cat.id === subCat[0].categoriesId
      )[0];
      return {
        catGroupLabel: subCatGroup[0].menulabel,
        catGroupId: subCatGroup[0].id,
        subCatLabel: subCat[0].menulabel,
        subCatSmarturl: subCat[0].smarturl,
        mainCatLabel: mainCat.menulabel,
        mainCatSmarturl: mainCat.smarturl
      };
    }
  }
  return {
    catGroupLabel: "",
    catGroupId: "",
    subCatLabel: "",
    subCatSmarturl: "",
    mainCatLabel: "",
    mainCatSmarturl: ""
  };
}

export function checkHttpStatus(response) {
  // if (response.status >= 200 && response.status < 300) {
  //   return response;
  // } else {
  return new Promise(function(resolve, reject) {
    response
      .text()
      .then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
      }))
      .then(({ status, statusText, headers, body }) => {
        let json;
        try {
          json = JSON.parse(body);
        } catch (e) {
          return reject({
            error: "Daten fehlerhaft formatiert",
            status: status
          });
        }
        if (status < 200 || status >= 300) {
          let msg = "Ein Fehler ist aufgetreten";
          if (json !== undefined && json.hasOwnProperty("errors")) {
            if (json.errors.hasOwnProperty("messages")) {
              msg = json.errors.messages.join(" | ");
            } else if (json.errors.hasOwnProperty("message")) {
              msg = json.errors.message;
            }
          }
          return reject({ error: msg, status: status });
        }
        return resolve(json);
      })
      .then(response => {
        return response;
      })
      .catch(errorMsg => {
        var error = new Error(errorMsg);
        throw error;
      });
  });
}

export function padDate(n) {
  return n < 10 ? "0" + n.toString() : n.toString();
}
export function getDateFromUTC(d) {
  var date = AuthUtils.getConvertedDate(d);
  return (
    padDate(date.getDate()) +
    "." +
    padDate(date.getMonth() + 1) +
    "." +
    date.getFullYear()
  );
}
export function getTimestampFromUTC(d) {
  var date = AuthUtils.getConvertedDate(d);
  return date.getTime() / 1000;
}
export function getTimeFromUTC(d) {
  var date = AuthUtils.getConvertedDate(d);
  return padDate(date.getHours()) + ":" + padDate(date.getMinutes()) + " Uhr";
}

export function cacheBust() {
  return "?cachebust=" + cacheBustString();
}

export function cacheBustString() {
  return Math.random()
    .toString(36)
    .substr(2, 9);
}

export function getHeadersAndJSON(response) {
  return { headers: response.headers, json: response.json() };
}

export function parseJSON(response) {
  return response;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
