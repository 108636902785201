import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Button,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  GradeOutlined as Tutorials,
  CloudDownloadOutlined as Downloads
} from "@material-ui/icons";
import clsx from "clsx";
import { contentWillChange } from "../../actions/content";
import CategoryMoodImage from "./CategoryMoodImage";
import Pinned from "../contents/Pinned";

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark
  },
  root: {
    flexGrow: 1
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(2, 4),
    marginLeft: 0
  },
  paperlist: {
    height: "100%"
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3)
  },
  moodimage: {
    order: 1,
    [theme.breakpoints.down("xs")]: {
      order: 0
    }
  },
  introtext: {
    order: 0,
    [theme.breakpoints.down("xs")]: {
      order: 1
    }
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "70px",
    paddingBottom: theme.spacing(10)
    // justifyContent: "center"
  },

  rightIcon: {
    marginLeft: theme.spacing(3)
  }
}));
const getParagraphWithLinebreaks = str => (
  <Typography
    paragraph
    dangerouslySetInnerHTML={{ __html: str.replace("\n", "<br>") }}
  />
);

export default function Maincategory(props) {
  const classes = useStyles();

  function handleMenuClick(url) {
    props.dispatch(contentWillChange(mainPage.smarturl.concat("/", url)));
  }

  const mainPage = props.sitemap.pages.filter(item => {
    return item.id === props.usersite.main;
  })[0];

  const subPages = mainPage.subcategories;

  const getIcon = smarturl => {
    if (smarturl === "tutorials") {
      return <Tutorials className={classes.rightIcon} />;
    } else if (smarturl === "downloads") {
      return <Downloads className={classes.rightIcon} />;
    }
    return null;
  };

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xl" component="main">
        <Box className={classes.title}>
          <Box p={2}>
            <Typography component="h1" variant="h6" className={classes.heading}>
              {mainPage.headline}
            </Typography>
          </Box>
        </Box>

        <Grid container className={classes.root} spacing={5}>
          <Grid
            item
            xs={12}
            sm={mainPage.moodImage !== "" ? 7 : 12}
            md={mainPage.moodImage !== "" ? 8 : 12}
            bgcolor="white"
            className={classes.introtext}
          >
            <Box
              className={clsx(classes.paper, classes.paperlist)}
              bgcolor="white"
            >
              {getParagraphWithLinebreaks(mainPage.copytext)}
              <Divider className={classes.divider} />
              <Fragment>
                {subPages.map(item => (
                  <Button
                    key={item.id}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleMenuClick(item.smarturl)}
                  >
                    {item.menulabel}
                    {getIcon(item.smarturl)}
                  </Button>
                ))}
              </Fragment>
            </Box>
          </Grid>
          {mainPage.moodImage !== "" && (
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              bgcolor="white"
              className={classes.moodimage}
            >
              <CategoryMoodImage
                title={mainPage.menulabel}
                moodImage={mainPage.moodImage}
              />
            </Grid>
          )}
        </Grid>
        <Pinned label={mainPage.headline} />
      </Container>
    </Box>
  );
}
