import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ContentElementGrid from "./ContentElementGrid";
import ContentElementList from "./ContentElementList";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative"
  },
  root: {
    flexGrow: 1
  },
  heading: {
    color: theme.palette.primary.dark
  },
  title: {
    marginTop: theme.spacing(2)
  }
}));

function FavoritesList(props) {
  const classes = useStyles();

  var elements = [];
  props.sitemap.pages.map(cat =>
    cat.subcategories.map(sub =>
      sub.categorygroups
        .filter(gr => gr.contentelements.length > 0)
        .map(gr =>
          gr.contentelements
            .filter(item => props.usersite.favorites.indexOf(item.id) !== -1)
            .map(elem => elements.push(elem))
        )
    )
  );

  return (
    <Fragment>
      {elements.length > 0 ? (
        <Grid container className={classes.root} spacing={3}>
          {elements.map(elem =>
            props.usersite.layout === "grid" ? (
              <ContentElementGrid
                isContentPage={false}
                {...elem}
                key={elem.id}
              />
            ) : (
              <ContentElementList
                isContentPage={false}
                {...elem}
                key={elem.id}
              />
            )
          )}
        </Grid>
      ) : (
        <Box className={classes.title}>
          <Box p={2}>
            <Typography variant="body2" className={classes.heading}>
              Diese Liste ist leer
            </Typography>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    sitemap: state.sitemap,
    usersite: state.usersite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoritesList);
