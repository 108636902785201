import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { GridOn as GridIcon, List as ListIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { setUsersiteLayout } from "../../actions/usersite";
import { saveContentLayout } from "../../actions";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  buttons: {
    marginLeft: theme.spacing(1)
  },
  actions: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  wrapper: {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

function GridListAction(props) {
  const classes = useStyles();

  const handleChange = (event, view) => {
    if (view !== null) {
      props.dispatch(setUsersiteLayout(view));
      props.dispatch(saveContentLayout({ layout: view }, props.employeeId));
    }
  };

  return (
    <Fragment>
      <Box className={classes.wrapper}>
        <Typography component="h1" variant="h6" className={classes.heading}>
          {props.label}
        </Typography>
        {props.hasButtons && (
          <Box className={classes.actions}>
            <Typography variant="body2" className={classes.heading}>
              Ansicht:
            </Typography>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={props.layout}
              className={classes.buttons}
              onChange={handleChange}
            >
              <ToggleButton value="grid" aria-label="grid view">
                <GridIcon />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </Box>
    </Fragment>
  );
}

GridListAction.propTypes = {
  hasButtons: PropTypes.bool
};
GridListAction.defaultProps = {
  hasButtons: true
};

const mapStateToProps = state => {
  return {
    layout: state.usersite.layout,
    customerId: state.auth.customerId,
    employeeId: state.auth.employeeId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridListAction);
