import React from "react";
import { Typography, Grid, Container, Box, Button } from "@material-ui/core";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Restore from "@material-ui/icons/Restore";
import List from "@material-ui/icons/List";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  startNewAboSalesDeal,
  resetAboSalesDeal,
  setAboSalesDealEditMode
} from "../../actions/abosales";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(3)
  },
  root: {
    flexGrow: 1
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(2, 4),
    marginLeft: 0
  },
  buttonCustom: {
    backgroundColor: theme.palette.custom.main,
    "&:hover": {
      backgroundColor: theme.palette.custom.light
    }
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2, 3),

    paddingBottom: theme.spacing(0)
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  wrapper: {
    minHeight: "90vh",
    width: "100%",
    // display: "flex",
    paddingTop: "70px",
    paddingBottom: theme.spacing(10)
    // justifyContent: "center"
  },

  rightIcon: {
    marginLeft: theme.spacing(3)
  }
}));

export default function AboDashboard(props) {
  const classes = useStyles();

  const clickResume = () => {
    props.dispatch(setAboSalesDealEditMode(true));
  };
  const clickNew = () => {
    props.dispatch(resetAboSalesDeal());
    props.dispatch(startNewAboSalesDeal());
  };

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="xl" component="main">
        <Box className={classes.title}>
          <Box p={2}>
            <Typography component="h1" variant="h6" className={classes.heading}>
              {AppConfig.pages.abodashboard.headings.main}
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="white">
          <Grid container className={classes.root} spacing={5}>
            <Grid item xs={12}>
              <Box className={classes.paper}>
                <Typography>
                  Hier können sie ihre bestehenden Vorgänge einsehen und
                  bearbeiten sowie neue Abonnements-Angebote erstellen.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.paper}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={clickNew}
                >
                  Neues Angebot
                  <PlaylistAdd className={classes.rightIcon} />
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.paper}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={classes.button}
                  onClick={() => props.handleMenuClick("deals")}
                >
                  {AppConfig.pages.abodealslist.label}
                  <List className={classes.rightIcon} />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.paper}>
                <Button
                  variant="contained"
                  fullWidth
                  className={clsx(classes.button, classes.buttonCustom)}
                  onClick={clickResume}
                  disabled={!props.abosales.progress.isset}
                >
                  Zum geladenen Angebot
                  <Restore className={classes.rightIcon} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
