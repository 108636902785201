import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Restore from "@material-ui/icons/Restore";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MenuToolTip from "../menu/MenuToolTip";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  buttonWrapper: {
    position: "absolute",
    right: 90,
    top: 10,
    zIndex: 1,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      right: 0,
      top: 0,
      marginLeft: theme.spacing(0)
    }
  },
  btn: {
    background: theme.palette.secondary.dark,
    color: "white",
    marginLeft: theme.spacing(4),
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.easeOut,
      duration: 200
    }),
    "&:hover": {
      color: theme.palette.primary.dark
    }
  },
  btnFirst: {
    marginLeft: theme.spacing(2)
  }
}));

export default function AboAddOrResumeButton(props) {
  const classes = useStyles();

  function handleClickNew() {
    props.clickNew();
  }
  function handleClickResume() {
    props.clickResume();
  }

  return (
    <Box className={classes.buttonWrapper}>
      {props.resume && (
        <Button
          size="small"
          className={clsx(classes.btn, classes.btnFirst)}
          aria-label="Resume"
          onClick={handleClickResume}
        >
          <MenuToolTip
            title="Zum geladenen Angebot"
            withBadge={true}
            icon={<Restore />}
            placement="bottom"
          />
        </Button>
      )}
      <Button
        size="small"
        className={classes.btn}
        aria-label="Add new"
        onClick={handleClickNew}
      >
        <MenuToolTip
          title="Neues Angebot"
          withBadge={true}
          icon={<PlaylistAdd />}
          placement="bottom"
        />
      </Button>
    </Box>
  );
}
