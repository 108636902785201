export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_PREPARE = "LOGOUT_PREPARE";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_CONTROLLED = "LOGOUT_CONTROLLED";
export const LOGIN_USER_DATA_UPDATE = "LOGIN_USER_DATA_UPDATE";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_RE_CHECK = "AUTH_RE_CHECK";
export const AUTH_NEEDS_RELOG = "AUTH_NEEDS_RELOG";
export const SHOW_SYSTEM_MESSAGE = "SHOW_SYSTEM_MESSAGE";
export const DISMISS_SYSTEM_MESSAGE = "DISMISS_SYSTEM_MESSAGE";
export const SHOW_SECTION_INCOMPLETE = "SHOW_SECTION_INCOMPLETE";
export const DISMISS_SECTION_INCOMPLETE = "DISMISS_SECTION_INCOMPLETE";
export const STATUS_UPLOAD = "STATUS_UPLOAD";
export const NOT_FOUND = "NOT_FOUND";
export const CONTENT_CHANGE = "CONTENT_CHANGE";
export const CONTENT_CHANGE_RESET = "CONTENT_CHANGE_RESET";
export const SET_UPLOAD = "SET_UPLOAD";
export const RESET_UPLOAD = "RESET_UPLOAD";
export const FAILED_UPLOAD = "FAILED_UPLOAD";
export const BEGIN_UPLOAD = "BEGIN_UPLOAD";
export const THEME_COLOR_CHANGE = "THEME_COLOR_CHANGE";
export const THEME_BG_CHANGE = "THEME_BG_CHANGE";
export const THEME_MOD_CHANGE = "THEME_MOD_CHANGE";
export const THEME_RESET = "THEME_RESET";
export const CONFIGS_SET = "CONFIGS_SET";
export const CONFIGS_RESET = "CONFIGS_RESET";
export const MENU_TOGGLE = "MENU_TOGGLE";
export const MENU_SET = "MENU_SET";
export const CUSTOMER_SET = "CUSTOMER_SET";
export const CUSTOMER_RESET = "CUSTOMER_RESET";
export const CUSTOMER_FORM_SET = "CUSTOMER_FORM_SET";
export const CUSTOMER_FORM_SAVED = "CUSTOMER_FORM_SAVED";
export const EMPLOYEE_SET = "EMPLOYEE_SET";
export const EMPLOYEE_RESET = "EMPLOYEE_RESET";
export const EMPLOYEE_FORM_SET = "EMPLOYEE_FORM_SET";
export const EMPLOYEE_FORM_SAVED = "EMPLOYEE_FORM_SAVED";
export const SHIPPING_SET = "SHIPPING_SET";
export const SHIPPING_RESET = "SHIPPING_RESET";
export const SHIPPING_FORM_SET = "SHIPPING_FORM_SET";
export const SHIPPING_FORM_SAVED = "SHIPPING_FORM_SAVED";
export const CONTACTS_SET = "CONTACTS_SET";
export const CONTACTS_RESET = "CONTACTS_RESET";
export const CONTACTS_LOADED = "CONTACTS_LOADED";
export const PASSWORD_OVERLAY = "PASSWORD_OVERLAY";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const SET_SAVING = "SET_SAVING";
export const SITEMAP_SET = "SITEMAP_SET";
export const SITEMAP_RESET = "SITEMAP_RESET";
export const USERSITE_SET_CURRENT = "USERSITE_SET_CURRENT";
export const USERSITE_RESET_CURRENT = "USERSITE_RESET_CURRENT";
export const USERSITE_SET_FILTER = "USERSITE_SET_FILTER";
export const USERSITE_SET_SEARCH = "USERSITE_SET_SEARCH";
export const USERSITE_SET_FAVORITES = "USERSITE_SET_FAVORITES";
export const USERSITE_SET_DETAIL = "USERSITE_SET_DETAIL";
export const USERSITE_RESET_DETAIL = "USERSITE_RESET_DETAIL";
export const USERSITE_SET_CAT_SEARCH = "USERSITE_SET_CAT_SEARCH";
export const USERSITE_SET_LAYOUT = "USERSITE_SET_LAYOUT";
export const USERSITE_RESET_ALL_EXCEPT_FAV = "USERSITE_RESET_ALL_EXCEPT_FAV";
export const USERSITE_RESET = "USERSITE_RESET";
export const USERSITE_RELOAD = "USERSITE_RELOAD";
export const USERSITE_HAS_CONTENT = "USERSITE_HAS_CONTENT";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";
export const DOWNLOAD_REQUEST = "DOWNLOAD_REQUEST";
export const DOWNLOAD_START = "DOWNLOAD_START";
export const DOWNLOAD_IDLE = "DOWNLOAD_IDLE";
export const ABO_ARTICLES_SET = "ABO_ARTICLES_SET";
export const ABO_ARTICLES_RESET = "ABO_ARTICLES_RESET";
export const ABO_ARTICLES_SWITCH_SET = "ABO_ARTICLES_SWITCH_SET";
export const ABO_CLIENTS_SET = "ABO_CLIENTS_SET";
export const ABO_CLIENTS_RESET = "ABO_CLIENTS_RESET";
export const ABO_CLIENTS_CHECK_DATA = "ABO_CLIENTS_CHECK_DATA";
export const ABO_CLIENTS_ADD_ONE_TO_COUNT = "ABO_CLIENTS_ADD_ONE_TO_COUNT";
export const ABO_DEALS_DATA = "ABO_DEALS_DATA";
export const ABO_DEALS_REQUEST = "ABO_DEALS_REQUEST";
export const ABO_DEALS_PAGINATION = "ABO_DEALS_PAGINATION";
export const ABO_DEALS_REFRESH = "ABO_DEALS_REFRESH";
export const ABO_DEALS_USERID = "ABO_DEALS_USERID";
export const ABO_DEALS_RESET = "ABO_DEALS_RESET";
export const ABO_DEALS_TOGGLE_COMMISSION = "ABO_DEALS_TOGGLE_COMMISSION";
export const ABO_DEALS_COMMISSION = "ABO_DEALS_COMMISSION";
export const ABO_DEALS_DETAIL = "ABO_DEALS_DETAIL";
export const ABO_DEALS_CLOSE_DETAIL = "ABO_DEALS_CLOSE_DETAIL";
export const ABO_SALES_SET_DEAL_CHANGED = "ABO_SALES_SET_DEAL_CHANGED";
export const ABO_SALES_SET_DEAL_ARTICLES = "ABO_SALES_SET_DEAL_ARTICLES";
export const ABO_SALES_SET_DEAL_CLIENT = "ABO_SALES_SET_DEAL_CLIENT";
export const ABO_SALES_SET_DEAL_MONTH = "ABO_SALES_SET_DEAL_MONTH";
export const ABO_SALES_SET_DEAL_COMPLETE = "ABO_SALES_SET_DEAL_COMPLETE";
export const ABO_SALES_DEAL_IN_PROGRESS = "ABO_SALES_DEAL_IN_PROGRESS";
export const ABO_SALES_SET_DEAL_EDITMODE = "ABO_SALES_SET_DEAL_EDITMODE";
export const ABO_SALES_SET_DEAL_TITLE = "ABO_SALES_SET_DEAL_TITLE";
export const ABO_SALES_SET_CLIENT_ADDRESS = "ABO_SALES_SET_CLIENT_ADDRESS";
export const ABO_SALES_SET_DEAL_SAVE = "ABO_SALES_SET_DEAL_SAVE";
export const ABO_SALES_SET_DEAL_FROM_SAVED_ITEMS =
  "ABO_SALES_SET_DEAL_FROM_SAVED_ITEMS";
export const ABO_SALES_DEAL_RESET = "ABO_SALES_DEAL_RESET";
export const ABO_SALES_START_DEAL = "ABO_SALES_START_DEAL";
export const ABO_PRINT_LINK = "ABO_PRINT_LINK";
export const ABO_PRINT_LINK_RESET = "ABO_PRINT_LINK_RESET";
export const ABO_PRINT_RUNTIME = "ABO_PRINT_RUNTIME";
export const ABO_PRINT_SAVE_FIRST = "ABO_PRINT_SAVE_FIRST";
export const USER_APP_SETTINGS = "USER_APP_SETTINGS";
export const USER_APP_SETTINGS_ABO_HINT = "USER_APP_SETTINGS_ABO_HINT";
