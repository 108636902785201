import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  dialogcontent: {
    padding: theme.spacing(4),
    "& .MuiPaper-elevation2": {
      boxShadow: "none"
    }
  },
  button: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  name: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  qty: {
    padding: theme.spacing(2, 5)
  },
  btnlabel: {
    width: "40px",
    textAlign: "right"
  }
}));

export default function ConfirmDeleteClick(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleDeleteAndClose() {
    handleClose();
    props.deleteAction();
  }
  function handleOnlyClose() {
    handleClose();
  }

  return (
    <Box p={0}>
      <IconButton
        edge="end"
        color="inherit"
        size={props.size}
        onClick={handleClickOpen}
        disabled={!props.canDelete}
        aria-label="close"
      >
        <DeleteIcon />
      </IconButton>

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.dialogcontent}>
          <Typography variant="body1" className={classes.name}>
            {props.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnlyClose} color="primary" variant="contained">
            {props.buttonCancel}
          </Button>
          <Button
            onClick={handleDeleteAndClose}
            color="secondary"
            variant="contained"
          >
            {props.buttonConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

ConfirmDeleteClick.propTypes = {
  message: PropTypes.string,
  buttonCancel: PropTypes.string,
  buttonConfirm: PropTypes.string
};
ConfirmDeleteClick.defaultProps = {
  message: "Wollen Sie den Eintrag wirklch löschen?",
  buttonCancel: "Nein, nicht löschen",
  buttonConfirm: "Ja, löschen"
};
