import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import CustomerContactItem from "./CustomerContactItem";

var AppConfig = require("../../config.json");

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(3)
  },
  dialogtitle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  dialogcontent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  addNew: {
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      left: 10
    }
  },
  addNewEnabled: {
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 1,
    background: theme.palette.secondary.dark,
    color: "white",
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.easeOut,
      duration: 200
    }),
    "&:hover": {
      color: theme.palette.primary.dark
    },
    [theme.breakpoints.down("xs")]: {
      left: 10
    }
  }
}));

export default function CustomerContactAdd(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function save(form, id, isnew) {
    handleClose();
    form.customersId = props.customerId;
    props.saveCustomerContact(form, id, isnew);
  }
  var fields = {
    contactType: ""
  };
  Object.keys(AppConfig.forms.contacts).map(n => {
    return Object.assign(fields, {
      [n]: ""
    });
  });

  return (
    <Fragment>
      <Button
        disabled={props.possibilities.length < 1}
        size="small"
        className={
          props.possibilities.length < 1
            ? classes.addNew
            : classes.addNewEnabled
        }
        aria-label="Add new"
        onClick={handleClickOpen}
      >
        Neu <PlaylistAdd className={classes.rightIcon} />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogtitle}>
          Neue(n) Ansprechpartner(in) hinzufügen
        </DialogTitle>
        <DialogContent className={classes.dialogcontent}>
          <CustomerContactItem
            {...fields}
            isnew={true}
            saveCustomerContact={save}
            contactTypes={props.possibilities}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
