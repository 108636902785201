import {
  AUTH_CHECK,
  AUTH_RE_CHECK,
  AUTH_NEEDS_RELOG,
  AUTH_SUCCESS,
  AUTH_FAILURE
} from "../store/constants";

import LocalStorageUtils from "../utils/LocalStorageUtils";

export function doRelog() {
  return {
    type: AUTH_NEEDS_RELOG,
    payload: {}
  };
}

export function authRequest() {
  return {
    type: AUTH_CHECK,
    payload: {}
  };
}
export const authSuccess = data => dispatch => {
  LocalStorageUtils.updateAccountData(data);
  dispatch({
    type: AUTH_SUCCESS,
    payload: data
  });
};

export function authFailure(error) {
  return {
    type: AUTH_FAILURE,
    payload: {}
  };
}
export const doNewAuth = () => dispatch => {
  dispatch({
    type: AUTH_RE_CHECK,
    payload: {}
  });
};
